import Env from '../Env';

export default class {
    uuid;
    platform;

    constructor() {
        this.uuid = null;
        this.platform = null;
    }

    restore() {
        if (Env.env === 'dev') {
            this.uuid = 'e0101010d38bde8e6740011221af335301010333';
            this.platform = 'ios';
        }
        else
        {
            let app = document.URL.indexOf( 'http://' ) === -1 && document.URL.indexOf( 'https://' ) === -1;
            if ( app ) {
                this.uuid = device.uuid;
                this.platform = device.platform.toLowerCase();
            } else {
                this.platform = 'web';
            }
        }
    }
}