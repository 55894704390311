import BaseForm from "./BaseForm";
import FormProfile from "../../FormProfile";

export default class extends BaseForm {

    givenNames;
    email;
    phone;

    constructor() {
        super();
        this.givenNames = FormProfile.guestCheckout.givenNames;
        this.email = FormProfile.guestCheckout.email;
        this.phone = FormProfile.guestCheckout.phone;
    }
}