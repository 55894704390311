<template>
    <f7-page class="CartV2">
        Cart V2
        <f7-link href="/login/">
            fdwef
        </f7-link>
    </f7-page>
</template>
<script>
    import BasePage from "./base";

    export default {
        extends: BasePage
    }
</script>