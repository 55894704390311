<template>
    <div class="MapComponent">
        <div class="MapComponent__Map">
            <div id="map"></div>
        </div>
        <div class="MapComponent__Stores">
            <div class="MapComponent__Stores__Nav">
                <StoresSearchComponent @results="onStoresSearchResults($event.stores)"/>
            </div>
            <div class="MapComponent__Stores__Content">
                <f7-list v-if="stores.length > 0" accordion-list no-chevron class="no-margin-vertical">
                    <f7-list-item accordion-item
                                  :id="`store-${store.latitude}-${store.longitude}`"
                                  v-for="store in stores"
                                  @click="mapPanningTo(store)"
                    >
                        <div slot="title">
                            <div class="MapPage__Store__Name">
                                {{store.name}}
                            </div>
                            <div class="MapPage__Store__Address">
                                {{store.address1}}
                            </div>
                            <div class="MapPage__Store__Status">
                                <template v-if="store.IsOpen">
                                    <span>Open Now:</span> {{store.openingHours}}
                                </template>
                                <template v-else>
                                    <span>Closed</span>
                                </template>
                            </div>
                        </div>
                        <div slot="after">
                            <div class="MapPage__Store__Distance"
                                 @click="openGoogleMap(store)">
                                <f7-icon f7="placemark_fill"/>
                                {{store.distance}}km
                            </div>
                        </div>
                        <f7-accordion-content>
                            <f7-block class="no-margin-horizontal margin-bottom">
                                <f7-button fill large @click="selectPickupStore(store)">Select Restaurant</f7-button>
                            </f7-block>
                        </f7-accordion-content>
                    </f7-list-item>
                </f7-list>
                <f7-block v-else>
                    No store found
                </f7-block>
            </div>
        </div>
        <f7-link icon-material="close"
                 class="ProductComponent__Product__Close"
                 @click="$emit('close')"
        />
    </div>
</template>

<script>
    import Product from "../models/Product";
    import BaseComponent from "./BaseComponent";
    import CartItem from "../models/CartItem";
    import Cart from "../models/Cart";
    import Menu from "../models/Menu";
    import View from "../models/View";
    import Position from "../models/Position";
    import StoresSearchComponent from "./StoresSearchComponent";
    import Store from "../models/Store";

    export default {
        name: "MapComponent",
        extends: BaseComponent,
        components: {StoresSearchComponent},
        data: function () {
            return {
                stores: [],
                currentPosition: new Position(),
                map: null,
                pickupStore: new Store(),
                mapMarkers: {}
            }
        },
        mounted() {
            this.useCurrentLocation();

            this.map = new google.maps.Map(document.getElementById('map'), {
                center: {lat: -25.782608, lng: 136.168044},
                zoom: 4,
                disableDefaultUI: true,
                clickableIcons: false
            });
        },
        methods: {
            useCurrentLocation() {
                this.$f7.preloader.show();

                navigator.geolocation.getCurrentPosition((position) => {
                  const { coords } = position;
                  if(!coords){
                        this.$f7.dialog.alert('Unable to get your location. Please use the search box instead');
                    } else {
                        this.currentPosition.fill(coords);
                    }
                    this.$f7.preloader.hide();
                }, (error) => {
                    this.$f7.preloader.hide();
                    this.$f7.dialog.alert('Unable to get your location. Please use the search box instead');
                }, {
                    timeout: 5000
                });
            },
            openGoogleMap(store) {
                this.$f7.dialog.confirm(`Open store <b>${store.name}</b> in Google Map?`, 'Open Google Map', () => {
                    window.open(`https://www.google.com/maps/search/?api=1&query=${store.latitude},${store.longitude}`, '_system')
                })
            },
            selectPickupStore(store) {
                this.$f7.dialog.confirm(`Select store <b>${store.name}</b> as pickup store?`, 'Select Pickup store', () => {
                    this.pickupStore.fill(store);
                    this.$f7.toast.show({
                        position: 'center',
                        icon: '<i class="f7-icons">checkmark_alt_circle</i>',
                        text: `Store ${store.name} is marked as pickup store`,
                        closeTimeout: 1000
                    });

                    this.$emit('store-selected', {
                        store: this.pickupStore
                    })
                    localStorage.setItem('storeID', store.id);

                    window.location.reload();
                });
            },
            onStoresSearchResults(stores) {
                this.stores = stores;

                stores.forEach((store) => {
                    if (this.mapMarkers[store.id] === undefined) {
                        new google.maps.Marker({
                            position:
                                {
                                    lat: parseFloat(store.latitude),
                                    lng: parseFloat(store.longitude)
                                },
                            map: this.map
                        });

                        this.mapMarkers[store.id] = store;
                    }else{
                      console.log(this.mapMarkers, store.id);
                    }
                });

                if (this.stores.length > 0) {
                    this.mapPanningTo(this.stores[0])
                }
            },
            mapPanningTo(store) {
                this.map.panTo({
                    lat: parseFloat(this.mapMarkers[store.id].latitude),
                    lng: parseFloat(this.mapMarkers[store.id].longitude)
                });
                this.map.setZoom(14);
            }
        },
        watch: {
            currentPosition: {
                deep: true,
                handler: function () {
                    if (this.currentPosition.isValid()) {
                        Store.getNearestStores(this.currentPosition.latitude, this.currentPosition.longitude).then((response) => {
                            if (response.success) {
                              this.onStoresSearchResults(response.stores);
                            }
                        });
                    }
                }
            }
        }
    }
</script>

<style scoped>
    #map {
        height: 100%
    }
</style>
