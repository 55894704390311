<template>
    <f7-page class="AccountPage" @page:afterin="checkNotificationStatus">
        <f7-navbar no-shadow class="AccountPage__NavBar">
            <f7-nav-left>
                <f7-button class="fb__30" back icon-material="arrow_backward"></f7-button>
            </f7-nav-left>
            <f7-nav-title class="width-100 text-align-center">Account</f7-nav-title>
            <f7-nav-right>
                <f7-button @click="update" class="AccountPage__Icon--Right">
                    Save
                </f7-button>
            </f7-nav-right>
        </f7-navbar>
        <div class="AccountPage__Content">
            <f7-list class="no-margin-top" no-hairlines inline-labels>
                <f7-list-input class="fli__with-hairline fli__no-hairline-input fli__default-input-height"
                               type="text" label="Full Name"
                               :value="member.FullName"
                               @change="member.FullName = $event.target.value"
                />
                <f7-list-input
                        class="fli__with-hairline fli__no-hairline-input fli__default-input-height"
                        type="email" label="Email" :value="member.Email" disabled/>
                <f7-list-input
                        class="fli__with-hairline fli__no-hairline-input fli__default-input-height"
                        type="date" label="Date of birth"
                        :value="member.DateOfBirth"
                        @change="member.DateOfBirth = $event.target.value"
                        :disabled="originalDateOfBirth !== 'null'"/>
                <f7-list-input
                        class="fli__with-hairline fli__no-hairline-input fli__default-input-height"
                        type="number" label="Mobile"
                        pattern="[0-9]*"
                        :value="member.Mobile"
                        @change="member.Mobile = $event.target.value"
                />
                <f7-list-item link="/change-password/" title="Change Password"/>
                <f7-list-item @click="switchToSettings">
                    <div slot="title">
                        Notifications
                    </div>
                    <div slot="after">
                        <f7-toggle :checked="hasNotificationTurnedOn" disabled></f7-toggle>
                    </div>
                </f7-list-item>
            </f7-list>
        </div>
    </f7-page>
</template>
<script>
    import BasePage from './base';
    import Member from "../models/Member";
    import Token from "../models/Token";
    import Device from "../models/Device";

    export default {
        extends: BasePage,
        data: function () {
            return {
                member: new Member(),
                token: new Token(),
                device: new Device(),
                hasNotificationTurnedOn: false,
                originalDateOfBirth: 'null'
            }
        },
        mounted: function () {
            this.device.restore();
            this.member.restore();
            this.token.restore();

            if (this.member.DateOfBirth !== null && this.member.DateOfBirth !== undefined)
                this.originalDateOfBirth = this.member.DateOfBirth;

            document.addEventListener("resume", () => {
                this.checkNotificationStatus()
            }, false);
        },
        methods: {
            switchToSettings() {
                try {
                    cordova.plugins.diagnostic.switchToSettings(() => {
                    }, (error) => {
                        this.$f7.dialog.alert(error)
                    });
                } catch (e) {
                }
            },
            checkNotificationStatus() {
                try {
                    cordova.plugins.diagnostic.isRemoteNotificationsEnabled((status) => {
                        this.hasNotificationTurnedOn = status;
                    }, (error) => {
                        this.$f7.dialog.alert(error)
                    });
                } catch (e) {
                }
            },
            update() {
                this.token.restore();
                this.$f7.preloader.show();
                Member.update(this.member, this.token.token, this.device).then((response) => {
                    this.$f7.preloader.hide();
                    this.$f7.dialog.alert(response.message);
                    if (response.success) {
                        this.member.fill(response.member);
                        this.token.fill(response.token);
                        this.member.store();
                        this.token.store();
                    }
                })
            },
            logout() {
                this.$f7.dialog.confirm('Are you sure?', () => {
                    this.member.purge();
                    this.token.purge();
                    this.$f7router.navigate('/login/')
                })
            },
            launchNewsFeed() {
                try {
                    AppboyPlugin.launchNewsFeed();
                } catch (e) {
                }
            }
        }
    };
</script>