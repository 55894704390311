<template>
  <f7-page name="order-received" class="OrderReceivedPage" @page:beforeout="handleBeforeOut">
    <div class="OrderReceivedPage__NavBar"></div>
    <div class="OrderReceivedPage__Pickup"></div>
    <div class="OrderReceivedPage__Store">
      <div class="OrderReceivedPage__Store__Name">
        {{ pickupStore.name }}
      </div>
      <div class="OrderReceivedPage__Store__Address">
        {{ pickupStore.address1 }} {{ pickupStore.address2 }} {{ pickupStore.state }}
      </div>
    </div>
    <div class="OrderReceivedPage__OrderConfirmed">
      <f7-icon f7="checkmark_alt_circle_fill"/>
      Order Confirmed!
    </div>
    <div class="OrderReceivedPage__PickupTime">
      {{ invoice.PickupTime | pickupTime }}
    </div>
    <div class="OrderReceivedPage__EstimatedPickupTime">
      Estimated Pickup Time
    </div>
    <f7-list>
      <f7-list-item>
        <div slot="title" class="OrderReceivedPage__OrderSummary">
          Order Summary
        </div>
      </f7-list-item>
      <f7-list-item v-for="item in cart.items">
        <div slot="title">
          <div class="OrderReceivedPage__Item__Title">
            {{ item.qty }} x {{ menu.map[item.product].name }}
          </div>
          <div class="OrderReceivedPage__Item__Ingredients">
            {{ item.ingredientsToString(menu.map) }}
          </div>
        </div>
        <div slot="after" class="OrderReceivedPage__Item__Price">
          {{ item.getTotal(menu.map) | total }}
        </div>
      </f7-list-item>
      <f7-list-item>
        <div slot="title" class="OrderReceivedPage__Total">
          Total
        </div>
        <div slot="after" class="OrderReceivedPage__TotalValue">
          {{ cart.getTotal(menu.map) | total }}
        </div>
      </f7-list-item>
    </f7-list>
    <f7-block>
      <f7-button large
                 fill
                 @click="openHomePage"
                 :animate="false"
      >
        Continue
      </f7-button>
    </f7-block>
  </f7-page>
</template>
<script>
import moment from 'moment';
import BasePage from './base'
import Store from "../models/Store";
import Invoice from "../models/Invoice";
import Cart from "../models/Cart";
import CreditCard from "../models/CreditCard";
import Menu from "../models/Menu";
import AppRateCounter from "../models/AppRateCounter";
import Member from "../models/Member";
import DataLayerHelper from "../models/DataLayerHelper";

export default {
  extends: BasePage,
  data() {
    return {
      pickupStore: new Store(),
      invoice: new Invoice(),
      cart: new Cart(),
      card: new CreditCard(),
      menu: new Menu(),
      appRateCounter: new AppRateCounter(),
      member: new Member(),
    }
  },
  mounted() {
    this.pickupStore.restore();
    this.invoice.restore();
    this.cart.restore();
    this.card.restore();
    this.menu.restore();
    this.member.restore();

    if (member.isValid()) {
      DataLayerHelper.logCustomerEmailOnCheckoutSuccess(this.member.Email);
    }
    DataLayerHelper.logStoreName(this.pickupStore.name);
  },
  methods: {
    handleBeforeOut() {
      this.invoice.purge();
      this.cart.purge();
    },
    openHomePage() {
      window.location.reload(true)
    }
  },
  filters: {
    pickupTime(value) {
      return moment(value).format('HH:mmA');
    }
  }
}
</script>
<style scoped>
</style>
