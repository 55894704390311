<template>
    <f7-page class="FAQsPage">
        <f7-navbar class="FAQsPage__NavBar" no-shadow>
            <f7-nav-left>
                <f7-link @click="closePage">Back</f7-link>
            </f7-nav-left>
            <f7-nav-title>FAQs</f7-nav-title>
            <f7-nav-right>
                <f7-link>Back</f7-link>
            </f7-nav-right>
        </f7-navbar>
        <div class="FAQsPage__Content">
            <p><strong>How do I redeem the &ldquo;Benefits&rdquo;?</strong></p>
            <p>You can redeem your benefits two ways;</p>
            <ol>
                <li>Add to Order- simply click into the benefit, select &ldquo;Add to order&rdquo; and follow the
                    prompts to purchase it in-app.
                </li>
                <li>Scan at restaurant- simply click into the benefit, select &ldquo;Scan at restaurant&rdquo; and scan
                    the barcode that shows in-restaurant when purchasing that specific deal.
                </li>
            </ol>
            <p>These unique app benefits have an expiry date and can only be used once in store or in-app.</p>
            <p><strong>Can I redeem multiple benefits in one transaction?</strong></p>
            <p>Yes, you can, but not of the SAME benefit.</p>
            <p><strong>Do you have an allergens list available?</strong></p>
            <p>You can find our full allergens list <a href="#" @click="openAllergensWebpage">here</a></p>
            <p><strong>Are your restaurants halal certified?</strong></p>
            <p>Betty&rsquo;s is not independently halal certified, however our chicken and beef products are prepared
                halal from our suppliers. Upon request, when preparing grilled chicken burgers, we can offer our guests
                the option of cleaning our grill prior to cooking to ensure no pork or beef products are present. We can
                also prepare a grilled mushroom burger under the same conditions. Unfortunately, our fried products are
                prepared with equipment that is used in conjunction with pork product preparation.</p>
            <p><strong>Do you have vegetarian or vegan options?</strong></p>
            <p>We have a Shroom burger that is vegetarian friendly and a Betty&rsquo;s Classic Vegan that is both
                vegetarian and vegan friendly.</p>
            <p><strong>Do you offer gluten friendly menu items?</strong></p>
            <p>All of our restaurants offer a gluten free bun as well as a &ldquo;bare&rdquo; burger option that&rsquo;s
                wrapped in lettuce as a substitute for our buns. For our coeliac guests, we toast our gluten free buns
                on a separate grill to avoid cross contamination. We can make all our beef burgers gluten friendly and
                we have a Betty&rsquo;s Classic Vegan that is gluten friendly. Be advised that our Shroom and Crispy
                Chicken burgers do contain gluten, however, upon request we can prepare a grilled version that are
                gluten friendly. Our chips are cooked in a separate fryer in most restaurants to avoid them containing
                gluten, however we do recommend that you confirm this with the restaurant upon ordering. Our vanilla and
                chocolate frozen custard are gluten friendly, however some of the mix-ins may contain gluten, so please
                ask a team member which ones you should avoid when ordering. Unfortunately, we must note some of our
                products may contain traces of gluten due to the sheer volume within our restaurants and our supply
                chain.</p>
            <p><strong>Do you offer dairy friendly menu options?</strong></p>
            <p>Our general burger buns contain milk so we would suggest you opt for our gluten friendly bun and request
                we toast the bun without butter to ensure we prepare you a dairy free option. It&rsquo;s also worth
                noting our delicious signature Betty&rsquo;s sauce is also dairy friendly.</p>
            <p><strong>Are your products pasteurised?</strong></p>
            <p>Our sauces and frozen custards are all pasteurised.</p>
            <p><strong>What is a Concrete?</strong></p>
            <p>Concrete is the name we&rsquo;ve given to our in house-made, frozen custard desserts. All of our
                Concretes consist of either vanilla or chocolate frozen custard which are made on site daily, then
                blended at a high speed with your various mix-in ingredients.</p>
            <p><strong>Can you share your menus kilojoules or calories?</strong></p>
            <p>Due to the size of our business we are not yet required by law to have our menu recipes measured by
                industry authorities. We can happily provide you with a contents list of each of our menu items in case
                of allergens, but unfortunately not the specific weights or kilojoules/ calories.</p>
            <p><strong>Are you a licenced restaurant?&nbsp;</strong></p>
            <p>All of our restaurants are licensed. We serve a selection of boutique beers, wines and ciders.</p>
            <p><strong>Does any of your food contain alcohol?&nbsp;</strong></p>
            <p>We do not use alcohol to prepare any of our food.</p>
            <p><strong>Do your restaurants offer delivery?&nbsp;</strong></p>
            <p>Via the app you can order ahead for in-restaurant pick-up only. We do not offer delivery via the app.</p>
            <p><strong>Can I change my personal details?</strong></p>
            <p>Yes, all the details you entered when you registered can be changed. Just log into the app, head to the
                your <em>Profile</em> section, click on <em>Your Account</em> and follow the prompts to change your
                details, remembering to select <em>SAVE</em> when updated.</p>
            <p><strong>My barcode won&rsquo;t scan in-restaurant?!</strong></p>
            <p>There may be a couple of different reasons for this;&nbsp;</p>
            <p>If your phone screen is cracked the scanner won&rsquo;t be able to read your barcode.&nbsp;</p>
            <p>Sometimes the world wide web isn&rsquo;t our friend. If you have a poor connection you won&rsquo;t be
                able to use the app. </p>
        </div>
    </f7-page>
</template>
<script>
    import BasePage from './BasePage';
    import Env from "../Env";

    export default {
        extends: BasePage,
        methods: {
            openEmail(email) {
                document.location.href = `mailto:${email}`;
            },
            openAllergensWebpage() {
                window.open(`${Env.url}/allergens`, '_system');
            },
            closePage()
            {
                if (this.$f7router.view.name==='main')
                {
                    this.$f7router.back();
                }
                else
                {
                    this.$f7router.emit('view:close')
                }
            }
        }
    };
</script>
