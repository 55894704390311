<template>
  <f7-page class="HomePageV2"
           v-if="responsiveStyle==='desktop'"
  >
    <template slot="fixed" v-if="view.isRightPanelView()">
      <div class="bettys-burgers-panel-overlay">
        <div class="bettys-burgers-panel right elevation-1">
          <f7-view url="/checkout/" name="right" @view:init="onRightViewInit">
          </f7-view>
        </div>
      </div>
    </template>
    <!--faqs-->
    <template slot="fixed" v-if="view.isFaqsView()">
      <div class="bettys-burgers-panel-overlay">
        <div class="bettys-burgers-panel right elevation-1">
          <f7-view url="/faqs/" name="common" @view:init="onCommonViewInit">
          </f7-view>
        </div>
      </div>
    </template>
    <template slot="fixed" v-if="view.isContactUsView()">
      <div class="bettys-burgers-panel-overlay">
        <div class="bettys-burgers-panel right elevation-1">
          <f7-view url="/contact-us/" name="common" @view:init="onCommonViewInit">
          </f7-view>
        </div>
      </div>
    </template>
    <template slot="fixed" v-if="view.isProductView()">
      <div class="bettys-burgers-popup-overlay">
        <div class="bettys-burgers-popup">
          <ProductComponent @close="view.changeToBasicView()"
                            @cart-updated="updateCart($event.cart)"
                            @show-toast="showCartToast($event.itemsCount)"
                            :product="activeProduct"
                            :menu="menu" :cart="cart"/>
        </div>
      </div>
    </template>
    <template slot="fixed" v-if="view.isStoresView()">
      <div class="bettys-burgers-popup-overlay">
        <div class="bettys-burgers-popup">
          <MapComponent @close="view.changeToBasicView()"
                        @store-selected="changePickupStore($event.store)"></MapComponent>
        </div>
      </div>
    </template>
    <div class="HomePageV2__Banner--Fixed" v-if="showFixedMenu">
      <div class="container display-flex align-items-center justify-content-space-between">
        <div class="HomePageV2__Banner--Fixed__Left">
          <a href="/" style="margin-left:70px">
            <img class="HomePageV2__Nav__Logo" src="static/images/HomePageV2/logo.png" alt="logo">
          </a>
        </div>
        <div class="HomePageV2__Banner--Fixed__Right margin-right-6em">
          <a @click="showMenu" class="HomePageV2__Nav__Link">
            Menu
          </a>
          <f7-link external href="https://www.bettysburgers.com.au/locations/" class="HomePageV2__Nav__Link">
            Locations
          </f7-link>
          <a @click="showMenu" class="HomePageV2__Nav__Link">
            Order Online
          </a>
          <f7-link external href="https://www.bettysburgers.com.au/contact-us/" class="HomePageV2__Nav__Link">
            Contact us
          </f7-link>
          <f7-link external href="https://www.bettysburgers.com.au/faqs/" class="HomePageV2__Nav__Link">
            FAQs
          </f7-link>
          <f7-link :class="`HomePageV2__CartButton ${this.beepingCart?'active':null}`"
                   @click="openRightPanel">
            <div class="dot">&bullet;</div>
            <i class="bettys-burgers-icon bettys-burgers-cart-icon"/>
            <f7-badge class="qty dark" v-if="cart.getQty()>0">{{ cart.getQty() }}</f7-badge>
          </f7-link>
        </div>
      </div>
    </div>
    <div class="HomePageV2__Banner">
      <div v-if="member.isValid()" class="bg-color-black display-flex justify-content-flex-end padding-half">
        <f7-link color="white" fill small @click="signOut">Logout</f7-link>
      </div>
      <div class="container">
        <div class="HomePageV2__Nav display-flex align-items-center justify-content-space-between">
          <div>
            <a href="/">
              <img class="HomePageV2__Nav__Logo" src="static/images/HomePageV2/logo.png" alt="logo">
            </a>
          </div>
          <div>
            <a @click="showMenu" class="HomePageV2__Nav__Link">
              Menu
            </a>
            <f7-link external href="https://www.bettysburgers.com.au/locations/"
                     class="HomePageV2__Nav__Link">
              Locations
            </f7-link>
            <a @click="showMenu" class="HomePageV2__Nav__Link">
              Order Online
            </a>
            <f7-link external href="https://www.bettysburgers.com.au/contact-us/"
                     class="HomePageV2__Nav__Link">
              Contact us
            </f7-link>
            <f7-link external href="https://www.bettysburgers.com.au/faqs/" class="HomePageV2__Nav__Link">
              FAQs
            </f7-link>
            <f7-link :class="`fb__forward HomePageV2__CartButton ${this.beepingCart?'active':null}`"
                     @click="openRightPanel">
              <div class="dot">&bullet;</div>
              <i class="bettys-burgers-icon bettys-burgers-cart-icon"/>
              <f7-badge class="qty" v-if="cart.getQty()>0">{{ cart.getQty() }}</f7-badge>
            </f7-link>
          </div>
        </div>
      </div>
    </div>
    <div class="HomePageV2__FindRestaurant display-flex align-items-center justify-content-center">
      <div class="container">
        <a class="HomePageV2__FindRestaurant__Link" @click="findNearestRestaurant">
          <i class="HomePageV2__FindRestaurant__Icon bettys-burgers-web-3 bettys-burgers-web-3-Location-Icon"/>
          <span class="margin-horizontal-23">
                       <template v-if="pickupStore.isValid()">
                           {{ pickupStore.name }}
                       </template>
                        <template v-else>
                             Find your nearest Restaurant
                        </template>
                    </span>
          <i class="HomePageV2__FindRestaurant__Icon bettys-burgers-web-3 bettys-burgers-web-3-Down-Icon"/>
        </a>
      </div>
    </div>
    <div class="container-990" id="menu">
      <div class="HomePageV2__TabBars">
        <div v-for="(category, categoryIndex) in menu.categories"
             @click="changeCategory(categoryIndex)"
             :class="activeCategoryIndex === categoryIndex?'active':null"
        >
          {{ category.editedName }}
        </div>
      </div>
    </div>

    <div class="HomePageV2__Categories">
      <div class="HomePageV2__Category"
           v-for="(category, categoryIndex) in menu.categories"
           v-if="activeCategoryIndex===categoryIndex"
      >
        <div class="container-990">
          <div class="HomePageV2__Category__Title">
            {{ category.editedName }}
          </div>
          <div class="CheckoutPage__Item__Ingredients"
               style="line-height: 1.5em; text-align: center; max-width: 100%; margin-bottom: 50px"
               v-if="isAlcoholCategory(category)"
          >
            The ordering of alcohol is only available at the Register in Restaurant. Please have photographic ID ready
            for checking when purchasing.
          </div>
          <div class="row HomePageV2__Products">
            <div class="HomePageV2__Product
                        display-flex flex-direction-row align-items-flex-start justify-content-space-between"
                 v-for="product in category.products"
                 @click="showProductV2(product)"
            >
              <div>
                <div class="HomePageV2__Product__Title">
                  {{ product.editedName }}
                </div>
                <div class="HomePageV2__Product__Description">
                  {{ product.description }}
                </div>
              </div>
              <div class="display-flex align-items-flex-start justify-content-flex-start">
                <div class="HomePageV2__Product__Price">
                  {{ menu.map[product.ProductID].price | total }}
                </div>
                <f7-button fill v-if="
                                product.isSimplified
                                && !isViewOnly(category)" class="HomePageV2__Product__Add"
                           @click="addToCart(product)"
                >
                  <i class="bettys-burgers-web-3 bettys-burgers-web-3-Quick-Add-Button">
                    <span class="path2"></span>
                  </i>
                </f7-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </f7-page>
  <f7-page class="HomePage"
           @page:afterin="checkStatus"
           @page:afterout="beepingCart=false"
           v-else-if="responsiveStyle==='mobile'"
  >
    <div class="HomePage__Banner">
      <div v-if="member.isValid()" class="bg-color-black display-flex justify-content-flex-end padding-half">
        <f7-link color="white" fill small @click="signOut">Logout</f7-link>
      </div>
      <div class="HomePage__Banner__Top">
        <f7-button external href="https://www.bettysburgers.com.au/" class="fb__backward fb__30"
                   icon-material="arrow_back" color="white">
        </f7-button>
        <div class="text-align-center flex-grow-1">
          <f7-link external href="https://www.bettysburgers.com.au/">
            <img class="bettys-burgers-white-logo" src="static/images/logo--white.png"/>
          </f7-link>
        </div>
        <f7-button :class="`fb__forward HomePage__CartButton ${this.beepingCart?'active':null}`"
                   href="/checkout/">
          <div class="dot">&bullet;</div>
          <i class="bettys-burgers-icon bettys-burgers-cart-icon"/>
          <f7-badge class="qty" v-if="cart.getQty()>0">{{ cart.getQty() }}</f7-badge>
        </f7-button>
      </div>
      <f7-block class="HomePage__Banner__Header">
        Order online and save time!
      </f7-block>
      <f7-block>
        <div class="HomePage__Promo">Download our app for exclusive benefits!</div>
      </f7-block>
      <f7-block>
        <f7-row>
          <f7-col>
            <f7-link @click="openAppStore">
              <img
                  style="height: 45px" src="static/images/HomePage/AppStore.png">
            </f7-link>
          </f7-col>
          <f7-col class="text-align-right">
            <f7-link @click="openPlayStore">
              <img style="height: 45px" src="static/images/HomePage/GooglePlay.png">
            </f7-link>
          </f7-col>
        </f7-row>
      </f7-block>
    </div>
    <div class="HomePage__ViewMenu" @click="$f7router.navigate('/menu/')">
      <div>
        View Menu
      </div>
      <div>
        <f7-icon class="HomePage__ViewMenu__Icon" material="chevron_right"></f7-icon>
      </div>
    </div>
    <div class="HomePage__Content">
      <div>
        <div class="HomePage__Featured">
          <div class="HomePage__Featured__Item"
               :style="`background-image:url('${getImageUrl(featuredItem.productImage)}')`"
               v-for="featuredItem in menu.featuredItems"
               @click="showProduct(featuredItem)"
          >
                        <span class="HomePage__Featured__Item__Title">
                            {{ featuredItem.editedName }}
                       </span>
          </div>
        </div>
      </div>
    </div>


  </f7-page>
  <f7-page v-else>
    <f7-block>
      <f7-progressbar infinite></f7-progressbar>
    </f7-block>
  </f7-page>
</template>
<script>
import BasePage from "./base";
import Menu from "../models/Menu";
import ProductComponent from "../components/ProductComponent";
import Product from "../models/Product";
import Cart from "../models/Cart";
import View from "../models/View";
import MapComponent from "../components/MapComponent";
import Store from "../models/Store";
import Member from "../models/Member";
import Token from "../models/Token";
import CartItem from "../models/CartItem";
import DataLayerHelper from "../models/DataLayerHelper";

export default {
  components: {MapComponent, ProductComponent},
  extends: BasePage,
  data: function () {
    return {
      menu: new Menu(),
      product: new Product(),
      activeCategoryIndex: 0,
      activeProduct: new Product(),
      cart: new Cart(),
      beepingCart: false,
      pickupStore: new Store(),
      view: new View([
        View.enums.BASIC,
        View.enums.PRODUCT,
        View.enums.STORES,
        View.enums.RIGHT_PANEL,
        View.enums.FAQS,
        View.enums.CONTACT_US
      ]),
      member: new Member(),
      token: new Token(),
      cartToast: null,
      showFixedMenu: false,
      responsiveStyle: null
    }
  },
  mounted() {
    Menu.get().then((response) => {
      if (response.success) {
        this.menu.fill(response.menu);
        this.menu.store();
        this.activeProduct.fill(this.menu.categories[0].products[0])

        DataLayerHelper.logCategoryView(this.menu.categories[this.activeCategoryIndex].editedName)
      }
    });
    this.cart.restore();
    this.pickupStore.restore();
    this.member.restore();
    this.token.restore();
    this.view.changeToBasicView();

    document.querySelector('.page-content').addEventListener('scroll', () => {
      this.showFixedMenu = document.querySelector('.page-content').scrollTop > 90;
    })

    if (window.innerWidth <= 496)
      this.responsiveStyle = 'mobile';
    else
      this.responsiveStyle = 'desktop';


  },
  methods: {
    showMenu() {
      document.querySelector('#menu').scrollIntoView(
          {
            behavior: 'smooth'
          }
      )
    },
    checkStatus() {
      this.cart.restore();
      if (this.cart.recentlyAdded) {
        this.beepingCart = true;
        this.cart.recentlyAdded = false;
        this.cart.store();
      }
    },
    findNearestRestaurant() {
      this.view.changeView(View.enums.STORES)
    },
    addToCart(product) {

      DataLayerHelper.logProductAddToCart(product.editedName)

      let cartItem = CartItem.fromProduct(product);
      this.cart.addToCart(cartItem);
      this.beepingCart = true;
      setTimeout(() => {
        this.beepingCart = false
      }, 500);
      this.cart.store();
      this.showCartToast(1)
    },
    dataLayerLogProductView(product) {
      const category = this.menu.categories.find(category => category.id === product.category_id);
      DataLayerHelper.logProductView(product.editedName, category.editedName);
    },
    showProduct(product) {
      this.dataLayerLogProductView(product);
      this.product.fill(product);
      this.product.store();
      this.$f7router.navigate('/product/');
    },
    showProductV2(product) {

      if (!product.isSimplified) {
        this.dataLayerLogProductView(product);
        this.beepingCart = false;
        this.activeProduct.fill(product);
        this.view.changeView(View.enums.PRODUCT)
        this.cartToast.close();
      }
    },
    changePickupStore(store) {
      DataLayerHelper.logStoreChange(store.name)
      this.pickupStore.fill(store);
      this.pickupStore.store();
      this.view.changeToBasicView();
    },
    updateCart(cart) {
      this.cart.fill(cart);
      this.cart.store();
      if (this.cart.recentlyAdded) {
        this.beepingCart = true;
        this.cart.recentlyAdded = false;
        setTimeout(() => {
          this.beepingCart = false;
        }, 500)
      }
    },
    showCartToast(itemsCount) {
      this.cartToast = this.$f7.toast.create({
        position: 'bottom',
        text: `${itemsCount} items added`,
        closeButton: true,
        closeButtonText: 'View Cart',
        closeButtonColor: 'white',
        // closeTimeout: 15000,
        on: {
          'closeButtonClick': () => {
            this.view.changeToRightPanelView()
          }
        }
      });
      this.cartToast.open();
    },
    signOut: function () {
      this.$f7.dialog.confirm('Are you sure?', () => {
        this.member.purge();
        this.token.purge();
        window.location.reload(true)
      });
    },
    openRightPanel() {
      this.view.changeToRightPanelView();
      this.cartToast.close();
    },
    onRightViewInit() {
      this.$f7.views.right.on('view:close', () => {
        this.view.changeToBasicView();
        this.checkStatus();
      })
    },
    onCommonViewInit() {
      this.$f7.views.common.on('view:close', () => {
        this.view.changeToBasicView();
      })
    },
    openAppStore() {
      // gtag('event', 'homepage_appstore_button_click');
      window.open('https://apps.apple.com/au/app/bettys-burgers/id1499423312', '_blank')
    },
    openPlayStore() {
      // gtag('event', 'homepage_playstore_button_click');
      window.open('https://play.google.com/store/apps/details?id=com.retailzoo.bettysburgers', '_blank')
    },
    isViewOnly(category) {
      return category.editedName.toLowerCase().trim() === 'alcohol'
    },
    isAlcoholCategory(category) {
      return category.editedName.toLowerCase().trim() === 'alcohol'
    },
    changeCategory(categoryIndex) {
      this.activeCategoryIndex = categoryIndex;

      DataLayerHelper.logCategoryView(this.menu.categories[categoryIndex].editedName)
    },
    findProduct(productId) {

      let foundProduct = null;

      this.menu.categories.forEach((category) => {
        const matchedProduct = category.products.find((product) => {
          return product.ProductID === productId;
        });

        if (matchedProduct !== undefined) {
          foundProduct = matchedProduct;
        }
      })

      return foundProduct;
    }
  }
}
</script>
<style>
.md .toast.toast-bottom {
  left: auto !important;
  right: 24px !important;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 45px;
  margin-left: 8%;
  max-width: 80%;
}

img {
  max-width: 100%;
  height: auto;
}

@media screen and (max-width: 1150px) {
  .image-container {
    max-width: 100%;
    margin-left: 0;
  }
}

.image-container-mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -62px;
  max-width: 120%;
  height: auto;
}
</style>