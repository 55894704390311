import BaseModel from "./BaseModel";
import CartItem from "./CartItem";

export default class Menu extends BaseModel {

    categories;
    upsells;
    ingredientCategories;
    map;
    featuredItems;

    constructor(data) {
        super();

        if (data !== undefined) {
            Object.keys(this).forEach((key) => {
                this[key] = data[key]
            })
        } else {
            this.categories = [];
            this.upsells = [];
            this.ingredientCategories = [];
            this.map = [];
            this.featuredItems = [];
        }
    }

    fill(data) {
        Object.keys(this).forEach((key) => {
            this[key] = data[key]
        })
    }

    static get() {
        const storeID = localStorage.getItem('storeID')
        let url = storeID ? `api/public/menu-v3?storeID=${storeID}` : "api/public/menu-v2";
        console.log(url);
        return super.get(url)
        
    }

    restore() {
        if (window.BettysBurgersMenu !== undefined) {
            this.fill(window.BettysBurgersMenu);
        }
    }

    store() {
        window.BettysBurgersMenu = this;
    }

    getProduct(ProductID) {
        let rs = null;
        this.categories.forEach((category) => {
            category.products.forEach((product) => {
                if (product.ProductID === ProductID) {
                    rs = product;
                }
            })
        });

        return rs;
    }
}