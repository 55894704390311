<template>
  <f7-page name="checkout" class="PaymentPage"
           @page:afterin="onAfterIn"
           @page:beforeout="onBeforeOut"
  >
    <f7-navbar no-shadow class="PaymentPage__NavBar">
      <f7-nav-left>
        <i @click="$f7router.back()"
           class="PaymentPage__Icon--Left bettys-burgers-web-2 bettys-burgers-web-2-Arrow-Back-Icon"/>
      </f7-nav-left>
      <f7-nav-title class="width-100 text-align-center">Payment</f7-nav-title>
      <f7-nav-right>
        <i class="PaymentPage__Icon--Right bettys-burgers-icon bettys-burgers-cart-icon"/>
      </f7-nav-right>
    </f7-navbar>
    <div class="PaymentPage__Content" v-if="loaded">
      <f7-list no-hairlines class="margin-vertical-half">
        <f7-list-item>
          <div slot="title">
            <div class="PaymentPage__PickupTimeLabel">
              Pickup Time:
            </div>
            <div class="PaymentPage__PickupTimeValue">
              {{ pickupTime.isASAP ? 'ASAP' : pickupTime.getHourValue() }}
            </div>
          </div>
        </f7-list-item>
      </f7-list>
      <f7-list no-hairlines v-if="pickupStore" class="margin-vertical-half">
        <f7-list-item>
          <div slot="title">
            <div class="PaymentPage__Store__Title">
              {{ pickupStore.name }}
            </div>
            <div class="PaymentPage__Store__Address">
              {{ pickupStore.address1 }}
            </div>
          </div>
          <div slot="after" class="PaymentPage__Store__Status">
            <f7-icon f7="checkmark_alt_circle_fill"/>
          </div>
        </f7-list-item>
      </f7-list>
      <f7-list no-hairlines class="no-margin-vertical">
        <f7-list-item>
          <div slot="title">
            <div class="PaymentPage__PaymentMethod">
              Payment Method
            </div>
          </div>
        </f7-list-item>
      </f7-list>
      <div v-if="paymentType==='cc'">
        <f7-list class="no-margin-vertical" v-if="creditCard.isValid()">
          <f7-list-item>
            <div slot="media" :class="`card-${creditCard.brand.toLowerCase()}`">
              {{ creditCard.brand }}
            </div>
            <div slot="title">
              <div class="PaymentPage__Card__Name">
                {{ creditCard.name }}
              </div>
              <div class="PaymentPage__Card__Last4">
                Card ending in {{ creditCard.last4 }}
              </div>
            </div>
            <div slot="after" class="PaymentPage__Card__Change">
              <f7-link href="/credit-card/">Change</f7-link>
            </div>
          </f7-list-item>
        </f7-list>
        <f7-list class="no-margin-vertical" no-hairlines v-else>
          <f7-list-item link="/credit-card/">
            Manage your credit cards
          </f7-list-item>
        </f7-list>
      </div>
      <div>

        <f7-block>
          <button type="button"
                  class="gpay-button button width-100"
                  v-if="enableGooglePay" @click="checkoutWithGooglePay"

          />
        </f7-block>
        <f7-block>
          <button type="button"
                  class="apay-button button width-100"
                  v-if="enableApplePay" @click="checkoutWithApplePay"
          />
        </f7-block>
      </div>
    </div>
    <f7-block v-else>
      <f7-progressbar infinite></f7-progressbar>
    </f7-block>
    <div slot="fixed" class="PaymentPage__DockBottom">
      <div class="left">
        {{ cart.getTotal(menu.map) | total }}
      </div>
      <div class="right">
        <f7-button :disabled="!creditCard.isValid()" large
                   fill
                   @click="checkout"
        >
          Pay Now
        </f7-button>
      </div>
    </div>
  </f7-page>
</template>
<script>
import BasePage from './base'
import Cart from '../models/Cart'
import Store from "../models/Store";
import Menu from "../models/Menu";
import PickupTime from "../models/PickupTime";
import Member from "../models/Member";
import Token from "../models/Token";
import Device from "../models/Device";
import CreditCard from "../models/CreditCard";
import Checkout from "../models/Checkout";
import moment from "moment";
import Invoice from "../models/Invoice";
import Env from "../Env";

export default {
  extends: BasePage,
  data() {
    return {
      cart: new Cart(),
      menu: new Menu(),
      member: new Member(),
      token: new Token(),
      device: new Device(),
      creditCard: new CreditCard(),
      pickupStore: new Store(),
      pickupTime: new PickupTime(),
      invoice: new Invoice(),
      paymentType: 'cc',
      enableGooglePay: false,
      enableApplePay: false,
      loaded: false
    }
  },
  methods: {
    onAfterIn() {
      this.member.restore();
      this.token.restore();
      this.device.restore();
      this.cart.restore();
      this.menu.restore();
      this.pickupStore.restore();
      this.pickupTime.restore();
      this.idempotencyKey = `${moment().unix()}`;
      this.loadUserPaymentData();
      if (this.device.platform === 'ios')
        this.enableApplePay = true;
      if (this.device.platform === 'android')
        this.enableGooglePay = true;

      // for dev
      if (Env.env === 'dev') {
        this.enableGooglePay = true;
        this.enableGooglePay = true;
      }

    },
    onBeforeOut() {
      this.loaded = false;
    },
    loadUserPaymentData() {
      Promise.all([
        CreditCard.get(this.member.CustomerID),
        Menu.get(),
        Member.load(this.member.Email, this.token.token, this.device)
      ]).then(([
                 creditCardResponse,
                 menuResponse,
                 memberResponse
               ]) => {
        if (creditCardResponse.success) {
          this.creditCard.fill(creditCardResponse.creditCard);
          this.creditCard.store();
        } else
          this.$f7.dialog.alert(creditCardResponse.message, 'Error');

        if (menuResponse.success) {
          this.menu.fill(menuResponse.menu);
          this.menu.store();
        } else
          this.$f7.dialog.alert(menuResponse.message, 'Error');

        if (memberResponse.success) {
          this.member.fill(memberResponse.member);
          this.token.fill(memberResponse.token);
          this.member.store();
          this.token.store();
        }

        if (this.member.Mobile === null) {
          //prompt nicely
          let mobileDialog = this.$f7.dialog.create(
              {
                title: 'Contact number',
                text: 'Please provide your mobile number should we need to call you about your order.',
                content: `<div class="dialog-input-field input"><input type="number" pattern="[0-9]*" class="dialog-input"></div>`,
                buttons: [
                  {
                    text: 'Dismiss',
                  },
                  {
                    text: 'Save',
                    bold: true,
                    onClick: (dialog) => {
                      const Mobile = dialog.$el.find('.dialog-input').val();
                      this.$f7.preloader.show();
                      Member.updateMobile(
                          this.member,
                          this.token.token,
                          this.device,
                          Mobile
                      ).then((response) => {
                        this.$f7.preloader.hide();
                        this.$f7.dialog.alert(response.message);
                        if (response.success) {
                          this.member.fill(response.member);
                          this.token.fill(response.token);
                          this.member.store();
                          this.token.store();
                        } else {
                          mobileDialog.open()
                        }
                      })
                    },
                  },
                ],
              }
          );

          mobileDialog.open();
        }
        this.loaded = true;
      })

    },
    checkout() {

      if (!this.cart.isValid(this.menu.map)) {
        this.$f7.dialog.confirm('Your cart is invalid', () => {
          this.$f7router.navigate('/checkout/');
        }, () => {
          this.$f7router.navigate('/checkout/');
        });
        return;
      }
      let cart = this.cart.toRedcatStd(this.menu.map);

      let orderTotal = this.cart.getTotal(this.menu.map);
      let pickupTime = this.pickupTime.getValue();

      if (this.creditCard.isValid()) {
        this.$f7.preloader.show();
        Checkout.validate(this.member.Email,
            this.token.token,
            cart,
            orderTotal,
            this.member.MemberNo,
            pickupTime,
            this.pickupStore.id,
            this.device.platform).then((response) => {
          this.$f7.preloader.hide();
          if (response.success) {
            this.$f7.preloader.show();
            Checkout.dispatch(
                this.member.Email,
                this.token.token,
                cart,
                orderTotal,
                this.member.CustomerID,
                this.member.MemberNo,
                this.member.ID,
                pickupTime,
                this.pickupTime.isASAP,
                this.pickupStore.id,
                this.pickupStore.name,
                this.device.platform,
                this.member.Mobile !== null ? `Mobile: ${this.member.Mobile}` : null,
                this.idempotencyKey
            ).then((response) => {
              this.$f7.preloader.hide();
              if (response.success) {
                this.invoice.fill(response.invoice);
                this.invoice.store();
                this.$f7router.navigate('/order-received/');
              } else {
                this.$f7.dialog.alert(response.message);
                this.idempotencyKey = `${moment().unix()}`;
              }
            })
          } else {
            this.$f7.preloader.hide();
            this.$f7.dialog.alert(response.message)
          }
        });
      }
    },
    checkoutWithGooglePay() {
      try {

        if (!this.cart.isValid(this.menu.map)) {
          this.$f7.dialog.confirm('Your cart is invalid', () => {
            this.$f7router.navigate('/checkout/');
          }, () => {
            this.$f7router.navigate('/checkout/');
          });
          return;
        }
        let cart = this.cart.toRedcatStd(this.menu.map);

        let orderTotal = this.cart.getTotal(this.menu.map);
        let pickupTime = this.pickupTime.getValue();

        this.$f7.preloader.show();
        Checkout.validate(this.member.Email,
            this.token.token,
            cart,
            orderTotal,
            this.member.MemberNo,
            pickupTime,
            this.pickupStore.id,
            this.device.platform).then((response) => {
          this.$f7.preloader.hide();
          if (response.success) {
            sgap.setKey(Env.googlePayPublishableKey).then(() => {
              sgap.isReadyToPay().then(() => {
                sgap.requestPayment(orderTotal / 100, 'AUD').then(
                    (googlePayToken) => {
                      this.$f7.preloader.show();
                      Checkout.dispatchGooglePay(
                          this.member.Email,
                          this.token.token,
                          cart,
                          orderTotal,
                          this.member.CustomerID,
                          this.member.MemberNo,
                          this.member.ID,
                          pickupTime,
                          this.pickupTime.isASAP,
                          this.pickupStore.id,
                          this.pickupStore.name,
                          this.device.platform,
                          this.member.Mobile !== null ? `Mobile: ${this.member.Mobile}` : null,
                          googlePayToken,
                          this.idempotencyKey
                      ).then((response) => {
                        this.$f7.preloader.hide();
                        if (response.success) {
                          this.invoice.fill(response.invoice);
                          this.invoice.store();
                          this.$f7router.navigate('/order-received/');
                        } else {
                          this.$f7.dialog.alert(response.message);
                          this.idempotencyKey = `${moment().unix()}`;
                        }
                      })
                    }).catch((err) => {
                  this.$f7.preloader.hide();
                  alert(err);
                });
              }).catch((err) => {
                this.$f7.preloader.hide();
                alert(err);
              });
            }).catch((err) => {
              this.$f7.preloader.hide();
              alert(err);
            });
          } else {
            this.$f7.preloader.hide();
            this.$f7.dialog.alert(response.message)
          }
        });
      } catch (e) {
      }
    },
    checkoutWithApplePay() {
      try {

        if (!this.cart.isValid(this.menu.map)) {
          this.$f7.dialog.confirm('Your cart is invalid', () => {
            this.$f7router.navigate('/checkout/');
          }, () => {
            this.$f7router.navigate('/checkout/');
          });
          return;
        }
        let cart = this.cart.toRedcatStd(this.menu.map);

        let orderTotal = this.cart.getTotal(this.menu.map);
        let pickupTime = this.pickupTime.getValue();

        this.$f7.preloader.show();
        Checkout.validate(this.member.Email,
            this.token.token,
            cart,
            orderTotal,
            this.member.MemberNo,
            pickupTime,
            this.pickupStore.id,
            this.device.platform).then((response) => {
          this.$f7.preloader.hide();
          if (response.success) {

            ApplePay.canMakePayments().then((message) => {
              ApplePay.makePaymentRequest({
                items: [{
                  label: "Betty's Burger App Order",
                  amount: orderTotal / 100
                }],
                currencyCode: 'AUD',
                countryCode: 'AU',
              }).then((paymentResponse) => {
                this.$f7.preloader.show();
                let applePayToken = paymentResponse.stripeToken;
                Checkout.dispatchApplePay(
                    this.member.Email,
                    this.token.token,
                    cart,
                    orderTotal,
                    this.member.CustomerID,
                    this.member.MemberNo,
                    this.member.ID,
                    pickupTime,
                    this.pickupTime.isASAP,
                    this.pickupStore.id,
                    this.pickupStore.name,
                    this.device.platform,
                    this.member.Mobile !== null ? `Mobile: ${this.member.Mobile}` : null,
                    applePayToken,
                    this.idempotencyKey
                ).then((response) => {
                  this.$f7.preloader.hide();
                  if (response.success) {
                    ApplePay.completeLastTransaction('success');
                    this.invoice.fill(response.invoice);
                    this.invoice.store();
                    this.$f7router.navigate('/order-received/');
                  } else {
                    ApplePay.completeLastTransaction('failure');
                    this.$f7.dialog.alert(response.message);
                    this.idempotencyKey = `${moment().unix()}`;
                  }
                })

              }).catch((e) => {
              });
            }).catch((message) => {
              this.$f7.dialog.alert('Apple pay is not supporting on this device.');
            });
          } else {
            this.$f7.preloader.hide();
            this.$f7.dialog.alert(response.message)
          }
        });
      } catch (e) {
      }
    }
  }
}
</script>
<style scoped>
</style>
