export default class {

    latitude;
    longitude;

    constructor(data) {
        this.latitude = null;
        this.longitude = null;
        if (data !== null && data !== undefined) {
            this.fill(data)
        }
    }

    isValid() {
        return this.latitude !== null && this.longitude !== null;
    }

    fill(data) {
        for(const key in data){
            this[key] = data[key];
        }
    }
}
