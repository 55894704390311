import BaseModel from "./BaseModel";
import moment from 'moment';
import G4Cookie from "../G4Cookie";

export default class Checkout extends BaseModel {

    static validatePickupTime(storeID, pickupTime) {

    }

    static validateCart() {

    }

    static validate(email, token, cart, orderTotal, memberNo, pickupTime, storeID, platform) {
        //validate cart
        return super.post('api/public/checkout/validate', {
            email: email,
            token: token,
            cart: cart,
            orderTotal: orderTotal,
            memberNo: memberNo,
            pickupTime: pickupTime,
            storeID: storeID,
            platform: platform,
            paymentType: "cc"
        })
    }

    static dispatch(email, token, cart, orderTotal, customerID, memberNo, memberId, pickupTime, pickupTimeASAP, storeID, storeName, platform, orderNotes, idempotencyKey) {
        //making cart here
        // inputType: 350 E7 Web Order
        return super.post(`api/public/checkout/cc`, {
            email: email,
            token: token,
            cart: cart,
            orderTotal: orderTotal,
            customerID: customerID,
            memberNo: memberNo,
            memberId: memberId,
            pickupTime: pickupTime,
            pickupTimeASAP: pickupTimeASAP,
            storeID: storeID,
            storeName: storeName,
            platform: platform,
            orderNotes: orderNotes,
            idempotencyKey: idempotencyKey,
            G4Cookie: G4Cookie.getCookie(),
            inputType: 350,
        })
    }

    static dispatchGooglePay(email, token, cart, orderTotal, customerID, memberNo, memberId, pickupTime, pickupTimeASAP, storeID, storeName, platform, orderNotes, googlePayToken, idempotencyKey) {
        //making cart here
        // inputType: 350 E7 Web Order
        return super.post(`api/public/checkout/gp`, {
            email: email,
            token: token,
            cart: cart,
            orderTotal: orderTotal,
            memberNo: memberNo,
            memberId: memberId,
            pickupTime: pickupTime,
            pickupTimeASAP: pickupTimeASAP,
            storeID: storeID,
            storeName: storeName,
            idempotencyKey: idempotencyKey,
            platform: platform,
            orderNotes: orderNotes,
            googlePayToken: googlePayToken,
            G4Cookie: G4Cookie.getCookie(),
            inputType: 350,
        })
    }

    static dispatchApplePay(email, token, cart, orderTotal, customerID, memberNo, memberId, pickupTime, pickupTimeASAP, storeID, storeName, platform, orderNotes, applePayToken, idempotencyKey) {
        //making cart here
        // inputType: 350 E7 Web Order
        return super.post(`api/public/checkout/ap`, {
            email: email,
            token: token,
            cart: cart,
            orderTotal: orderTotal,
            memberNo: memberNo,
            memberId: memberId,
            pickupTime: pickupTime,
            pickupTimeASAP: pickupTimeASAP,
            storeID: storeID,
            storeName: storeName,
            idempotencyKey: idempotencyKey,
            platform: platform,
            orderNotes: orderNotes,
            applePayToken: applePayToken,
            G4Cookie: G4Cookie.getCookie(),
            inputType: 350,
        })
    }
}
