export default class {
    token;

    constructor(token) {
        if (token !== null && token !== undefined)
            this.token = token;
        else
            this.token = null;
    }

    //restore from cache
    restore() {
        this.token = localStorage.getItem('bettysburger-v2-token');
    }

    //save to cache
    store() {
        localStorage.setItem('bettysburger-v2-token', this.token)
    }

    purge() {
        localStorage.removeItem('bettysburger-v2-token')
    }

    fill(token)
    {
        this.token = token;
    }
}