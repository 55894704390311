<template>
    <f7-page name="credit-card" class="CreditCardPage">
        <f7-navbar no-shadow class="CreditCardPage__NavBar">
            <f7-nav-left>
                <i @click="$f7router.back()" class="CreditCardPage__Icon--Left icon material-icons">arrow_backward</i>
            </f7-nav-left>
            <f7-nav-title class="width-100 text-align-center">
                <template v-if="view===views.viewCard">
                    Payment
                </template>
                <template v-else-if="view===views.linkCard">
                    <template v-if="creditCard.isValid()">
                        Edit Card
                    </template>
                    <template v-else>
                        Add Card
                    </template>
                </template>
            </f7-nav-title>
            <f7-nav-right>
                <f7-button v-if="view===views.viewCard && creditCard.isValid()"
                           @click="edit"
                           class="CreditCardPage__Icon--Right">
                    Edit
                </f7-button>
                <f7-button v-else
                           color="white"
                           class="CreditCardPage__Icon--Right">
                    Edit
                </f7-button>
            </f7-nav-right>
        </f7-navbar>
        <f7-block v-if="!loading" class="no-margin no-padding">
            <div v-if="view===views.viewCard">
                <f7-block v-if="creditCard.isValid()">
                    <f7-card class="text-color-white no-margin CreditCardPage__CreditCard"
                             :style="`background-image: url('static/images/CreditCard/${creditCard.brand==='Visa'|| creditCard.brand==='MasterCard'? creditCard.brand:'Generic'}.png');`"
                    >
                        <f7-card-content>
                            <f7-row>
                                <f7-col width="25" class="text-align-center CreditCardPage__CreditCard__Dot">
                                    &#x25CF;&#x25CF;&#x25CF;&#x25CF;
                                </f7-col>
                                <f7-col width="25" class="text-align-center CreditCardPage__CreditCard__Dot">
                                    &#x25CF;&#x25CF;&#x25CF;&#x25CF;
                                </f7-col>
                                <f7-col width="25" class="text-align-center CreditCardPage__CreditCard__Dot">
                                    &#x25CF;&#x25CF;&#x25CF;&#x25CF;
                                </f7-col>
                                <f7-col width="25" class="text-align-center CreditCardPage__CreditCard__Last4">
                                    {{creditCard.last4}}
                                </f7-col>
                            </f7-row>
                            <f7-row>
                                <f7-col width="50">
                                    <p>
                                        <small>Card Holder</small>
                                    </p>
                                    <p>
                                        {{creditCard.name}}
                                    </p>
                                </f7-col>
                                <f7-col width="50" class="text-align-right">
                                    <p>
                                        <small>Expire at</small>
                                    </p>
                                    <p>
                                        {{creditCard.exp_month}}/{{creditCard.exp_year}}
                                    </p>
                                </f7-col>
                            </f7-row>
                        </f7-card-content>
                    </f7-card>
                </f7-block>
                <f7-list v-if="creditCard.isValid()" class="margin-vertical-half">
                    <f7-list-item>
                        <div slot="media" :class="`card-${creditCard.brand.toLowerCase()}`">
                            {{creditCard.brand}}
                        </div>
                        <div slot="title">
                            <div class="CreditCardPage__Card__Name">
                                {{creditCard.name}}
                            </div>
                            <div class="CreditCardPage__Card__Last4">
                                Card ending in {{creditCard.last4}}
                            </div>
                        </div>
                        <div slot="after" class="CreditCardPage__Card__Status">
                            <f7-icon f7="checkmark_alt_circle_fill"/>
                        </div>
                    </f7-list-item>
                </f7-list>
                <f7-list no-hairlines v-else class="margin-vertical-half">
                    <f7-list-item @click="switchToLinkCardView">
                        Add a payment Method
                    </f7-list-item>
                </f7-list>
            </div>
            <div v-if="view===views.linkCard">
                <f7-list
                        no-hairlines
                        class="CreditCardForm no-margin-top">
                    <f7-list-input
                            outline
                            @change="linkCardForm.name = $event.target.value"
                            :value="linkCardForm.name"
                            placeholder="Your name"
                            label="Your name"
                            floating-label
                    />

                    <f7-list-input
                            outline
                            @change="linkCardForm.number = $event.target.value"
                            :value="linkCardForm.number"
                            type="tel"
                            placeholder="Your card number"
                            label="Your card number"
                            floating-label
                            input-id="card-number"
                    />

                    <f7-list-input
                            outline
                            type="tel"
                            placeholder="Expiry Date*"
                            label="Expiry Date"
                            floating-label
                            @change="linkCardForm.exp = $event.target.value"
                            :value="linkCardForm.exp"
                            input-id="card-exp"
                    />

                    <f7-list-input
                            outline
                            @change="linkCardForm.cvc = $event.target.value"
                            :value="linkCardForm.cvc"
                            type="tel"
                            placeholder="CVV"
                            label="CVV"
                            floating-label
                            maxlength="4"
                            size="4"
                    />
                    <f7-block>
                        <f7-button large fill @click="linkCreditCard">
                            Confirm Card
                        </f7-button>
                    </f7-block>
                    <f7-block>
                        <f7-button @click="removeCreditCard" v-if="creditCard.isValid()" large outline>
                            Remove Card
                        </f7-button>
                    </f7-block>
                </f7-list>
            </div>
        </f7-block>
        <f7-card v-else
                 class="skeleton-text skeleton-effect-blink"
                 content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi lobortis et massa ac interdum. Cras consequat felis at consequat hendrerit."
        ></f7-card>
    </f7-page>
</template>
<script>
    import BasePage from './base'
    import CreditCard from "../models/CreditCard";
    import LinkCardForm from "../models/Forms/LinkCardForm";
    import Member from "../models/Member";
    import Token from "../models/Token";
    import Device from "../models/Device";
    import Cleave from 'cleave.js';

    const viewCard = 0;
    const linkCard = 1;

    export default {
        extends: BasePage,
        data() {
            return {
                creditCard: new CreditCard(),
                linkCardForm: new LinkCardForm(),
                member: new Member(),
                token: new Token(),
                device: new Device(),
                view: 0,
                views: {
                    viewCard: 0,
                    linkCard: 1,
                }
            }
        },
        mounted() {
            this.loading = true;
            this.member.restore();
            this.token.restore();
            this.device.restore();

            CreditCard.get(this.member.CustomerID).then((response) => {
                this.loading = false;
                if (response.success) {
                    this.creditCard.fill(response.creditCard);
                    console.log(this.creditCard);
                } else {
                    this.$f7.dialog.alert(response.message, 'Error')
                }
            });

        },
        methods: {
            edit() {
                this.linkCardForm.fillFromCreditCard(this.creditCard);
                this.view = this.views.linkCard;
                setTimeout(() => {
                    new Cleave('#card-number', {
                        creditCard: true
                    });
                    new Cleave('#card-exp', {
                        date: true,
                        datePattern: ['m', 'Y']
                    });
                }, 500)
            },
            switchToLinkCardView() {
                this.view = this.views.linkCard;
                setTimeout(() => {
                    new Cleave('#card-number', {
                        creditCard: true
                    });
                    new Cleave('#card-exp', {
                        date: true,
                        datePattern: ['m', 'Y']
                    });
                }, 500)
            },
            linkCreditCard() {
                this.$f7.preloader.show();
                CreditCard.link(this.member.CustomerID, this.linkCardForm).then((response) => {
                    this.$f7.preloader.hide();
                    if (response.success) {
                        this.creditCard.fill(response.creditCard);
                        this.view = this.views.viewCard;
                    } else
                        this.$f7.dialog.alert(response.message, 'Error');
                })
            },
            removeCreditCard() {
                this.$f7.dialog.confirm('Are you sure you want to unlink current credit card?', 'Are you sure?', () => {
                    this.$f7.preloader.show();
                    CreditCard.delete(this.member.CustomerID, this.creditCard.id).then((response) => {
                        this.$f7.preloader.hide();
                        if (response.success) {
                            this.$f7.dialog.alert(response.message, 'Success');
                            this.creditCard.id = null;
                            this.view = this.views.viewCard;
                        } else {
                            this.$f7.dialog.alert(response.message, 'Error')
                        }
                    })
                })
            }
        }
    }
</script>
<style scoped>
</style>
