<template>
  <f7-page name="checkout" class="CheckoutPage"
           @page:afterin="onAfterIn"
           @page:beforeout="onBeforeOut"
  >
    <f7-navbar no-shadow class="CheckoutPage__NavBar">
      <f7-nav-left>
        <f7-button @click="closePage()" class="CheckoutPage__Icon--Left">
          <i class="bettys-burgers-web-2 bettys-burgers-web-2-Arrow-Back-Icon"/>
        </f7-button>
      </f7-nav-left>
      <f7-nav-title class="width-100 text-align-center">Cart</f7-nav-title>
      <f7-nav-right>
        <f7-button class="CheckoutPage__CartButton">
          <i class="bettys-burgers-icon bettys-burgers-cart-icon"/>
          <f7-badge class="qty" v-if="cart.getQty()>0">{{ cart.getQty() }}</f7-badge>
        </f7-button>
      </f7-nav-right>
    </f7-navbar>
    <!--        Start  -->
    <div class="CheckoutPage__Content" v-if="loaded">
      <f7-list no-hairlines class="no-margin-vertical" v-if="!cart.isEmpty()">
        <f7-list-item class="CheckoutPage__Item" v-for="(item, index) in cart.items">
          <template v-if="item.isQtyEditable">
            <div slot="title" v-if="typeof menu.map[item.product] !=='undefined'">
              <div class="CheckoutPage__Item__Title">
                {{ menu.map[item.product].name }}
              </div>
              <div class="CheckoutPage__Item__Ingredients">
                {{ menu.map[item.product].description }}
              </div>
              <div class="CheckoutPage__Item__Ingredients">
                {{ item.ingredientsToString(menu.map) }}
              </div>
            </div>
            <div slot="title" v-else>
              <div class="CheckoutPage__Item__Title">
                Invalid Item
              </div>
            </div>
            <div slot="after" v-if="typeof menu.map[item.product] !=='undefined'">
              <div class="CheckoutPage__Item__Price">
                {{ item.getTotal(menu.map) | total }}
              </div>
              <div class="CheckoutPage__Item__Qty">
                <f7-button class="CheckoutPage__Item__Stepper"
                           @click="item.qty > 1? item.qty--:removeItemPrompt(item,index)">
                  -
                </f7-button>
                <f7-button class="CheckoutPage__Item__QtyValue">{{ item.qty }}</f7-button>
                <f7-button class="CheckoutPage__Item__Stepper" @click="item.qty++">+</f7-button>
              </div>
            </div>
            <div slot="after" v-else>
              <f7-button @click="removeItem(item,index)" color="red" icon-f7="trash"/>
            </div>
          </template>
          <template v-else>
            <div slot="title">
              <div class="CheckoutPage__Item__Title">
                {{ menu.map[item.product].name }}
              </div>
              <div class="CheckoutPage__Item__Ingredients">
                {{ menu.map[item.product].description }}
              </div>
            </div>
            <div slot="after">
              <f7-button @click="removeItem(item,index)" color="red" icon-f7="trash"/>
            </div>
          </template>
        </f7-list-item>
      </f7-list>
      <div class="alert" v-else>
        Your cart is empty
      </div>
      <f7-list accordion-list class="no-margin-vertical" v-if="pickupStore.isValid()">
        <f7-list-item accordion-item>
          <div slot="title">
            <div class="CheckoutPage__Accordion__Title">
              {{ pickupStore.name }}
            </div>
            <div class="CheckoutPage__Accordion__Description">
              {{ pickupStore.address1 }}
            </div>
            <div class="CheckoutPage__Accordion__Description">
              {{ pickupStore.openingHours }}
            </div>
          </div>
          <f7-accordion-content>
            <f7-block>
              <f7-button class="margin-bottom-half" large fill @click="openStoresPage('checkout')">Change
                Store
              </f7-button>
            </f7-block>
          </f7-accordion-content>
        </f7-list-item>
      </f7-list>
      <f7-list v-else>
        <f7-block>
          <f7-button large fill @click="openStoresPage('checkout')">Select Pickup Store</f7-button>
        </f7-block>
      </f7-list>
      <f7-list accordion-list class="no-margin-vertical">
        <f7-list-item accordion-item>
          <div slot="title">
            <div class="CheckoutPage__Accordion__Title">
              Pickup in Store
            </div>
            <div class="CheckoutPage__Accordion__Description">
              {{ pickupStore.asapEstimatedTimeDescription }}
            </div>
          </div>
          <f7-accordion-content>
            <f7-list inline-labels>
              <f7-list-item @click="pickupTime.isASAP = !pickupTime.isASAP">
                <span>ASAP</span>
                <f7-toggle disabled :checked="pickupTime.isASAP"/>
              </f7-list-item>
              <f7-list-input
                  outline
                  label="Time" type="time"
                  :value="pickupTime.value"
                  @change="pickupTime.value = $event.target.value"
              />
            </f7-list>
          </f7-accordion-content>
        </f7-list-item>
      </f7-list>
    </div>
    <f7-block v-else>
      <f7-progressbar infinite></f7-progressbar>
    </f7-block>
    <div slot="fixed" class="CheckoutPage__DockBottom">
      <div class="left">
        {{ cart.getTotal(menu.map) | total }}
      </div>
      <div class="right">
        <f7-button
            @click="goToPaymentPage"
            large
            fill
            :disabled="cart.isEmpty() || !pickupStore.isValid()"
        >Checkout
        </f7-button>
      </div>
    </div>
  </f7-page>
</template>
<script>
import BasePage from './base'
import Cart from '../models/Cart'
import Store from "../models/Store";
import Menu from "../models/Menu";
import PickupTime from "../models/PickupTime";
import moment from "moment";
import Member from "../models/Member";
import DataLayerHelper from "../models/DataLayerHelper";

export default {
  extends: BasePage,
  data() {
    return {
      member: new Member(),
      cart: new Cart(),
      menu: new Menu(),
      pickupStore: new Store(),
      pickupTime: new PickupTime(),
      loaded: false
    }
  },
  methods: {
    onAfterIn() {
      this.cart.restore();
      this.menu.restore();
      this.pickupStore.restore();
      this.pickupTime.store();
      this.loaded = true;
      DataLayerHelper.logCartView();
    },
    onBeforeOut() {
      this.loaded = false;
    },
    removeItemPrompt(item, index) {
      this.$f7.dialog.confirm(`Remove <b>${this.menu.map[item.product].name}</b> from your cart?`, 'Are you sure?', () => {
        DataLayerHelper.logProductRemoveFromCart(this.menu.map[item.product].name)
        this.cart.removeFromCart(index);
      })
    },

    removeItem(item, index) {
      DataLayerHelper.logProductRemoveFromCart(this.menu.map[item.product].name)
      this.cart.removeFromCart(index);
    },

    goToPaymentPage() {
      this.member.restore();

      if (this.member.isValid()) {
        this.$f7router.navigate('/payment/')
      } else {
        this.$f7router.navigate('/guest-payment/')
      }
    },
    closePage() {
      if (this.$f7router.view.name === 'main') {
        this.$f7router.back();
      } else {
        this.$f7router.emit('view:close')
      }
    },
  },
  watch: {
    cart: {
      deep: true,
      handler: function () {
        this.cart.store();
      }
    },
    pickupTime: {
      deep: true,
      handler: function () {
        this.pickupTime.store();
      }
    }
  }
}
</script>
<style scoped>
</style>
