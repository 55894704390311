import Config from "../../Config";
import BaseForm from "./BaseForm";
import FormProfile from "../../FormProfile";

export default class EditProfileForm extends BaseForm {

    Email;
    GivenNames;
    Surname;
    FullName;
    Gender;
    Password;
    ConfirmPassword;
    DateOfBirth;
    Platform;
    Uuid;

    constructor(device) {
        super();
        this.Email = FormProfile.register.Email;
        this.GivenNames = FormProfile.register.GivenNames;
        this.Surname = FormProfile.register.Surname;
        this.Sex = FormProfile.register.Sex;
        this.FullName = FormProfile.register.FullName;
        this.Password = FormProfile.register.Password;
        this.ConfirmPassword = FormProfile.register.ConfirmPassword;
        this.DateOfBirth = FormProfile.register.DateOfBirth;
        this.Platform = device.platform;
        this.Uuid = device.uuid;
    }

    submit() {
        this.GivenNames = this.FullName.trim().split(' ').slice(0, -1).join(' ');
        this.Surname = this.FullName.trim().split(' ').slice(-1).join(' ');
        this.ConfirmPassword = this.Password;
        return this.post('/api/register', this)
    }
}