<template>
    <f7-page class="ChangePasswordPage">
        <f7-navbar no-shadow class="ChangePasswordPage__NavBar">
            <f7-nav-left>
                <i @click="$f7router.back()" class="ChangePasswordPage__Icon--Left icon material-icons">arrow_backward</i>
            </f7-nav-left>
            <f7-nav-title class="width-100 text-align-center">Change Password</f7-nav-title>
            <f7-nav-right>
                <f7-button @click="changePassword" class="ChangePasswordPage__Icon--Right">
                    Save
                </f7-button>
            </f7-nav-right>
        </f7-navbar>
        <div>
            <f7-list no-hairlines class="no-margin-top">
                <f7-list-input
                        floating-label
                        label="New Password"
                        type="password"
                        :value="newPassword"
                        @change="newPassword=$event.target.value"
                />
                <f7-list-input
                        floating-label
                        label="Confirm Password"
                        type="password"
                        :value="confirmPassword"
                        @change="confirmPassword=$event.target.value"
                />
            </f7-list>
        </div>
    </f7-page>
</template>
<script>
    import BasePage from './base';
    import ChangePasswordForm from "../models/Forms/ChangePasswordForm";
    import Member from "../models/Member";
    import Token from "../models/Token";
    import Device from "../models/Device";

    export default {
        extends: BasePage,
        data: function () {
            return {
                member: new Member(),
                token: new Token(),
                device: new Device(),
                oldPassword: '',
                newPassword: '',
                confirmPassword: ''
            };
        },
        mounted: function () {
            this.member.restore();
            this.token.restore();
            this.device.restore();
        },
        methods: {
            changePassword() {

                this.$f7.preloader.show();
                this.token.restore();
                Member.updatePassword(
                    this.member,
                    this.token.token,
                    this.device,
                    this.newPassword,
                    this.confirmPassword).then((response) => {
                    this.$f7.preloader.hide();
                    this.$f7.dialog.alert(response.message);
                    if (response.success) {
                        this.member.fill(response.member);
                        this.token.fill(response.token);
                        this.member.store();
                        this.token.store();
                    }
                })
            }
        }
    };
</script>
