<template>
    <div class="StoresSearchComponent">
        <f7-input type="text"
                  class="StoresSearchComponent__Input"
                  placeholder="Enter Address"
                  :value="search"
                  @input="search=$event.target.value"
                  outline
        />
        <f7-button @click="useCurrentLocation()" class="StoresSearchComponent__Icon" icon-material="near_me"/>
        <div class="StoresSearchComponent__Predictions">
            <f7-list class="no-margin-vertical elevation-1" v-if="predictions.length>0">
                <f7-list-item link no-chevron v-for="prediction in predictions" @click="selectPlace(prediction)">
                    {{prediction.description}}
                </f7-list-item>
            </f7-list>
        </div>
    </div>
</template>

<script>
    import Store from "../models/Store";
    import Position from "../models/Position";

    export default {
        name: "StoresSearchComponent",
        data: function () {
            return {
                search: '',
                predictions: [],
                autoCompleteService: null,
                placeService: null,
                currentPosition: new Position()
            }
        },
        mounted() {
            this.autoCompleteService = new google.maps.places.AutocompleteService();
            this.placeService = new google.maps.places.PlacesService(document.createElement('div'));
        },
        methods: {
            useCurrentLocation() {

                this.$f7.preloader.show();
                navigator.geolocation.getCurrentPosition((position) => {
                    this.currentPosition.fill(position.coords);
                    this.$f7.preloader.hide();
                }, (error) => {
                    this.$f7.preloader.hide();
                    this.$f7.dialog.alert('Unable to get your location. Please use the search box instead');
                }, {
                    enableHighAccuracy: true
                });
            },
            selectPlace(place) {
                this.predictions = [];
                this.placeService.getDetails({
                    placeId: place.place_id
                }, (response) => {
                    this.currentPosition.fill({
                        latitude: response.geometry.location.lat(),
                        longitude: response.geometry.location.lng()
                    })
                });
            }
        },
        watch: {
            search: function () {
                if (this.search.length < 3)
                    this.predictions = [];
                else {
                    this.autoCompleteService.getPlacePredictions({
                        input: this.search,
                        componentRestrictions: {country: 'au'}
                    }, (predictions, status) => {
                        if (status != google.maps.places.PlacesServiceStatus.OK) {
                            this.predictions = [];
                        } else
                            this.predictions = predictions;
                    });
                }
            },
            currentPosition: {
                deep: true,
                handler: function () {
                    if (this.currentPosition.isValid()) {
                        Store.getNearestStores(this.currentPosition.latitude, this.currentPosition.longitude).then((response) => {
                            if (response.success) {
                                this.$emit('results',
                                    {
                                        stores: response.stores
                                    }
                                )
                            }
                        });
                    }
                }
            }
        }
    }
</script>

<style scoped>

</style>