<template>
    <f7-page class="FindABettysPage">
        <BettysToolbar :index="2" slot="fixed"></BettysToolbar>
        <div class="FindABettysPage__Banner">
            <f7-link :animate="false" back icon-material="close"/>
        </div>
        <div class="FindABettysPage__Content">
            <div class="FindABettysPage__Header">
                FIND A BETTY’S
            </div>
            <div>
                <label for="search-input" class="FindABettysPage__Label">
                    Search by Suburb/Postcode
                </label>
                <input class="FindABettysPage__SearchInput" type="text" id="search-input"
                       placeholder="Enter Suburb/Postcode">
            </div>
            <div class="FindABettysPage__Or">
                OR
            </div>
            <div>
                <f7-button large fill class="FindABettysPage__Button" @click="useCurrentLocation">
                    <f7-icon f7="placemark_fill"/>
                    Use current location
                </f7-button>
            </div>
            <f7-block class="display-flex align-items-center justify-content-space-around">
                <div class="FindABettysPage__Tips">
                    TIPS
                </div>
                <div class="FindABettysPage__TipsText">
                    Turn on location can help you check the nearest store anywhere.
                </div>
            </f7-block>
        </div>
    </f7-page>
</template>
<script>
    import BasePage from "./BasePage";
    import BettysToolbar from "./BettysToolbar";

    export default {
        extends: BasePage,
        components: {
            BettysToolbar
        },
        data: function () {
            return {
                autoCompleteService: null,
                placeService: null
            }
        },
        mounted: function () {
            this.$f7.autocomplete.create({
                inputEl: '#search-input',
                openIn: 'dropdown',
                valueProperty: 'description',
                textProperty: 'description',
                source: (query, render) => {
                    if (query.length < 3)
                        render([]);
                    else {
                        this.autoCompleteService.getPlacePredictions({
                            input: query,
                            componentRestrictions: {country: 'au'}
                        }, (predictions, status) => {
                            if (status != google.maps.places.PlacesServiceStatus.OK) {
                                render([]);
                            }
                            if (predictions !== null)
                                render(predictions);
                        });
                    }
                },
                on: {
                    change: (places) => {
                        let place = places.pop();
                        this.placeService.getDetails({
                            placeId: place.place_id
                        }, (response) => {
                            let lat = response.geometry.location.lat();
                            let lng = response.geometry.location.lng();
                            this.$f7router.navigate(`/map/?lat=${lat}&lng=${lng}&back=`, {
                                reloadCurrent: true
                            });
                        });
                    }
                }
            });

            this.autoCompleteService = new google.maps.places.AutocompleteService();
            this.placeService = new google.maps.places.PlacesService(document.createElement('div'));
        }, methods: {
            useCurrentLocation() {
                this.$f7.preloader.show();
                navigator.geolocation.getCurrentPosition((position) => {

                    let lat = position.coords.latitude;
                    let lng = position.coords.longitude;
                    this.$f7.preloader.hide();
                    this.$f7router.navigate(`/map/?lat=${lat}&lng=${lng}&back=`, {
                        animate: false,
                        reloadCurrent: true
                    });
                }, (error) => {
                    this.$f7.preloader.hide();
                    this.$f7.dialog.alert('Unable to get your location. Please use the search box instead');
                }, {
                    timeout: 5000
                });
            }
        }
    }
</script>