export default class {
    product;
    ingredients;
    qty;
    isCustomisable;
    isQtyEditable;

    constructor(data) {
        this.product = null;
        this.ingredients = [];
        this.qty = 1;
        this.isCustomisable = true;
        this.isQtyEditable = true;

        if (data !== null && data !== undefined) {
            this.fill(data);
        }
    }

    fill(data) {
        Object.keys(data).forEach((key) => {
            this[key] = data[key];
        });
    }

    static fromProduct(product) {
        let rs = new this();

        rs.product = product.ProductID;
        rs.ingredients = [];
        rs.qty = 1;
        rs.isCustomisable = product.isCustomisable;
        rs.isQtyEditable = product.isQtyEditable;

        return rs;
    }

    hasIngredient(ingredientID) {
        return this.ingredients.indexOf(ingredientID) !== -1;
    }

    /**
     *
     * @param ingredientID
     * @returns {boolean}
     */
    addIngredient(ingredientID) {
        if (!this.hasIngredient(ingredientID))
            this.ingredients.push(ingredientID);
    }

    /**
     *
     * @param ingredientID
     */
    removeIngredient(ingredientID) {
        let index = this.ingredients.indexOf(ingredientID);

        if (index !== -1) {
            this.ingredients.splice(index, 1);
        }
    }

    toggleIngredient(ingredientID) {
        if (ingredientID == null)
            return;
        if (this.hasIngredient(ingredientID))
            this.removeIngredient(ingredientID);
        else
            this.addIngredient(ingredientID)
    }

    getAwardPoints(map) {
        let total = map.products[this.product].awardPoints;

        this.ingredients.forEach((ingredientID) => {
            total += map.ingredients[ingredientID].awardPoints;
        });

        this.extras.forEach((ingredientID) => {
            total += map.ingredients[ingredientID].awardPoints;
        });

        if (this.meal !== null && this.drink !== null) {
            total += map.meals[this.meal].awardPoints;
            total += map.drinks[this.drink].awardPoints;
        }

        total *= this.qty;

        return total;
    }

    getTotal(map) {

        try {

            let total = map[this.product].price;

            this.ingredients.forEach((ingredientID) => {
                total += map[ingredientID].price;
            });

            total *= this.qty;

            return total;
        } catch (e) {
            return 0;
        }
    }

    reset() {
        this.ingredients = [];
    }

    ingredientsToString(map) {

        return this.ingredients.map((ingredient) => {
            return map[ingredient].name;
        }).join(', ');
    }
}