import BaseModel from "../BaseModel";

export default class ContactForm extends BaseModel {

    fullName;
    email;
    store;
    category;
    message;
    // fields;
    //
    // constructor() {
    //     super();
    //     this.id = null;
    //     this.fields = [];
    // }
    //
    // isValid() {
    //     return this.id !== null;
    // }
    //
    // static get() {
    //     return super.get('api/public/contact-form');
    // }

    static submit(form) {

        return super.post('api/public/contact-form',
            form
        )
    }
}