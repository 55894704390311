import BaseForm from "./BaseForm";

export default class ChangePasswordForm extends BaseForm {
    password;
    confirmPassword;

    constructor() {
        super();
        this.password = '';
        this.confirmPassword = '';
    }

    prepare(data) {
        Object.keys(data).forEach((key) => {
            this[key] = data[key]
        })
    }

    submit() {

        return this.put(`/api/public/members/${this.device.uuid}/password`, {
            password: this.password,
            confirmPassword: this.confirmPassword,
            email: this.email,
            token: this.token,
            platform: this.device.platform
        });
    }
}