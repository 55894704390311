<script>

    import Route from '../Route';
    import Config from '../Config';
    import moment from 'moment';
    import Env from '../Env';
    import BaseModel from "../models/BaseModel";

    export default {
        data: function () {
            return {
                // always false in the beginning
                isLoaded: false,
                error: null,
                member: null,
                token: null,
                device: null,
                route: Route,
                position: null,
                //for dev
                dev: {
                    enabled: Env.env === 'dev',
                    params: {
                        position: null,
                        // position: {
                        //     latitude: -38.3441120,
                        //     longitude: 145.3608150
                        // }
                    }
                },
                devParams: {
                    location: {
                        enabled: true,
                        position: {
                            coords: {
                                latitude: -37.8863,
                                longitude: 145.0830
                            }
                        }
                    }
                }
            };
        },
        methods: {

            getImageUrl(imageUrl) {
                return BaseModel.getImageUrl(imageUrl);
            },

            isUserLoggedIn() {
                return this.member && this.token;
            },
            isDev() {
                return this.env === 'dev';
            },
            isLocationEnabled() {
                return this.devParams.location.enabled;
            },
            isRunningOnIOS() {
                return this.device.platform === 'ios';
            },
            isRunningOnAndroid() {
                return this.device.platform === 'android';
            },
            // check if current path is a specific path
            isPage(path) {
                return this.$f7router.currentRoute.path === path;
            },
            getRequestParam(key) {
                return this.$f7route.params[key];
            },
            getRequestQuery(key) {
                return this.$f7route.query[key];
            },
            redirectExternalLink(url) {
                window.open(url, '_system');
            },
            redirectInternalLink(url, options) {
                if (options === undefined) {
                    options = {};
                }
                options.animated = true;
                this.$f7router.navigate(url, options);
            },
            redirectBack() {
                this.$f7router.back();
            },
            /**
             * For redirecting back to a specific url
             * @param url
             */
            redirectBackForce(url) {
                this.$f7router.back(url, {
                    force: true
                });
            },
            getLocation() {
                navigator.geolocation.getCurrentPosition((position) => {
                    this.location = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    }
                }, (error) => {
                    this.location = {
                        lat: undefined,
                        lng: undefined,
                    }
                });

            },
            alert(title, content) {
                this.$f7.dialog.alert(content, title);
            },
            prompt(title, content, callbackOk, callbackCancel) {
                this.$f7.dialog.prompt(content, title, callbackOk, callbackCancel);
            },
            confirm(content, callbackOk, callbackCancel) {
                this.$f7.dialog.confirm(content, callbackOk, callbackCancel);
            },
            showLoadingOverlay() {
                this.$f7.preloader.show();
            },
            hideLoadingOverlay() {
                this.$f7.preloader.hide();
            },
            getNetworkConnection() {

                if (this.isDev()) {
                    return Config.networkState();
                } else {
                    try {

                        return navigator.connection.type;
                    } catch (e) {
                        return false;
                    }
                }
            }
        },
        filters: {
            currency: function (value) {
                return value ? `${(value / 100).toFixed(2)}` : ''
            },
            leadingZero: function (value) {
                return value < 10 ? `0${value}` : `${value}`
            },
            storeTradingHours: function (store) {
                let now = moment.unix();
                if (!store.IsOpen || store.FirstOrderTime > now || store.LastOrderTime < now)
                    return 'Closed';
                else
                    return 'Trading Hours: ' + store.openingHours;
            },
            ddmmyyyy: function (value) {
                return moment(value).format('DD/MM/YYYY')
            },
            round: function (value, round) {
                return value ? value.toFixed(round) : 0
            },
            store_status: function (store) {
                if (store === null || store === undefined)
                    return 'Closed';
                if (store.OpenNow) {
                    let openingTime = moment(store.OpeningTimeToday).format('HH:mm');
                    let closingTime = moment(store.ClosingTimeToday).format('HH:mm');
                    return `Open Now: ${openingTime}-${closingTime}`
                } else
                    return 'Closed'
            }
        }
    };
</script>

<style scoped>

</style>
