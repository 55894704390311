import BaseModel from "./BaseModel";

export default class GuestCheckout extends BaseModel {

    static dispatch(
        cart,
        orderTotal,
        storeID,
        storeName,
        pickupTime,
        pickupTimeASAP,
        hash,
        paymentToken,
        paymentMethod,
        idempotencyKey,
        platform,
        email,
        phone,
        givenNames,
    ) {
        //making cart here
        return super.post(`api/public/guest-checkout/checkout`, {
            cart: cart,
            orderTotal: orderTotal,
            storeID: storeID,
            storeName: storeName,
            pickupTime: pickupTime,
            pickupTimeASAP: pickupTimeASAP,
            hash: hash,
            paymentToken: paymentToken,
            paymentMethod: paymentMethod,
            idempotencyKey: idempotencyKey,
            platform: platform,
            email: email,
            phone: phone,
            givenNames: givenNames
        })
    }

    static validate(cart, orderTotal, pickupTime, pickupTimeASAP, storeID, storeName, hasPaymentIntent) {
        //validate cart
        return super.post('api/public/guest-checkout/validate', {
            cart: cart,
            orderTotal: orderTotal,
            pickupTime: pickupTime,
            pickupTimeASAP: pickupTimeASAP,
            storeID: storeID,
            storeName: storeName,
            hasPaymentIntent: hasPaymentIntent
        })
    }
}