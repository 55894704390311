<template>
    <f7-page class="PrivacyPolicyPage">
        <f7-navbar class="PrivacyPolicyPage__NavBar" no-shadow>
            <f7-nav-left>
                <f7-link @click="$f7router.back()">Back</f7-link>
            </f7-nav-left>
            <f7-nav-title>Privacy Policy</f7-nav-title>
            <f7-nav-right>
                <f7-link>Back</f7-link>
            </f7-nav-right>
        </f7-navbar>
        <div class="PrivacyPolicyPage__Content">
            <h2>Betty&rsquo;s Burgers &amp; Concrete Co. Privacy Policy</h2>
            <p><em>Last Updated: 20 July 2018</em></p>
            <p><strong>1. Why do we have a privacy policy</strong></p>
            <p><strong>Summary:</strong>&nbsp;When you interact with Betty&rsquo;s Burgers you trust us with your
                information and expect us to protect and use it appropriately. We take privacy seriously and are
                committed to keeping that trust. That starts with helping you understand our privacy practices. This
                policy describes the information we collect, how it is used and shared, and your choices regarding this
                information.</p>
            <p>Betty&rsquo;s Burgers Australia Pty Ltd ACN 132 912 817 and its related entities (collectively referred
                to as &ldquo;we&rdquo;, &ldquo;us&rdquo;, &ldquo;our&rdquo; or &ldquo;Betty&rsquo;s Burgers&rdquo;) are
                committed to protecting the privacy of Personal Information in accordance with the&nbsp;<em>Australian
                    Privacy Principles</em>&nbsp;contained in the&nbsp;<em>Privacy Act 1988&nbsp;</em>(Cth)
                (<strong>Act</strong>) or, where applicable, other data protection laws (such as those in force in the
                European Union (<strong>EU</strong>). This Privacy Policy sets out the way we handle Personal
                Information and includes information on how we collect, use, disclose, keep secure and provide you with
                access to your Personal Information, whether provided to us via our stores, head office, the Betty&rsquo;s
                Burgers website or other means.</p>
            <p><strong>2. What Personal Information do we collect?</strong></p>
            <p><strong>Summary:</strong>&nbsp;The term &ldquo;Personal Information&rdquo; means any information that can
                be used to personally identify you.</p>
            <p>Examples of Personal Information we may collect from you includes your name, address, date of birth
                (age), gender, home address, email address, telephone number, occupation, menu preferences, previous
                order information, IP address, credit card details, individual interests, preferences, tastes,
                purchasing behaviour, education details, employment history and other information relating to your work
                experience. We may also collect an opinion (including information or an opinion forming part of a
                database, whether true or not, and whether recorded in a material form or not, about an individual whose
                identity is apparent, or can reasonably be ascertained from the information or opinion). Those are just
                a few examples so please review this Privacy Policy closely and let us know if you have any
                queries.<br/>We do not collect Sensitive Personal Information, which includes information about racial
                or ethnic groups or religious beliefs, without your express consent or as otherwise required by law. The
                exception to this is when you apply for a position, in which case refer to the sections below titled
                &ldquo;Employment Applications&rdquo;. We will not use Sensitive Personal Information for marketing
                purposes.</p>
            <p><strong>3. How do we collect Personal Information?</strong></p>
            <p><strong>Summary:</strong>&nbsp;We collect your Personal Information in a number of ways. We may collect
                your Personal Information directly from you or in the course of our dealings with you, for example:</p>
            <ul>
                <li>Information that you provide to us, such as your name</li>
                <li>Information when you use our services, such as your purchase history</li>
            </ul>
            <p>We will only collect Personal Information from you that we reasonably require for one or more of our
                business functions or activities and will do so in accordance with the&nbsp;<em>Australian Privacy
                    Principles.</em></p>
            <p>The kinds of Personal Information we may collect from you will depend on what type of interaction you
                have with us. We may collect Personal Information from you in the following ways:</p>
            <ul>
                <li>When you participate in a promotion, competition, promotional activity, survey, market research or
                    customer behavioural activity
                </li>
                <li>When you subscribe to our mailing list or interact or follow our social media pages including
                    Facebook, Twitter and Instagram
                </li>
                <li>We collect information about how you interact with our services. This includes information such as
                    access dates and times, pages viewed and other system activity
                </li>
                <li>When you provide us with Personal Information via our stores or head office or when you place an
                    order in store, by telephone or via our mobile or electronic ordering system
                </li>
                <li>If you use social media, any information that you allow the social media site to share with us</li>
                <li>If you provide feedback and opinions about our products and services through any channel</li>
                <li>If you contact our Support Centre we may record calls in and out of our Support Centre and so we
                    will collect any Personal Information you provide during the call
                </li>
                <li>If you are applying for a position with Betty&rsquo;s Burgers, we will collect information as
                    outlined in the section below titled &ldquo;Employment Applications&rdquo;
                </li>
                <li>By accessing a website or mobile application via links in an email we have sent and/or by accessing
                    a website or mobile application where you have identified yourself, you consent to the collection of
                    such information where it is Personal Information. We may combine your anonymous or personal visitor
                    session information or other information collected through tracking technologies with other Personal
                    Information collected from you from time to time in order to understand and measure your online
                    experiences and to determine what products, promotions and services are likely to be of interest to
                    you.
                </li>
                <li>Depending on the Betty&rsquo;s Burgers services that you use, and your app settings or device
                    permissions, we may collect your precise or approximate location information as determined through
                    data such as GPS, IP address and WiFi.
                </li>
                <li>We may collect transaction details related to your use of our services, including your order
                    details, date and time the service was provided, amount charged, and payment method
                </li>
                <li>We may also collect Personal Information through cookies and other identification technologies,
                    please refer to &ldquo;Cookies and Web Beacons&rdquo; section below.
                </li>
                <li>Public databases.</li>
                <li>We may use CCTV in or around Betty&rsquo;s Burgers businesses to investigate any type of incident
                    and protect our personnel. We will restrict access to any footage.
                </li>
            </ul>
            <p>Betty&rsquo;s Burgers may combine the information collected from these sources with other information in
                its possession.</p>
            <p>In some circumstances, we may collect Personal Information from third parties such as credit reporting
                agencies or marketing agencies. Where we do, we will ensure that we act in accordance with relevant
                Australian laws.</p>
            <p><strong>4. Cookies and web beacons</strong></p>
            <p><strong>Summary:</strong>&nbsp;Betty&rsquo;s Burgers and its partners may use cookies and other
                identification technologies on our apps, websites, emails, and online ads for purposes described in this
                policy. A &ldquo;cookie&rdquo; is a small text file that is stored on your browser or device that can
                identify you as a unique customer and store your personal preferences.</p>
            <p>As you navigate through our websites or other mediums, certain information can be passively collected (by
                us or our vendors) using various technologies such as cookies, internet tags or web beacons, and
                navigational data collection (log files, server logs and clickstream data). Using &ldquo;cookies&rdquo;
                enables us to collect data regarding your personal preferences, including what products you have
                ordered, at what times and in what amounts, the pages you have visited in what order and so forth. This
                enables us to recognise you as a specific customer and to help us and our third-party service providers
                present targeted and customised advertising to you. For example, we can keep track of the burgers you
                like such that, if you consent, we can send you information about new products and promotions that you
                might be interested in.<br/>If you do not wish to receive cookies, you can change your browser settings
                such that your device does not accept cookies or please contact us. However, please note that certain
                areas of the Betty&rsquo;s Burgers website may only be accessed in conjunction with cookies.</p>
            <p><strong>5. Third-party links</strong></p>
            <p>The websites may include links to third-party websites, plug-ins and applications. Clicking on those
                links or enabling those connections may allow third parties to collect or share data about you. We do
                not control, endorse or make representations about these third-party sites and are not responsible for
                their privacy statements. When you leave our websites, we encourage you to read the privacy notice of
                every website you visit. If you choose to give Personal Information to unrelated third parties it will
                not be covered by this Privacy Policy.<br/>On our online services, we may also have providers of other
                services like apps, tools, widgets and plug-ins (for example Instagram &ldquo;Like&rdquo; features),
                which may also use automated methods to collect information about how you use these features. These
                organisations may use your information in line with their own policies.</p>
            <p><strong>6. Employment applications</strong></p>
            <p>We collect Personal Information when you interact with us with regards to employment. Examples of when we
                collect Personal Information includes when you apply for a position with us, complete an employment form
                for us, contact our Support Centre in respect to an employment opportunity, visit our website, social
                media channels or mobile applications. In considering your application, we may also obtain Personal
                Information about you from third parties. For example, from your previous employers, recruitment
                agencies, nominated referees, government agencies and regulatory authorities. Subject to your consent,
                we may also collect Sensitive Personal Information about you such as information about your health
                (including any disability), any criminal record, if it is relevant to the role that you are applying
                for, and whether you identify as Aboriginal or Torres Strait Islander. We collect Personal Information
                for a variety of purposes, including but not limited to any one or more of the following purposes:
                verifying your identity, communicating with you, assessing you for a position with us or one of our
                related entities and storing your information for future employment opportunities. If you do not provide
                us with the information we request, we will be unable to do one or more of the above. We may disclose
                your information to; referees or previous employers, recruitment agencies or agencies or contractors
                acting on our behalf, our related entities, law enforcement agencies to verify whether you have a
                criminal record or educational organisations to the extent necessary to verify your qualifications. If
                we engage third party contractors to perform services for us which involves handling Personal
                Information, we will take reasonable steps to prevent the contractor from using the Personal Information
                except for the purpose for which it was supplied. We will hold your Personal Information for future job
                opportunities with us, unless you tell us not to.</p>
            <p><strong>7. How we store your Personal Information</strong></p>
            <p>Where we store your Personal Information depends on what interaction you have had with us. However, some
                areas may include databases for processing customer enquiries, feedback or for general data processing
                or databases for email or SMS marketing communications.</p>
            <p><strong>8. Information retention and deletion</strong></p>
            <p><strong>Summary:</strong>&nbsp;If you withdraw consent to the collection or use of Personal Information,
                Betty&rsquo;s Burgers deletes such Personal Information. Users may request deletion of their accounts at
                any time. Following such request, Betty&rsquo;s Burgers deletes the information.</p>
            <p>We only keep your Personal Information for as long as it is required for the purpose for which it was
                collected or as otherwise required by law. We will take appropriate measures to destroy or permanently
                de-identify your Personal Information if we no longer need to retain it. These measures may vary
                depending on the type of information concerned, the way it was collected and how it was stored.<br/>There
                may be circumstances relating to employment applications or inappropriate or legal conduct which prevent
                Betty&rsquo;s Burgers from permanently deleting Personal Information. For example, in the case of fraud
                or where litigation has been threatened.</p>
            <p><strong>9. How your Personal Information will be used</strong></p>
            <p><strong>Summary:</strong>&nbsp;Betty&rsquo;s Burgers does not sell your Personal Information to third
                parties.</p>
            <p>We collect your Personal Information so that we can use it for our functions and activities and provide
                products and services to you, which include, among other things:</p>
            <p><u>Providing services and features</u></p>
            <p>Betty&rsquo;s Burgers uses the information we collect to provide, personalise, maintain and improve our
                products and services. This includes using the information to:</p>
            <ul>
                <li>Create and update your account</li>
                <li>Verify your identity</li>
                <li>To assist us in providing goods and services to you</li>
                <li>processing orders you place with us in our stores or via our electronic or mobile ordering system,
                    providing you with email or SMS confirmation of your orders, providing you with our products and
                    processing refunds where applicable;
                </li>
                <li>Perform internal operations necessary to provide our goods and services, including to troubleshoot
                    software bugs and operational problems, to conduct data analysis, testing, and research, and to
                    monitor and analyse usage and activity trends
                </li>
                <li>features to personalise your experience on our Betty&rsquo;s Burgers platforms</li>
                <li>to identify services and products that may be of interest to you</li>
                <li>assisting you with remembering and re-ordering from our menu in future</li>
                <li>administering and conducting traineeships</li>
                <li>facilitating our internal business operations, including fulfilment of any legal and regulatory
                    requirements
                </li>
                <li>to facilitate our feedback program</li>
                <li>processing and considering your employment application and conducting reference checks (refer to
                    Employment Application section above)
                </li>
                <li>Facebook Custom Audiences: If you use Facebook, we may use your email address in an encrypted format
                    to match with your Facebook profile so that we can provide you with personalised advertising on
                    Facebook. This is subject to the privacy settings you have chosen on Facebook.
                </li>
                <li>Facebook Lookalike Audiences: If you use Facebook, we may use your email address in an encrypted
                    format to enable Facebook to find other registered users of their services that share similar
                    interests to you based on how you interact with Salsas and what information Facebook has about you.
                </li>
                <li>to identify services and products that may be of interest</li>
            </ul>
            <p><em>Customer Support</em></p>
            <ul>
                <li>Direct your questions to the appropriate customer support person</li>
                <li>Investigate and address your concerns</li>
                <li>Monitor and improve our customer support responses</li>
                <li>administering and responding to your enquiry or feedback about our products</li>
                <li>to process and respond to any feedback provided by you</li>
            </ul>
            <p><em>Legal proceedings and requirements</em><br/>Betty&rsquo;s Burgersmay share your information if we
                believe it is required by applicable law, regulation, operating agreement, legal process or governmental
                request, or where the disclosure is otherwise appropriate due to safety or similar concerns:</p>
            <p><em>Communications from Betty&rsquo;s Burgers</em><br/>Betty&rsquo;s Burgers may use the information we
                collect to communicate with you about:</p>
            <ul>
                <li>promoting and marketing current and future products, services, promotions, studies, surveys, news,
                    updates and events
                </li>
                <li>conducting, and allowing you to participate in, a promotion, competition, promotional activity,
                    survey or market research; fulfil any related awards, and serve you relevant ads and content about
                    our goods or services and those of our business partners
                </li>
                <li>special promotions and offers and analysing our products and services so as to improve and develop
                    new products and services.
                </li>
            </ul>
            <p>This communication (including direct marketing materials) may take place via: email, text messages, other
                digital services, phone, internet-based marketing including targeted online advertising and online
                behavioural marketing.</p>
            <p>You consent to us sending you those direct marketing communications by any of those methods.</p>
            <p>You may receive some of these communications based on your profile as a user. All users may object to
                this type of processing. You can contact us at any time if you no longer wish to receive marketing
                materials from us or our related entities.</p>
            <p><em>With your consent</em><br/>Betty&rsquo;s Burgers may share your information other than as described
                in this policy if we notify you and you consent to the sharing</p>
            <p><em>Vendors</em><br/>We may share your Personal Information with vendors who provide services to us, such
                as fulfilling orders (e.g. Deliveroo), carrying out research (data processing), managing promotions,
                personalising customer experiences and other information technology services. We do not allow these
                vendors to use this information or to share it for any purpose other than to provide services on our
                behalf.</p>
            <p><strong>10. Marketing Opt-Outs</strong></p>
            <p>At any time, you may opt-out of receiving direct marketing communications from us or our related
                entities. We will then ensure your details are removed from our mailing lists and stop sending direct
                marketing communications to you within a reasonable period after your request is made (usually within 5
                business days from receiving your request). To opt-out follow the instructions in the marketing
                communications we send you or contact us.<br/>Please note that if you opt out, we may still send you
                non-promotional messages, such as receipts or information about your account.</p>
            <p><strong>11. Disclosure of Personal Information to third parties</strong></p>
            <p>We may disclose Personal Information we collect from you:</p>
            <ul>
                <li>to our related entities, suppliers, consultants, contractors or agents for the purposes set out in
                    the section above or for other purposes directly related to the purpose for which the Personal
                    Information is collected
                </li>
                <li>to relevant federal, state and territory authorities for the purpose of investigating a food safety
                    or health issue
                </li>
                <li>where the law requires or authorises us to do so (whether in Australia or overseas)</li>
                <li>to others that you have been informed of at the time any Personal Information is collected from
                    you
                </li>
                <li>with your consent (express or implied) to others</li>
            </ul>
            <p><strong>12. Overseas disclosures</strong></p>
            <p>We are based in Australia, so your Personal Information will be processed in Australia. However, some of
                our related entities and third-party goods and services providers are located overseas. We may need to
                share your Personal Information with organisations or persons located outside of Australia.<br/>The
                countries in which these organisations or persons are located will depend on the circumstances, but in
                the course of our ordinary operations, we may disclose Personal Information to our third- party service
                providers located in the United States, United Kingdom and Italy.<br/>If we disclose Personal
                Information to a third party in a country which does not have equivalent privacy laws to Australia, we
                will take steps to ensure that you are provided with appropriate safeguards in respect of your Personal
                Information. This might include entering into contractual clauses with the third-party that place
                obligations on the third-party in relation to their handling your Personal Information.<br/>Whilst Betty&rsquo;s
                Burgers will take all reasonable care to ensure that overseas providers will handle your Personal
                Information securely, you acknowledge that by agreeing to the disclosure of your Personal Information to
                these overseas entities for the purposes stated above, Betty&rsquo;s Burgers will not be held
                accountable for any breaches of the Australian Privacy Principles by an overseas recipient.</p>
            <p><strong>13. Overseas disclosures</strong></p>
            <p>We take protecting your Personal Information seriously and are continuously developing our security
                systems and processes. We take reasonable steps to protect your Personal Information from misuse and
                loss and unauthorised access, modification or disclosure. We do this by having physical and electronic
                security systems and by limiting who can access your Personal Information. We also have online and
                network security systems in place for our websites, so that the information you provide us online is
                protected and secure. Your Personal Information may be held in electronic or hard copy form. We use
                third party service providers to store some personal information.<br/>As our website and smart device
                application are linked to the internet which is inherently insecure, Betty&rsquo;s Burgers cannot
                provide any assurance regarding the security of the information you communicate to us online. We cannot
                guarantee that such information will not be intercepted so you transmit such information at your own
                risk. Please contact us if you become aware of, or suspect, any breach of security.<br/>If we become
                aware of a data breach that is likely to result in serious harm (for example, your Personal Information
                was shared with unauthorised persons) we will notify you as soon as is practicable and the Office of the
                Australian Information Commissioner about that actual or possible breach.</p>
            <p><strong>14. Access to Personal Information and Correction</strong></p>
            <p>We aim to ensure that any information we obtain from you is accurate. You can request access to your
                Personal Information or advise us of any required correction and information which you believe to be
                inaccurate by forwarding a request to privacy@BettysBurgers.com.<br/>We will require you to verify your
                identity and to specify what information you require. We may charge a fee to cover the cost of verifying
                the application and locating, retrieving, reviewing and copying any material requested. If the
                information sought is extensive, we will advise the likely cost in advance and can help to refine your
                request if required.<br/>The exceptions under the&nbsp;<em>Australian Privacy Principles</em>&nbsp;which
                affect your right to access your Personal Information we hold about you include the following:</p>
            <ul>
                <li>access would pose a serious threat to the life or health of any individual</li>
                <li>access would have an unreasonable impact on the privacy of others</li>
                <li>a frivolous or vexatious request</li>
                <li>the information relates to a commercially sensitive decision-making process</li>
                <li>access would be unlawful or denying access is required or authorised by law</li>
                <li>access would prejudice enforcement activities relating to criminal activities and other breaches of
                    law, public revenue, a security function, or negotiations with the individual
                </li>
                <li>legal dispute resolution proceedings.</li>
            </ul>
            <p><strong>15. Contact Us</strong></p>
            <p>Although we are committed to ensuring that at all times there is strict adherence to the&nbsp;<em>Australian
                Privacy Principles</em>, in the event that you are dissatisfied in any way with the collection, use,
                storage and/or disclosure of your Personal Information or believe that there has been a breach of the&nbsp;<em>Australian
                    Privacy Principles</em>, we invite you to contact us as soon as possible. We have appointed an
                internal Privacy Complaints Manager (who is also our Privacy Officer) to whom your complaint or any
                queries may be directed by:</p>
            <p>E-mail at privacy@BettysBurgers.com<br/>Calling us on (03) 9508 4400<br/>Writing to us at the below
                address:<br/>Attention: Privacy Officer<br/>Retail Zoo Legal Department Level 1, Tower 2, Chadstone
                Place<br/>1341 Dandenong Road<br/>Chadstone, VIC 3148</p>
            <p>If you are not satisfied with our response, you can write to &ldquo;Privacy Complaints Manager, Betty's
                Burgers Pty Ltd, Level 1, Tower 2, Chadstone Place, 1341 Dandenong Road, Chadstone, Victoria, 3148&Prime;.
                If you are not satisfied with the manner in which we deal with your complaint, you may refer it to the
                Office of the Australian Information Commissioner (&ldquo;<strong>OAIC</strong>&ldquo;). You can contact
                OAIC by:</p>
            <ul>
                <li>visiting&nbsp;<a href="#" @click="openLink('http://www.oaic.gov.au/')">www.oaic.gov.au</a>;</li>
                <li>forwarding an email to&nbsp;<a href="#" @click="openEmail('enquiries@oaic.gov.au')">enquiries@oaic.gov.au</a>;</li>
                <li>telephoning 1300 363 992; or</li>
                <li>writing to OAIC at GPO Box 5218, Sydney NSW 2001.</li>
            </ul>
            <p><strong>16. Changes to this Privacy Policy</strong></p>
            <p>We may occasionally make changes to this Privacy Policy. We will post any changes to this privacy policy
                on our websites and applications. When we make material changes to this Privacy Policy, we will provide
                you with notice pursuant to applicable laws.<br/>To the extent permitted under applicable law, by using
                our services after such notice, you consent to our updates to this policy.<br/>We encourage you to
                periodically review this policy for the latest information on our privacy practices.</p>
            <p><strong>17. Betty&rsquo;s Burgers subsidiaries and affiliates</strong></p>
            <p>We share information with our subsidiaries and affiliates to help us provide our services or conduct data
                processing on their behalf.</p>
        </div>
    </f7-page>
</template>
<script>
    import BasePage from './BasePage';

    export default {
        extends: BasePage,
        methods: {
            openLink(link) {
                window.open(link, '_system');
            },
            openEmail(email) {
                window.open(`mailto:${email}`, '_system');
            }
        }
    };
</script>
