<template>
    <f7-page class="ByronBayTermsAndConditionsPage">
        <f7-navbar class="ByronBayTermsAndConditionsPage__NavBar" no-shadow>
            <f7-nav-left>
                <f7-link @click="$f7router.back()">Back</f7-link>
            </f7-nav-left>
            <f7-nav-title>Terms & Conditions</f7-nav-title>
            <f7-nav-right>
                <f7-link>Back</f7-link>
            </f7-nav-right>
        </f7-navbar>
        <div class="ByronBayTermsAndConditionsPage__Content">

            <p><strong>Take Your Bestie on The Ultimate Beach Hop Holiday Terms &amp; Conditions ("Conditions of
                Entry")</strong></p>
            <div border="1" width="538" cellspacing="0" cellpadding="5">
                <div>
                <div>
                    <div colspan="2" width="526">
                        <p><strong>Schedule</strong></p>
                    </div>
                </div>
                <div>
                    <div width="52">
                        <p><strong>Promotion: </strong></p>
                    </div>
                    <div width="464">
                        <p>Take Your Bestie on The Ultimate Beach Hop Holiday</p>
                    </div>
                </div>
                <div>
                    <div width="52">
                        <p><strong>Promoter: </strong></p>
                    </div>
                    <div width="464">
                        <p><a name="_Hlk34307156"></a>Betty's Burgers Australia Pty Ltd ABN 35132912817, Level 1, Tower
                            2 Chadstone Place, 1341 Dandenong Road, Chadstone, VIC 3148, Australia. Ph: (03) 9508 4400
                        </p>
                    </div>
                </div>
                <div>
                    <div width="52">
                        <p><strong>Promotional Period:</strong></p>
                    </div>
                    <div width="464">
                        <p><strong>Start date: </strong>01/04/20 at 09:00 am AEDT</p>
                        <p><strong>End date: </strong>05/07/20 at 11:59 pm AEST</p>
                    </div>
                </div>
                <div>
                    <div width="52">
                        <p><strong>Eligible entrants: </strong></p>
                    </div>
                    <div width="464">
                        <p><a name="_Hlk34307539"></a>Entry is only open to Australian residents who are 18 years and
                            over.</p>
                    </div>
                </div>
                <div>
                    <div width="52">
                        <p><strong>How to Enter:</strong></p>
                    </div>
                    <div width="464">
                        <p>To enter the Promotion, you must complete the following steps during the Promotional
                            Period:</p>
                        <ol type="a">
                            <li>
                                <p>register an account on the Betty&rsquo;s Burgers App downloadable via the App Store
                                    or Google Play Store (if you do not have an existing account); and</p>
                            </li>
                            <li>
                                <p>either:</p>
                                <ol type="a">
                                    <li>
                                        <p><strong>In-Store Purchase: </strong>complete a purchase at any Betty&rsquo;s
                                            Burgers store within Australia and scan the promotional barcode within the
                                            Betty&rsquo;s Burgers App at the time of purchase; OR</p>
                                    </li>
                                    <li>
                                        <p><strong>In-App Purchase:</strong> pre-order via the Betty&rsquo;s Burgers App
                                            for automatic entry,</p>
                                    </li>
                                </ol>
                            </li>
                        </ol>
                        <p>(each an &ldquo;<strong>Eligible Order</strong>&rdquo;).</p>
                    </div>
                </div>
                <div>
                    <div width="52">
                        <p><strong>Entries permitted:</strong></p>
                    </div>
                    <div width="464">
                        <p>Multiple entries permitted subject to the following:</p>
                        <ol type="a">
                            <li>
                                <p>limit one (1) entry permitted per Eligible Order; and</p>
                            </li>
                            <li>
                                <p>each entry must be submitted separately and in accordance with the entry instructions
                                    above.</p>
                            </li>
                        </ol>
                    </div>
                </div>
                <div>
                    <div width="52">
                        <p><strong>Total Prize Pool: </strong></p>
                    </div>
                    <div width="464">
                        <p><a name="_Hlk34307028"></a>Up to AUD $10,000.00</p>
                    </div>
                </div>
                <div>
                    <div colspan="2" width="526">
                        <div border="1" width="522" cellspacing="0" cellpadding="5">
                            <div>
                            <div>
                                <div width="286">
                                    <p><strong>Prize Description</strong></p>
                                </div>
                                <div width="53">
                                    <p><strong>Number of this prize</strong></p>
                                </div>
                                <div width="80">
                                    <p><strong>Value (per prize)</strong></p>
                                </div>
                                <div width="61">
                                    <p><strong>Winning Method</strong></p>
                                </div>
                            </div>
                            <div>
                                <div width="286">
                                    <p><a name="_Hlk34307007"></a> The prize is a beach hoping trip for two (2) people
                                        to Byron Bay, Noosa and Gold Coast which consists of<br/>the following:</p>
                                    <p>2 x return economy class flights from the winner's nearest capital or nearest
                                        major airport (as determined by the Promoter) to Maroochydore (if winner resides
                                        within close proximity of the accommodation destinations to not warrant flights,
                                        as determined by the Promoter, flights will not be provided);</p>
                                    <p>2 nights' accommodation at Peppers Noosa Resort &amp; Villas (includes breakfast
                                        daily);</p>
                                    <p>3 nights' accommodation at Q1 Resort and Spa;</p>
                                    <p>2 nights' accommodation at Byron Bay Hotel and Apartments;</p>
                                    <p>Car hire for 7 days; and</p>
                                    <p>$500 Betty&rsquo;s Burgers Dining Voucher.</p>
                                    <p><strong>Trip must be redeemed by December
                                        15</strong><sup><strong>th</strong></sup><strong> 2020.</strong></p>
                                </div>
                                <div width="53">
                                    <p>1</p>
                                </div>
                                <div width="80">
                                    <p>Up to AUD$10,000.00, depending on exact date and point of departure</p>
                                </div>
                                <div width="61">
                                    <p>Draw: computerised random selection - 06/07/20 at 02:30 pm AEST</p>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div width="52">
                        <p><strong>Prize Conditions:</strong></p>
                    </div>
                    <div width="464">
                        <ul>
                            <ul>
                                <li>
                                    <p>Travel itinerary will be determined by the Promoter in its absolute
                                        discretion.</p>
                                </li>
                                <li>
                                    <p>Travel must be taken before 15<sup>th</sup> December 2020.</p>
                                </li>
                                <li>
                                    <p>The prize is subject to booking and flight availability.</p>
                                </li>
                                <li>
                                    <p>Frequent flyer points will not be awarded and do not form part of the prize.</p>
                                </li>
                                <li>
                                    <p>Spending money, meals, taxes (excluding airline and airport taxes), insurance,
                                        transport to and from departure point, transfers, items of a personal nature,
                                        in-room charges and all other ancillary costs, unless otherwise specified in the
                                        prize description herein, are not included.</p>
                                </li>
                            </ul>
                        </ul>
                        <ul>
                            <ul>
                                <li>
                                    <p>Subject to the terms and conditions of the participating prize provider(s), if
                                        for any reason the winner does not, once the prize has been booked, take the
                                        prize (or an element of the prize) at the time stipulated, then the prize (or
                                        that element of the prize) will be forfeited and will not be redeemable for
                                        cash.</p>
                                </li>
                                <li>
                                    <p>The winner will be required to present their credit card at accommodation check
                                        in.</p>
                                </li>
                                <li>
                                    <p>Prize is subject to the standard terms and conditions of individual prize and
                                        service providers.</p>
                                </li>
                                <li>
                                    <p>The winner and his/her travel companion must depart from and return to the same
                                        departure point and travel together.</p>
                                </li>
                                <li>
                                    <p>During the entire duration of the prize, a nominated parent/guardian must
                                        accompany any person under 18 years of age.</p>
                                </li>
                                <li>
                                    <p>Car hire prize is subject to standard rental conditions. Petrol and insurance are
                                        not included. The driver must have a current valid driver&rsquo;s license issued
                                        in an Australian State or Territory and be aged 21 years or over. If the winner
                                        or his/her companion for the prize do not meet the age or license requirements
                                        for car hire, they will forfeit their right to the car hire element of the prize
                                        and will not be awarded cash or any other alternative in lieu.</p>
                                </li>
                            </ul>
                        </ul>
                    </div>
                </div>
                <div>
                    <div width="52">
                        <p><strong>Winner notification:</strong></p>
                    </div>
                    <div width="464">
                        <p>The winner will be contacted by email within two (2) business days of the draw. The winner
                            will be published at https://www.facebook.com/bettysburgersco/ by 13/07/20.</p>
                    </div>
                </div>
                <div>
                    <div width="52">
                        <p><strong>Unclaimed Prizes:</strong></p>
                    </div>
                    <div width="464">
                        <p>Prize must be claimed by 06/10/20 at 02:30 pm AEDT. In the event of an unclaimed prize, the
                            prize will be redrawn on 07/10/20 at 02:30 pm AEDT at Plexus, Level 4, 411 Collins Street,
                            Melbourne VIC 3000. The winner of the redraw will be notified by email within two (2)
                            business days of the redraw. The winner will be notified publicly (and their details
                            published) at https://www.facebook.com/bettysburgersco/ by 14/10/20.</p>
                    </div>
                </div>
                </div>
            </div>
            <ol>
                <li>
                    <p>The entrant agrees and acknowledges that they have read these Conditions of Entry (and Schedule)
                        and that entry into the Promotion is deemed to be acceptance of these Conditions of Entry (and
                        Schedule). Any capitalised terms used in these Conditions of Entry have the meaning given in the
                        Schedule, unless stated otherwise.</p>
                </li>
                <li>
                    <p>The Promotion commences on the Start Date and ends on the End Date ("Promotional Period").
                        Entries are deemed to be received at the time of receipt by the Promoter and not at the time of
                        transmission or deposit by the entrant. Records of the Promoter and its agencies are final and
                        conclusive as to the time of receipt.</p>
                </li>
                <li>
                    <p>Valid and eligible entries will be accepted during the Promotional Period.</p>
                </li>
                <li>
                    <p>Employees (and the immediate family members) of agencies/companies directly associated with the
                        conduct of this Promotion, the Promoter, its distributors, suppliers, subsidiary
                        companies/businesses and associated companies and agencies are not eligible to enter. "Immediate
                        family member" means any of the following: spouse, ex-spouse, de-facto spouse, child or
                        step-child (whether natural or by adoption), parent, step-parent, grandparent, step-grandparent,
                        uncle, aunt, niece, nephew, brother, sister, step-brother, step-sister or 1st cousin.</p>
                </li>
                <li>
                    <p><u>Draw:</u></p>
                    <ol type="a">
                        <li>
                            <p>The draw will take place at Plexus, Level 4, 411 Collins Street, Melbourne VIC 3000 at
                                02:30 pm AEST on 06/07/20 using computerised random selection.</p>
                            <ol type="i">
                                <li>
                                    <p>The first valid entry drawn will be the winner of the prize specified in the
                                        Schedule above.</p>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <p>The draw conductor may draw additional reserve entries in case an invalid entry or
                                entrant is drawn.</p>
                        </li>
                        <li>
                            <p>If a draw is scheduled on the weekend or a public holiday, the draw will be conducted at
                                the same time and location on the following business day. The Promoter will ensure each
                                draw is open for public scrutiny and anyone may witness the draw on request. The winner
                                of a drawn prize is determined by chance.</p>
                        </li>
                    </ol>
                </li>
                <li>
                    <p>All reasonable attempts will be made to contact each winner.</p>
                </li>
                <li>
                    <p>If any winner chooses not to take their prize (or is unable to), or does not take or claim a
                        prize by the time specified by the Promoter, or is unavailable, they forfeit the prize and the
                        Promoter is not obliged to substitute the prize.</p>
                </li>
                <li>
                    <p>The value of the prize is accurate and based upon the recommended retail value of the prize
                        (inclusive of GST) at the date of printing. The Promoter accepts no responsibility for any
                        variation in the value of the prize after that date.</p>
                </li>
                <li>
                    <p>No part of a prize is exchangeable, redeemable for cash or any other prize or transferable,
                        unless otherwise specified in writing by the Promoter.</p>
                </li>
                <li>
                    <p>If a prize (or portion of a prize) is unavailable the Promoter reserves the right to substitute
                        the prize (or that portion of the prize) to a prize of equal or greater value and/or
                        specification, subject to any written directions of a regulatory authority.</p>
                </li>
                <li>
                    <p>No entry fee is charged by the Promoter to enter the Promotion.</p>
                </li>
                <li>
                    <p>If there is a dispute as to the identity of an entrant or winner, the Promoter reserves the
                        right, in its sole discretion, to determine the identity of the entrant or winner.</p>
                </li>
                <li>
                    <p>Any questions or comments regarding the Promotion must be directed to the Promoter, not to
                        Facebook. The entrant releases Facebook and its associated companies from all liabilities
                        arising in respect of the Promotion. Entrants acknowledge that the Promotion is in no way
                        sponsored, endorsed or administered by, or associated with Facebook.</p>
                </li>
                <li>
                    <p>Entrants' personal information will be collected by the Promoter. Personal information will be
                        stored on the Promoter's database. The Promoter may use this information for future marketing
                        purposes regarding its products, including contacting the entrant electronically. The Promoter
                        is bound by the Australian Privacy Principles in accordance with the Privacy Act 1988 (Cth) and
                        its privacy policy which is located at https://www.bettysburgers.com.au/privacy.html. The
                        Promoter's privacy policy contains information about how the entrant may access, update and seek
                        correction of the personal information the Promoter holds about them and how the entrant may
                        complain about any potential breach by the Promoter of the Australian Privacy Principles or any
                        other Australian privacy laws and how such complaints will be dealt with. The Promoter collects
                        personal information about entrants to enable them to participate in this Promotion and may
                        disclose the entrants' personal information to third parties including its contractors and
                        agents, prize suppliers and service providers to assist in conducting this Promotion and to the
                        State and Territory lottery departments as required under the relevant lottery legislation. If
                        the entrant does not provide their personal information as requested, they may be ineligible to
                        enter or claim a prize in the Promotion. The Promoter may transfer entrants' personal
                        information to countries specified in the Promoter&rsquo;s privacy policy.</p>
                </li>
                <li>
                    <p>For the purposes of public statements and advertisements, the Promoter may only publish the
                        winner's surname, initial and State/Territory or postcode of residence.</p>
                </li>
                <li>
                    <p>It is a condition of accepting the prize that the winner may be required to sign a legal release
                        as determined by the Promoter in its absolute discretion, prior to receiving the prize.</p>
                </li>
                <li>
                    <p>If a prize is provided to the Promoter by a third party, the prize is subject to the terms and
                        conditions of the third party prize supplier and the provision of the prize is the sole
                        responsibility of the third party and not the Promoter. The terms and conditions which apply to
                        the prize at the time it is issued to the winner will prevail over these Conditions of Entry, to
                        the extent of any inconsistency. The Promoter accepts no responsibility or liability for any
                        delay or failure by the third party to deliver the prize, any delay or failure relating to the
                        prize itself or failure by the third party to meet any of its obligations in these Conditions of
                        Entry or otherwise.</p>
                </li>
                <li>
                    <p>Any guarantee or warranty given is in addition to any relevant statutory guarantees and
                        warranties and nothing in these Conditions of Entry restricts, excludes or modifies or purports
                        to restrict, exclude or modify any statutory consumer rights under any applicable law including
                        the Competition and Consumer Act 2010 (Cth).</p>
                </li>
                <li>
                    <p>If for any reason any aspect of this Promotion is not capable of running as planned, including by
                        reason of computer virus, communications network failure, bugs, tampering, unauthorised
                        intervention, fraud, technical failure or any cause beyond the control of the Promoter, the
                        Promoter may in its sole discretion cancel, terminate, modify or suspend the Promotion and
                        invalidate any affected entries, or suspend or modify a prize, subject to State or Territory
                        regulation.</p>
                </li>
                <li>
                    <p>The Promoter reserves the right, at any time, to validate and check the authenticity of entries
                        and entrant's details (including an entrant's identity, age and place of residence). In the
                        event that a winner cannot provide suitable proof as required by the Promoter to validate their
                        entry, the winner will forfeit the prize in whole and no substitute will be offered. Incomplete,
                        indecipherable, inaudible, incorrect and illegible entries, as applicable, will at the
                        Promoter's discretion be deemed invalid and not eligible to win. Entries containing offensive or
                        defamatory comments, or which breach any law or infringe any third party rights, including
                        intellectual property rights, are not eligible to win. The use of any automated entry software
                        or any other mechanical or electronic means that allows an individual to automatically enter
                        repeatedly is prohibited and may render all entries submitted by that individual invalid.</p>
                </li>
                <li>
                    <p>The Promoter reserves the right to disqualify entries in the event of non-compliance with these
                        Conditions of Entry. In the event that there is a dispute concerning the conduct of the
                        Promotion, the decision of the Promoter is final and binding on each entrant and no
                        correspondence will be entered into.</p>
                </li>
                <li>
                    <p><a name="_GoBack"></a> The Promoter and its associated agencies and companies will not be liable
                        for any loss (including, without limitation, indirect, special or consequential loss or loss of
                        profits), expense, damage, personal injury or death which is suffered or sustained (whether or
                        not arising from any person's negligence or wilful misconduct) in connection with this Promotion
                        or accepting or using any prize (or recommendation), except for any liability which cannot be
                        excluded by law (in which case that liability is limited to the minimum allowable by law).</p>
                </li>
                <li>
                    <p>The entrant will participate in and co-operate as required with all reasonable marketing and
                        editorial activities relating to the Promotion, including (but not limited to) being recorded,
                        photographed, filmed or interviewed and acknowledges that the Promoter may use any such
                        marketing and editorial material without further reference or compensation to them.</p>
                </li>
                <li>
                    <p>Unless otherwise specified, a prize is a single event for the winner (and where relevant their
                        guest) and cannot be separated into separate events or components.</p>
                </li>
                <li>
                    <p>The Promoter accepts no responsibility for any tax implications and the entrant must seek their
                        own independent financial advice in regards to the tax implications relating to the prize or
                        acceptance of the prize.</p>
                </li>
                <li>
                    <p>Failure by the Promoter to enforce any of its rights at any stage does not constitute a waiver of
                        these rights.</p>
                </li>
                <li>
                    <p>Authorised under: NSW Permit No. LTP/XX/XXXX; ACT Permit No. XXXX and SA Permit No. TX/XXXX.</p>
                </li>
            </ol>
        </div>
    </f7-page>
</template>
<script>
    import BasePage from './base';

    export default {
        extends: BasePage,
        methods: {}
    };
</script>
