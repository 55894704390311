<script>

    import BaseModel from "../models/BaseModel";

    export default {
        name: 'BaseComponent',
        data: function () {
            return {
                // always false in the beginning
                isLoaded: false
            };
        },
        methods: {
            getImageUrl(imageUrl) {
                return BaseModel.getImageUrl(imageUrl);
            },
        },
        filters: {
            currency: function (value) {
                return value ? `${(value / 100).toFixed(2)}` : ''
            },
        }
    };
</script>

<style scoped>

</style>
