import { render, staticRenderFns } from "./OrderHistoryPage.vue?vue&type=template&id=cecd98b2&scoped=true&"
import script from "./OrderHistoryPage.vue?vue&type=script&lang=js&"
export * from "./OrderHistoryPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cecd98b2",
  null
  
)

export default component.exports