export default {
    /**
     *
     * @param productName string
     */
    logProductAddToCart(productName) {

        window.dataLayer.push({ecommerce: null});  // Clear the previous ecommerce object.
        window.dataLayer.push({
            'event': 'addToCart',
            'ecommerce': {
                'add': {                                // 'add' actionFieldObject measures.
                    'products': [{                        //  adding a product to a shopping cart.
                        'name': productName,
                    }]
                }
            }
        });

        console.log(`[Data Layer | Added to cart] ${productName}`);
    },
    logProductView(productName, categoryName) {

        window.dataLayer.push({ecommerce: null});  // Clear the previous ecommerce object.
        window.dataLayer.push({
            'event': 'productView',
            'ecommerce': {
                'detail': {
                    'products': [{
                        'name': productName,
                        'category': categoryName,
                    }]
                }
            }
        });
        console.log(`[Data Layer | Product Viewed] ${productName} of ${categoryName}`);
    },
    logCategoryView(categoryName) {
        window.dataLayer.push({ecommerce: null});  // Clear the previous ecommerce object.
        window.dataLayer.push({
            'event': 'categoryView',
            'ecommerce': {                       // Local currency is optional.
                'impressions': [
                    {
                        'category': categoryName,
                    }]
            }
        });
        console.log(`[Data Layer | Category Viewed] ${categoryName}`);
    },
    logProductRemoveFromCart(productName) {
        window.dataLayer.push({ecommerce: null});  // Clear the previous ecommerce object.
        window.dataLayer.push({
            'event': 'removeFromCart',
            'ecommerce': {
                'remove': {                               // 'remove' actionFieldObject measures.
                    'products': [{                          //  removing a product to a shopping cart.
                        'name': productName
                    }]
                }
            }
        });
        console.log(`[Data Layer | Removed from cart] ${productName}`);
    },
    logCustomerEmail(email, eventName) {
        window.dataLayer.push({
            'event': eventName,
            'customer_details': {
                'email': email
            }
        });

        console.log(`[Data Layer | Customer Email] ${email}`);
    },

    logCustomerEmailOnCheckoutSuccess(email){
        this.logCustomerEmail(email, 'checkout_success');
    },
    logCustomerEmailOnLogin(email){
        this.logCustomerEmail(email, 'login');
    },
    logCustomerEmailOnRegister(email){
        this.logCustomerEmail(email, 'register');
    },
    logStoreName(storeName) {
        window.dataLayer.push({
            'event': 'checkout_success',
            'details': {
                'storeName': storeName
            }
        });

        console.log(`[Data Layer | Store Name] ${storeName}`);
    },
    logCartView() {
        window.dataLayer.push({ecommerce: null});  // Clear the previous ecommerce object.
        window.dataLayer.push({
            'event': 'cartView'
        });
        console.log(`[Data Layer | Cart View]`);
    },
    logStoreChange(storeName) {
        window.dataLayer.push({
            'event': 'pickUpStoreLocation',
            'details': {
                'storeName': storeName
            }
        });

        console.log(`[Data Layer | Store Change] ${storeName}`);
    },
}
