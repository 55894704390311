import BaseModel from "./BaseModel";
import LinkCardForm from "./Forms/LinkCardForm";

export default class CreditCard extends BaseModel {

    id;
    brand;
    name;
    last4;
    exp_month;
    exp_year;

    constructor() {
        super();
        this.id = null;
        this.brand = '';
        this.name = '';
        this.last4 = '';
        this.exp_month = null;
        this.exp_year = null;
    }

    isValid() {
        return this.id !== null;
    }

    //restore from cache
    restore() {
        Object.keys(this).forEach((key) => {
            this[key] = localStorage.getItem(`bettysburger-v2-credit-card--${key}`)
        })
    }

    //save to cache
    store() {
        Object.keys(this).forEach((key) => {
            localStorage.setItem(`bettysburger-v2-credit-card--${key}`, this[key])
        })
    }

    fill(data) {
        if (data === null) {
            this.id = null;
        } else {
            Object.keys(this).forEach((key) => {
                this[key] = data[key];
            })
        }
    }

    static get(customerID) {
        let url = `api/public/credit-cards`;
        return super.get(url, {
            params: {
                customerID: customerID
            }
        });
    }

    static link(customerID, creditCardForm) {
        let formattedCreditCardForm = new LinkCardForm();

        formattedCreditCardForm.fill(creditCardForm);
        formattedCreditCardForm.format();
        let url = `api/public/credit-cards`;
        return super.post(url, {
            customerID: customerID,
            card: formattedCreditCardForm
        });
    }

    static delete(customerID, creditCardID) {
        let url = `api/public/credit-cards`;
        return super.delete(url, {
            params: {
                customerID: customerID,
                creditCardID: creditCardID
            }
        });
    }
}