<template>
    <f7-page class="ClubPage">
        <BettysToolbar :index="3" slot="fixed"></BettysToolbar>
        <div class="ClubPage__Banner">
            <div class="ClubPage__Banner__Text"><strong>Hello,</strong><br>
                <strong>{{member.GivenNames}} {{member.Surname}}</strong>
            </div>
            <div class="ClubPage__Banner__Small"><strong>Member</strong> {{member.MemberNo}}</div>
        </div>
        <div class="ClubPage__Content">
            <f7-list no-chevron no-hairlines class="ClubPage__List margin-top-half no-margin-bottom">
                <f7-list-item class="ClubPage__List__ListItem" link="/account/">Your Account</f7-list-item>
                <f7-list-item class="ClubPage__List__ListItem" link="/order-history/">Order History</f7-list-item>
                <f7-list-item class="ClubPage__List__ListItem" link="/credit-card/">Payment</f7-list-item>
                <f7-list-item class="ClubPage__List__ListItem" link="/faqs/">FAQs
                </f7-list-item>
                <f7-list-item class="ClubPage__List__ListItem" @click="openAllergensWebpage">Allergens
                </f7-list-item>
                <f7-list-item class="ClubPage__List__ListItem" link="/contact-us/">Contact us</f7-list-item>
            </f7-list>
            <div class="ClubPage__LineBreak"></div>
            <f7-list no-hairlines class="ClubPage__List margin-top-half" no-chevron>
                <f7-list-item class="ClubPage__List__ListItem small" link="/terms-and-conditions/">Terms And
                    Conditions
                </f7-list-item>
                <f7-list-item class="ClubPage__List__ListItem small" link="/privacy-policy/">Privacy Policy
                </f7-list-item>
                <f7-list-item class="ClubPage__List__ListItem small" @click="signOut">Sign Out</f7-list-item>
                <f7-list-item class="ClubPage__List__ListItem small">{{appVersion}}/{{appBuild}}</f7-list-item>
            </f7-list>
        </div>
    </f7-page>
</template>
<script>
    import BasePage from './base';
    import Member from "../models/Member";
    import Token from "../models/Token";
    import BettysToolbar from "./BettysToolbar";
    import Env from "../Env";

    export default {
        extends: BasePage,
        components: {
            BettysToolbar
        },
        data() {
            return {
                member: new Member(),
                token: new Token(),
                appVersion: Env.app.version,
                appBuild: Env.app.build
            }
        },
        methods: {
            signOut: function () {
                this.$f7.dialog.confirm('Are you sure?', () => {
                    this.member.purge();
                    this.token.purge();
                    this.$f7router.navigate('/', {
                        reloadAll: true
                    })
                });
            },
            openAllergensWebpage() {
                window.open(`${Env.url}/allergens`, '_system');
            }
        },
        mounted() {
            this.member.restore();
            this.token.restore();
        }
    };
</script>
