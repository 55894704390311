<template>

</template>
<script>

    import Env from '../Env'
    import moment from 'moment'
    import BaseModel from "../models/BaseModel";
    import Position from "../models/Position";

    export default {
        data() {
            return {
                loading: false,
                loaded: false,
                instabug: {
                    enabled: true,
                },
                dev: {
                    enabled: Env.env === 'dev',
                    params: {
                        position: {
                            latitude: -38.3441120,
                            longitude: 145.3608150
                        }
                    }
                },
                member: null,
                token: null,
                platform: null,
                uuid: null,
                //reflect cordova device
                device: null
            }
        },
        methods: {

            openStoresPage(back) {
                this.$f7.preloader.show();
                navigator.geolocation.getCurrentPosition((position) => {
                    this.$f7.preloader.hide();
                    let lat = position.coords.latitude;
                    let lng = position.coords.longitude;
                    this.$f7router.navigate(`/map/?lat=${lat}&lng=${lng}&back=${back}`, {
                        reloadCurrent: true
                    });
                }, (error) => {
                    this.$f7.preloader.hide();
                    this.$f7router.navigate(`/find-a-bettys/`, {
                        animate: false
                    });
                }, {
                    timeout: 5000
                });
            },
            getImageUrl(imageUrl) {
                return BaseModel.getImageUrl(imageUrl);
            },

            openHomePage() {
                this.$f7router.navigate('/', {
                    context: {
                        member: this.member,
                        token: this.token,
                        uuid: this.uuid,
                        platform: this.platform
                    }
                })
            },

            openAccountPage() {
                this.$f7router.navigate('/account/', {
                    context: {
                        member: this.member,
                        token: this.token,
                        uuid: this.uuid,
                        platform: this.platform
                    }
                })
            },

            isRunningOnIOS() {
                return this.platform === 'ios';
            },
            isRunningOnAndroid() {
                return this.platform === 'android';
            },

            //load uuid
            loadUUID(device) {
                this.uuid = this.dev.enabled ? '490b4024-298d-4bf9-9769-2c78dfde3556' : device.uuid;
            },

            //load platform
            loadPlatform(device) {
                this.platform = this.dev.enabled ? 'android' : device.platform.toLowerCase();
            },

            initKeyParams() {
                if (this.dev.enabled) {
                    //fake data here
                    this.uuid = '490b4024-298d-4bf9-9769-2c78dfde3556';
                    this.platform = 'ios';
                    if (this.dev.params.position !== null)
                        this.position = new Position(this.dev.params.position);
                } else {
                    try {
                        this.uuid = device.uuid;
                    } catch (e) {
                        this.$f7.toast.show({
                            text: 'Error: unable to fetch device uuid',
                            closeTimeout: 1000
                        })
                    }
                    try {
                        this.platform = device.platform;
                    } catch (e) {
                        this.$f7.toast.show({
                            text: 'Error: unable to detect device platform',
                            closeTimeout: 1000
                        })
                    }

                    try {
                        navigator.geolocation.getCurrentPosition((position) => {
                            this.position = new Position(position.coords);
                        }, (error) => {
                            this.position = new Position()
                        }, {maximumAge: 3000, timeout: 5000, enableHighAccuracy: true});
                    } catch (e) {
                        this.position = new Position()
                    }
                }
            }
        },
        filters: {
            total: function (value) {
                return value ? `${(value / 100).toFixed(2)}` : '0'
            },
            currency: function (value) {
                return value ? `$${(value / 100).toFixed(2)}` : '$0'
            },
            leadingZero: function (value) {
                return value < 10 ? `0${value}` : `${value}`
            },
            storeTradingHours: function (store) {
                let now = moment.unix();
                if (!store.IsOpen || store.FirstOrderTime > now || store.LastOrderTime < now)
                    return 'Closed';
                else
                    return 'Open Now: ' + store.openingHours;
            },
            ddmmyyyy: function (value) {
                return moment(value).format('DD/MM/YYYY')
            }
        }
    }
</script>