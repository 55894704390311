<template>
    <f7-page class="ContactUsPage">
        <div class="ContactUsPage__Banner">
            <div class="ContactUsPage__Banner__Top">
                <f7-button @click="closePage"
                           class="fb__back"
                           >
                    <i class="bettys-burgers-web-2 bettys-burgers-web-2-Arrow-Back-Icon"></i>
                </f7-button>
            </div>
            <div class="ContactUsPage__Banner__Header">
                Hello, <br>How can we help?
            </div>
        </div>
        <div class="ContactUsPage__Content">
            <f7-list no-hairlines>
                <f7-list-input
                        outline
                        type="text"
                        placeholder="Full name*"
                        :value="form.fullName"
                        @change="form.fullName = $event.target.value"
                />
                <f7-list-input
                        outline
                        type="email"
                        placeholder="Email address*"
                        :value="form.email"
                        @change="form.email = $event.target.value"
                />
                <f7-list-input
                        outline
                        type="select"
                        :value="state"
                        @change="state = $event.target.value"
                >
                    <option value="VIC">
                        VIC
                    </option>
                    <option value="NSW">
                        NSW
                    </option>
                    <option value="QLD">
                        QLD
                    </option>
                    <option value="SA">
                        SA
                    </option>
                    <option value="WA">
                        WA
                    </option>
                    <option value="TAS">
                        TAS
                    </option>

                </f7-list-input>
                <f7-list-input
                        outline
                        type="select"
                        :value="form.store"
                        @change="form.store = $event.target.value"
                >
                    <option value="" disabled selected>
                        Select Restaurant*
                    </option>
                    <option
                            v-for="store in stores"
                            v-if="store.state===state"
                            :value="store.name">{{store.name}}
                    </option>
                </f7-list-input>
                <f7-list-input
                        outline
                        type="select"
                        :value="form.category"
                        @change="form.category = $event.target.value"
                >
                    <option value="" disabled selected>
                        Select Category*
                    </option>
                    <option value="Guest Service">Guest Service</option>
                    <option value="Food Product">Food Product</option>
                    <option value="Marketing & Media">Marketing & Media</option>
                    <option value="General">General</option>
                </f7-list-input>
                <f7-list-input
                        outline
                        type="textarea"
                        placeholder="Message*"
                        :value="form.message"
                        @change="form.message = $event.target.value"

                />
                <f7-block>
                    <f7-button @click="submit" large fill>Submit</f7-button>
                </f7-block>
            </f7-list>
        </div>
    </f7-page>
</template>
<script>
    import BasePage from './base';
    import ContactForm from "../models/ZenDesk/ContactForm";
    import Member from "../models/Member";
    import Store from "../models/Store";

    export default {
        extends: BasePage,
        data() {
            return {
                form: new ContactForm(),
                member: new Member(),
                stores: [],
                state: 'VIC'
            }
        },
        mounted() {
            this.member.restore();
            if(this.member.isValid()) {
                this.form.fullName = this.member.FullName;
                this.form.email = this.member.Email;
            }
            Store.all().then((response) => {
                if (response.success) {
                    this.stores = response.stores;
                }
            })
        },
        methods: {
            submit() {
                this.$f7.preloader.show();
                ContactForm.submit(this.form).then((response) => {
                    this.$f7.dialog.alert(response.message, response.success ? 'Success' : 'Error');
                    this.$f7.preloader.hide();
                    if (response.success) {
                        this.closePage()
                    }
                })
            },
            closePage()
            {
                if (this.$f7router.view.name==='main')
                {
                    this.$f7router.back();
                }
                else
                {
                    this.$f7router.emit('view:close')
                }
            }
        },
        watch: {
            state: function () {
                this.form.store = null;
            }
        }
    };
</script>
