export default class {

    name;
    number;
    exp;
    cvc;

    constructor() {
        this.name = '';
        this.number = '';
        this.exp = '';
        this.cvc = '';
    }

    fill(data) {
        if (data !== null && data !== undefined) {
            Object.keys(this).forEach((key) => {
                this[key] = data[key]
            })
        }
    }

    fillFromCreditCard(creditCard) {
        console.log(creditCard);
        this.fill({
            name: creditCard.name,
            number: '',
            exp: `${creditCard.exp_month}/${creditCard.exp_year}`,
            cvc: ''
        })
    }

    format() {
        this.number = this.number.replace(/\s/g, '')
    }
}