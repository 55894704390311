import BaseModel from "./BaseModel";

export default class Deal extends BaseModel {

    id;
    name;
    description;
    subtitle;
    from;
    to;
    image;
    innerImage;
    isNew;
    product;
    isScannableInRestaurant;

    constructor(data) {
        super();
        this.id = null;

        if (data !== null && data !== undefined) {
            this.fill(data)
        }
    }

    isValid() {
        return this.id !== null;
    }

    fill(data) {
        Object.keys(data).forEach((key) => {
            this[key] = data[key];
        });
        this.FullName = `${this.GivenNames} ${this.Surname}`;
    }

    static all() {
        let url = '/api/public/deals';
        return super.all(url)
    }

    static allWithMember(member, token) {
        let url = '/api/public/deals';
        return super.all(url, {
            params: {
                MemberNo: member.MemberNo,
                email: member.Email,
                token: token.token
            }
        })
    }

    static get(deal, member, token, device) {
        return super.post(`/api/public/deals/${deal.id}`, {
            member: member,
            token: token,
            uuid: device.uuid,
            platform: device.platform
        });
    }

    //restore from cache
    restore() {
        Object.keys(this).forEach((key) => {
            this[key] = localStorage.getItem(`bettysburger-v2-deal--${key}`)
        })
        this.isScannableInRestaurant = this.isScannableInRestaurant === 'true';
    }

    //save to cache
    store() {
        Object.keys(this).forEach((key) => {
            localStorage.setItem(`bettysburger-v2-deal--${key}`, this[key])
        })
    }
}