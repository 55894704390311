<template>
  <div class="ProductComponent
display-flex flex-direction-row">
    <div class="ProductComponent__Product__Image"
         :style="`background-image: url(${getImageUrl(product.productImage)})`">
    </div>
    <div class="ProductComponent__Product__Details" v-if="view.isBasicView()">
      <div class="ProductComponent__Product__Title">
        {{ product.editedName }}
      </div>
      <div class="ProductComponent__Product__Description">
        {{ product.description }}
      </div>
      <f7-link @click="view.changeToCustomizeView()" class="ProductComponent__Product__Customize">
        Customize
      </f7-link>
      <div class="ProductComponent__Product__Qty">
        <f7-stepper :disabled="!product.isQtyEditable" :min="1" :value="cartItem.qty"
                    @change="cartItem.qty = parseInt($event.target.value)"
                    large/>
      </div>
    </div>
    <div class="ProductComponent__CustomiseProduct" v-if="view.isCustomizeView()">
      <div class="ProductComponent__CustomiseProduct__Nav">
        <div>
          <f7-link @click="view.changeToBasicView()" class="ProductComponent__CustomiseProduct__Icon"
                   icon-material="arrow_backward"/>
        </div>
        <div class="ProductComponent__CustomiseProduct__Header">
          Customize
        </div>
        <div class="opacity-0">
          <f7-link class="ProductComponent__CustomiseProduct__Icon" icon-material="arrow_backward"/>
        </div>
      </div>
      <div class="ProductComponent__CustomiseProduct__Content">
        <div>
          <f7-list no-hairlines accordion-list class="ProductPage__Product__Options">
            <f7-list-item
                accordion-item
                v-for="ingredientCategory in menu.ingredientCategories"
                v-if="ingredientCategory.ingredients.length > 0"
                class="ProductPage__Product__Options__IngredientCategory"
            >
              <div slot="title">
                <div class="ProductPage__Product__Options__IngredientCategory__Name">
                  {{ ingredientCategory.name }}
                </div>
                <div class="ProductPage__Product__Options__IngredientCategory__Items">
                  {{ cartItem.ingredients | selected(product, ingredientCategory) }}
                </div>
              </div>
              <f7-accordion-content>
                <f7-list>
                  <f7-list-item v-for="ingredient in product.ingredients"
                                v-if="ingredient.ingredient_category_id === ingredientCategory.id"
                                @click="cartItem.toggleIngredient(ingredient.IngredientID)"
                                class="ProductPage__Product__Ingredients__Ingredient"
                  >
                    <div slot="title" class="ProductPage__Product__Ingredients__Ingredient__Name">
                      {{ ingredient.editedName }}
                    </div>
                    <div slot="after" class="display-flex align-items-center justify-content-space-between">
                      <div class="ProductPage__Product__Ingredients__Ingredient__Price">
                        {{ `+$${menu.map[ingredient.IngredientID].price / 100}` }}
                      </div>
                      <i v-if="cartItem.hasIngredient(ingredient.IngredientID)"
                         class="ProductPage__Product__Ingredients__Ingredient__CheckBox bettys-burgers-web bettys-burgers-web-checkbox-filled">
                        <span class="path1"></span><span class="path2"></span>
                      </i>
                      <i v-else
                         class="ProductPage__Product__Ingredients__Ingredient__CheckBox bettys-burgers-web bettys-burgers-web-checkbox"/>

                    </div>
                  </f7-list-item>
                </f7-list>
              </f7-accordion-content>
            </f7-list-item>
          </f7-list>
        </div>
        <div class="ProductComponent__Product__Qty">
          <f7-stepper :disabled="!product.isQtyEditable" :min="1" :value="cartItem.qty"
                      @change="cartItem.qty = parseInt($event.target.value)"
                      large/>
        </div>
      </div>
    </div>
    <f7-link icon-material="close"
             class="ProductComponent__Product__Close"
             v-if="view.isBasicView()"
             @click="$emit('close')"
    />
    <div class="ProductComponent__AddToCart" v-if="addToCartView.isBasicView()">
      <div class="ProductComponent__AddToCart__Left">
        {{ cartItem.getTotal(menu.map) | currency }}
      </div>
      <div class="ProductComponent__AddToCart__Right">
        <f7-button @click="addToOrder" large fill>{{ cartText }}</f7-button>
      </div>
    </div>
    <div class="ProductComponent__BettysSuggestions" v-if="addToCartView.isUpsellsView()">
      <div class="ProductPage__UpsellPopUp__Header">
        Betty's Suggestions
      </div>
      <div class="ProductComponent__Upsell">
        <div class="ProductComponent__Upsell__Item"
             :style="`background-image:url('${getImageUrl(upsell.productImage)}')`"
             v-for="upsell in menu.upsells"
             @click="toggleUpsells(upsell.ProductID)"
        >
          <f7-icon class="ProductComponent__Upsell__Item__Marker color-primary"
                   v-if="frontEnd__Upsells.indexOf(upsell.ProductID) !== -1"
                   f7="checkmark_alt_circle_fill"></f7-icon>
          <f7-icon class="ProductComponent__Upsell__Item__Marker color-white"
                   v-else
                   f7="checkmark_alt_circle"></f7-icon>
          <div class="ProductComponent__Upsell__Item__Title">
            {{ upsell.editedName }}
          </div>
          <div class="ProductComponent__Upsell__Item__Price">
            {{ menu.map[upsell.ProductID].price | currency }}
          </div>
        </div>
      </div>
      <div v-if="frontEnd__Upsells.length===0">
        <f7-button @click="noThanks" large outline class="ProductPage__UpsellPopUp__Button">
          No Thanks
        </f7-button>
      </div>
      <div class="ProductPage__SubCart" v-else>
        <div class="ProductPage__SubCart__Upsell">
          <div class="ProductPage__SubCart__Upsell__Item active"
               v-for="upsell in menu.upsells"
               v-if="frontEnd__Upsells.indexOf(upsell.ProductID) !== -1"
               :style="`background-image:url('${getImageUrl(upsell.productImage)}')`"
          >
          </div>
          <div class="ProductPage__SubCart__Upsell__Item"
               v-for="upsell in menu.upsells"
               v-if="frontEnd__Upsells.indexOf(upsell.ProductID) === -1"
          >
          </div>
        </div>
        <div class="ProductPage__SubCart__AddToCart">
          <f7-button @click="addToOrderWithUpsells">
            <i class="bettys-burgers-web-4 bettys-burgers-web-4-cart-plus-icon"></i>
          </f7-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Product from "../models/Product";
import BaseComponent from "./BaseComponent";
import CartItem from "../models/CartItem";
import Cart from "../models/Cart";
import Menu from "../models/Menu";
import View from "../models/View";
import DataLayerHelper from "../models/DataLayerHelper";

export default {
  name: "ProductComponent",
  extends: BaseComponent,
  data: function () {
    return {
      cartText: 'Order now',
      cartItem: new CartItem(),
      frontEnd__Upsells: [],
      view: new View([
        View.enums.BASIC,
        View.enums.CUSTOMIZE
      ]),
      //for the cart
      addToCartView: new View([
        View.enums.BASIC,
        View.enums.UPSELLS
      ])
    }
  },
  mounted() {
    this.cartItem = CartItem.fromProduct(this.product);
    this.view.changeView(View.enums.BASIC);
    this.addToCartView.changeView(View.enums.BASIC);
  },
  props: {
    product: Product,
    menu: Menu,
    cart: Cart
  },
  methods: {
    addToOrder() {

      const product = this.findProduct(this.cartItem.product);

      if (product !== null) {
        DataLayerHelper.logProductAddToCart(product.editedName)
      }

      let cart = new Cart();
      cart.fill(this.cart);
      cart.addToCart(this.cartItem);
      this.cartText = 'Added!';
      setTimeout(() => {
        this.$emit('cart-updated', {
          cart: cart
        });
        if (this.product.hasUpsells) {
          this.addToCartView.changeView(View.enums.UPSELLS)
        } else {
          this.$emit('close');
          this.$emit('show-toast', {
            itemsCount: this.cartItem.qty
          });
        }
      }, 500)
    },
    addToOrderWithUpsells() {
      let cart = new Cart();
      cart.fill(this.cart);
      let itemsCounts = this.cartItem.qty;
      this.frontEnd__Upsells.forEach((upsell) => {

        const product = this.findProduct(upsell);

        if (product !== null) {
          DataLayerHelper.logProductAddToCart(product.editedName)
        }

        let cartItem = new CartItem();
        cartItem.product = upsell;
        cart.addToCart(cartItem);
        itemsCounts++;
      });
      this.$emit('cart-updated', {
        cart: cart
      });
      this.$emit('close');
      this.$emit('show-toast', {
        itemsCount: itemsCounts
      });
    },
    noThanks() {
      this.$emit('close');
      this.$emit('show-toast', {
        itemsCount: this.cartItem.qty
      });
    },
    toggleUpsells(ProductID) {
      let index = this.frontEnd__Upsells.indexOf(ProductID);
      if (index === -1) {
        this.frontEnd__Upsells.push(ProductID)
      } else {
        this.frontEnd__Upsells.splice(index, 1)
      }
    },
    findProduct(productId) {

      let foundProduct = null;

      this.menu.categories.forEach((category) => {
        if (Array.isArray(category.products)) {
        const matchedProduct = category.products.find((product) => {
          return product.ProductID === productId;
        });

        if (matchedProduct !== undefined) {
          foundProduct = matchedProduct;
        }
        }
      })

      return foundProduct;
    }
  },
  filters: {
    selected: function (cartItemIngredients, product, ingredientCategory) {
      let count = 0;
      product.ingredients.forEach((ingredient) => {
        if (ingredient.ingredient_category_id === ingredientCategory.id && cartItemIngredients.indexOf(ingredient.IngredientID) !== -1)
          count++;
      });
      if (count > 0)
        return `${count} selected`;
      else
        return '';
    }
  }
}
</script>

<style scoped>

</style>
