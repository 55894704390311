import BaseModel from "../BaseModel";

export default class ForgotPasswordForm extends BaseModel {

    Username;

    static submit(Username) {
        return super.post(`/api/reset-password`, {
            Username: Username
        })
    }
}