export default {
    url: 'https://bettysburgers-backend.herokuapp.com',
    env: 'production',
    resourceUrl: 'https://d2wa2u1o35wjr4.cloudfront.net',
    googlePayPublishableKey: 'pk_live_RCExZSD3AtTDZu65pcLUXomp00rvm3XUh7',
    stripePublishableKey: 'pk_live_RCExZSD3AtTDZu65pcLUXomp00rvm3XUh7',
    app: {
        version: '1.0.8',
        build: '1.0.3'
    }
};
//
// export default {
//     url: 'https://d477147d.ngrok.io',
//     env: 'dev',
//     resourceUrl: 'https://d3miuomk1mc7i3.cloudfront.net',
//     googlePayPublishableKey: 'pk_test_oQA8B9TD7r2uoQdxuB1nndmd00zkG8iDE5',
//     app: {
//         version: '1.0.7',
//         build: '1.0.0'
//     }
// }

// export default {
//     url: 'http://salsas-au-staging.herokuapp.com',
//     env: 'dev',
// }
//
// export default {
//     url: 'https://salsas-au-staging.herokuapp.com',
//     env: 'production',
// }
