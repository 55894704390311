import CartItem from "./CartItem";

export default class {

    items;
    //an item just be added to the cart
    recentlyAdded;

    constructor() {
        this.items = [];
        this.recentlyAdded = false;
    }

    fill(data) {
        this.items = data.items.map((item) => {
            return new CartItem(item)
        });
        this.recentlyAdded = data.recentlyAdded;
    }

    addToCart(cartItem) {
        let index = this.findItem(cartItem);
        if (index === -1)
            this.items.push(cartItem);
        else {
            if (this.items[index].isQtyEditable)
                this.items[index].qty++;
        }

        this.recentlyAdded = true;
        return this;
    }

    removeFromCart(cartItemIndex) {
        this.items.splice(cartItemIndex, 1);
        return this;
    }

    getTotal(map) {

        let total = 0;

        this.items.forEach((cartItem) => {
            total += cartItem.getTotal(map)
        });

        return total;

    }

    getAwardPoints(map) {

        let total = this.getTotal(map);

        return (total / 1500).toFixed(2);
    }

    getRedeemPoints(map) {

        let total = this.getTotal(map);

        return (total / 100).toFixed(2);
    }

    getQty() {

        let total = 0;

        this.items.forEach((cartItem) => {
            total += cartItem.qty
        });

        return total;
    }

    isEmpty() {
        return this.items.length === 0;
    }

    isValid(map) {
        let rs = true;
        this.items.forEach((item) => {
            if (typeof map[item.product] === 'undefined')
                rs = false;
            else {
                item.ingredients.forEach((ingredient) => {
                    if (typeof map[ingredient] === 'undefined')
                        rs = false;
                })
            }
        });

        return rs;
    }

    findItem(needle) {
        let rs = -1;
        this.items.forEach((item, key) => {
            if (item.product === needle.product) {
                let a = item.ingredients;
                let b = needle.ingredients;
                a.sort();
                b.sort();
                if (JSON.stringify(a) === JSON.stringify(b))
                    rs = key;
            }
        });

        return rs;
    }

    hasItem(needle) {
        let rs = false;
        this.items.forEach((item) => {
            if (item.product === needle.product)
                rs = true;
        });

        return rs;
    }

    toRedcatStd(map) {

        let cart = [];

        this.items.forEach((cartItem) => {

            let cartElm = {
                Item: map[cartItem.product].name,
                Price: map[cartItem.product].price,
                PLUCode: cartItem.product,
                Qty: cartItem.qty,
                Ingredients: []
            };

            cartItem.ingredients.forEach((ingredient) => {
                cartElm.Ingredients.push(
                    {
                        Item: map[ingredient].name,
                        Price: map[ingredient].price,
                        PLUCode: ingredient,
                        Qty: 1
                    }
                )
            });

            cart.push(cartElm);
        });

        return cart;
    }

    toGTagItems(map) {

        let cart = [];

        this.items.forEach((cartItem) => {

            let gaProductItem = {
                id: cartItem.product,
                name: map[cartItem.product].name,
                category: map[cartItem.product].category,
                price: map[cartItem.product].price/100,
                quantity: cartItem.qty,
                currency: 'AUD'
            };

            cart.push(gaProductItem);

            cartItem.ingredients.forEach((ingredient) => {
                let gaIngredientItem = {
                    id: ingredient,
                    name: map[ingredient].name,
                    category: map[ingredient].category,
                    price: map[ingredient].price/100,
                    quantity: cartItem.qty,
                    currency: 'AUD'
                };

                cart.push(gaIngredientItem);
            });
        });

        return cart;
    }

    restore() {
        /*if (window.BettysBurgersCart !== undefined) {
            this.fill(window.BettysBurgersCart);
        }*/
        const str = sessionStorage.getItem('cart');
        if(str){
            this.fill(JSON.parse(str));
        }
    }

    store() {
        const str = JSON.stringify(this);
        sessionStorage.setItem('cart',str);
        // window.BettysBurgersCart = this;
    }

    purge() {
        // window.BettysBurgersCart = undefined;
        sessionStorage.clear();
    }
}
