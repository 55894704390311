<template>
  <f7-page name="guest-payment" class="GuestPaymentPage"
           @page:afterin="onAfterIn"
  >
    <f7-navbar no-shadow class="GuestPaymentPage__NavBar">
      <f7-nav-left>
        <f7-button back class="GuestPaymentPage__Icon--Left">
          <i class="bettys-burgers-web-2 bettys-burgers-web-2-Arrow-Back-Icon"/>
        </f7-button>
      </f7-nav-left>
      <f7-nav-title class="width-100 text-align-center">Checkout Guest</f7-nav-title>
      <f7-nav-right>
        <f7-button class="CheckoutPage__CartButton">
          <i class="bettys-burgers-icon bettys-burgers-cart-icon"/>
          <f7-badge class="qty" v-if="cart.getQty()>0">{{ cart.getQty() }}</f7-badge>
        </f7-button>
      </f7-nav-right>
    </f7-navbar>
    <div class="GuestPaymentPage__Content" v-if="loaded">
      <div style="width: 100%; height: 90vh; display: flex; flex-direction: column; justify-content: center;">
        <f7-block class="text-align-center" v-if="!stripeElementsFormIsEnabled">
          <f7-button large fill class="margin-bottom" @click="goToLoginPage">Sign in</f7-button>
          <f7-button v-if="enableGuestPayment === true" large fill class="margin-bottom bg-color-black" @click="showForm">Continue as Guest</f7-button>
          <p class="GuestPaymentPage__DontHaveAnAccount">
            Don't have an account?
          </p>
          <div>
            <f7-button large @click="goToRegisterPage">Sign up now</f7-button>
          </div>
        </f7-block>
        <div v-else>
          <div>
            <f7-list no-hairlines class="no-margin-vertical GuestPaymentPage__Cart">
              <f7-list-item class="CheckoutPage__Item" v-for="(item, index) in cart.items">
                <div slot="title" v-if="typeof menu.map[item.product] !=='undefined'">
                  <div class="CheckoutPage__Item__Title">
                    {{ menu.map[item.product].name }}
                  </div>
                  <div class="CheckoutPage__Item__Ingredients">
                    {{ menu.map[item.product].description }}
                  </div>
                  <div class="CheckoutPage__Item__Ingredients">
                    {{ item.ingredientsToString(menu.map) }}
                  </div>
                </div>
                <div slot="title" v-else>
                  <div class="CheckoutPage__Item__Title">
                    Invalid Item
                  </div>
                </div>
                <div slot="after" v-if="typeof menu.map[item.product] !=='undefined'">
                  <div class="CheckoutPage__Item__Price">
                    {{ item.getTotal(menu.map) | total }}
                  </div>
                </div>
              </f7-list-item>
              <f7-list-item class="GuestPaymentPage__Total">
                <div slot="title" class="GuestPaymentPage__Total__Label">
                  Total
                </div>
                <div slot="after" class="GuestPaymentPage__Total__Value">
                  {{ cart.getTotal(menu.map) | total }}
                </div>
              </f7-list-item>
            </f7-list>
            <div v-if="enableGuestPayment" class="GuestPaymentPage__PaymentRequest" id="payment-request-part">
              <div>
                <div v-if="mobilePay" class="GuestPaymentPage__WantFasterCheckout">
                  Want Faster Checkout?
                </div>
                <div>
                  <div v-if="mobilePay" id="payment-request-button"></div>
                  <div v-if="mobilePay === false">Unsupported Mobile Pay, Please ensure you have activated your apple/google pay</div>
                </div>
              </div>
            </div>
            <div class="GuestPaymentPage__Form">
              <f7-button
                  href="/register/"
                  large
                  fill
                  style="margin: 10px"
              >Sign up
              </f7-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <f7-block v-else>
      <f7-progressbar infinite></f7-progressbar>
    </f7-block>
  </f7-page>
</template>
<script>
import BasePage from './base'
import Cart from '../models/Cart'
import Store from "../models/Store";
import Menu from "../models/Menu";
import PickupTime from "../models/PickupTime";
import Device from "../models/Device";
import CreditCard from "../models/CreditCard";
import moment from "moment";
import Invoice from "../models/Invoice";
import Env from "../Env";
import GuestCheckout from "../models/GuestCheckout";
import GuestCheckoutForm from "../models/Forms/GuestCheckoutForm";


export default {
  extends: BasePage,
  data() {
    return {
      enableGuestPayment: null,
      /**
       * @var mobilePay: null|boolean
       * @description
       * null = [title, mobile-pay-btn, unsupported-message](hidden)
       * true = [title, mobile-pay-btn](visible)
       * false = [unsupported-message](visible)
       */
      mobilePay: null,
      cart: new Cart(),
      menu: new Menu(),
      device: new Device(),
      creditCard: new CreditCard(),
      pickupStore: new Store(),
      pickupTime: new PickupTime(),
      invoice: new Invoice(),
      loaded: false,
      stripeElementsFormIsEnabled: false,
      cardNumber: null,
      stripe: null,
      guestCheckoutForm: new GuestCheckoutForm(),
    }
  },
  methods: {
    onAfterIn() {
      this.device.restore();
      this.cart.restore();
      this.menu.restore();
      this.pickupStore.restore();
      this.pickupTime.restore();
      this.idempotencyKey = `${moment().unix()}`;
      this.loaded = true;
      this.isMobilePaySupported();
    },
    async isMobilePaySupported() {
      this.stripe = Stripe(Env.stripePublishableKey);
      const paymentRequest = this.stripe.paymentRequest({
        country: 'AU',
        currency: 'aud',
        total: {
          label: "Betty's Burgers Web Order",
          amount: this.cart.getTotal(this.menu.map),
        },
        requestPayerName: true,
        requestPayerEmail: true,
        requestPayerPhone: true,
      });
      try {
        const response = await paymentRequest.canMakePayment();
        console.log('mobile pay supported',response);
        this.enableGuestPayment = !!response;
      }catch (e) {
        console.log("exception",e);
      }
    },
    sleep: ml => (new Promise((resolve)=>{
      setTimeout(()=>{
        resolve();
      },ml);
    })),
    async showForm(){
      this.stripeElementsFormIsEnabled = true;
      this.$f7.preloader.show();
      this.stripe = Stripe(Env.stripePublishableKey);
      const elements = this.stripe.elements();
      const elementClasses = {
        focus: 'focused',
        empty: 'empty',
        invalid: 'invalid',
      };

      const paymentRequest = this.stripe.paymentRequest({
        country: 'AU',
        currency: 'aud',
        total: {
          label: "Betty's Burgers Web Order",
          amount: this.cart.getTotal(this.menu.map),
        },
        requestPayerName: true,
        requestPayerEmail: true,
        requestPayerPhone: true,
      });

      const prButton = elements.create('paymentRequestButton', {
        paymentRequest: paymentRequest,
      });
      //await this.sleep(500);
      // Check the availability of the Payment Request API first.
      try {
        const response = await paymentRequest.canMakePayment();
        if(response){
          this.mobilePay = true;
          await this.sleep(500);
          prButton.mount('#payment-request-button');
        }else {
          this.mobilePay = false;
        }
      }catch (e) {
        console.log("exception",e);
      }
      this.$f7.preloader.hide();
    },
    showOldForm() {
      // gtag('event', 'guestpaymentpage_continue_as_guest_button_click');
      this.stripeElementsFormIsEnabled = true;
      this.$f7.preloader.show();
      this.enableGuestPayment = true;
      setTimeout(() => {
        this.stripe = Stripe(Env.stripePublishableKey);
        let elements = this.stripe.elements();
        let elementClasses = {
          focus: 'focused',
          empty: 'empty',
          invalid: 'invalid',
        };

        let style = {
          base: {
            color: '#9e9eaf',
            fontWeight: 500,
            fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
            fontSize: '16px',
            fontSmoothing: 'antialiased',
            ':-webkit-autofill': {
              color: '#fce883',
            },
            '::placeholder': {
              color: '#9e9eaf',
            },
          },
          invalid: {
            iconColor: '#FFC7EE',
            color: '#FFC7EE',
          },
        };

        this.cardNumber = elements.create('cardNumber', {
          classes: elementClasses,
          showIcon: true,
          style: style
        });
        this.cardNumber.mount('#card-number');

        let cardExpiry = elements.create('cardExpiry', {
          classes: elementClasses,
          style: style
        });
        cardExpiry.mount('#card-expiry');

        let cardCvc = elements.create('cardCvc', {
          classes: elementClasses,
          style: style
        });

        cardCvc.mount('#card-cvc');

        let paymentRequest = this.stripe.paymentRequest({
          country: 'AU',
          currency: 'aud',
          total: {
            label: "Betty's Burgers Web Order",
            amount: this.cart.getTotal(this.menu.map),
          },
          requestPayerName: true,
          requestPayerEmail: true,
          requestPayerPhone: true,
        });

        //payment request
        let prButton = elements.create('paymentRequestButton', {
          paymentRequest: paymentRequest,
        });

        // Check the availability of the Payment Request API first.
        paymentRequest.canMakePayment().then((result) => {
          if (result) {
            prButton.mount('#payment-request-button');
          } else {
            document.getElementById('payment-request-part').style.display = 'none';
          }
        });

        paymentRequest.on('token', (ev) => {
          if (ev.token) {
            this.checkoutWithToken(
                ev.token,
                ev.methodName,
                ev.payerEmail,
                ev.payerPhone,
                ev.payerName
            );
            ev.complete('success')
          } else {
            ev.complete('fail')
          }
        });

        this.$f7.preloader.hide();
      }, 1000)
    },
    checkout() {
      this.stripe.createToken(this.cardNumber).then((response) => {
        if (response.token !== undefined) {
          this.checkoutWithToken(
              response.token,
              'guest-cc',
              this.guestCheckoutForm.email,
              this.guestCheckoutForm.phone,
              this.guestCheckoutForm.givenNames
          );
        }
      })
    },
    checkoutWithToken(token, paymentMethod, email, phone, givenNames) {

      if (!this.cart.isValid(this.menu.map)) {
        this.$f7.dialog.confirm('Your cart is invalid', () => {
          this.$f7router.navigate('/checkout/');
        }, () => {
          this.$f7router.navigate('/checkout/');
        });
        return;
      }
      let cart = this.cart.toRedcatStd(this.menu.map);

      let orderTotal = this.cart.getTotal(this.menu.map);
      let pickupTime = this.pickupTime.getValue();

      this.$f7.preloader.show();
      let paymentToken = token.id;
      GuestCheckout.validate(
          cart,
          orderTotal,
          pickupTime,
          this.pickupTime.isASAP,
          this.pickupStore.id,
          this.pickupStore.name,
          false
      ).then((response) => {
        this.$f7.preloader.hide();
        if (response.success) {
          this.$f7.preloader.show();
          GuestCheckout.dispatch(
              cart,
              orderTotal,
              this.pickupStore.id,
              this.pickupStore.name,
              pickupTime,
              this.pickupTime.isASAP,
              response.hash,
              paymentToken,
              paymentMethod,
              this.idempotencyKey,
              'web',
              email,
              phone,
              givenNames
          ).then((response) => {
            this.$f7.preloader.hide();
            if (response.success) {
              this.invoice.fill(response.invoice);
              this.invoice.store();
              this.$f7router.navigate('/order-received/');
            } else {
              this.$f7.dialog.alert(response.message);
              this.idempotencyKey = `${moment().unix()}`;
            }
          })
        } else {
          this.$f7.preloader.hide();
          this.$f7.dialog.alert(response.message);
        }
      });
    },
    goToLoginPage() {
      // gtag('event', 'guestpaymentpage_login_button_click');
      this.$f7router.navigate('/login/')
    },
    goToRegisterPage() {
      // gtag('event', 'guestpaymentpage_register_button_click');
      this.$f7router.navigate('/register/')
    }
  }
}
</script>
<style scoped>
</style>
