import BaseModel from "./BaseModel";

export default class Member extends BaseModel {
    UserName;
    ShortState;
    DateOfBirth;
    State;
    Email;
    StateID;
    Barcode;
    ID;
    IsAdministrator;
    Password;
    Expired;
    MemberNo;
    StoreID;
    VerificationStatus;
    Token;
    Points;
    GivenNames;
    GroupID;
    RegistrationDate;
    Surname;
    Money;
    Sex;
    CardType;
    Postcode;
    BarcodeInline;
    CustomerID;
    FullName;
    Mobile;

    constructor(data) {
        super();
        this.ID = null;

        if (data !== null && data !== undefined) {
            this.fill(data)
        }
    }

    isValid() {
        return this.ID !== null;
    }

    //restore from cache
    restore() {
        Object.keys(this).forEach((key) => {
            this[key] = localStorage.getItem(`bettysburger-v2-member--${key}`)
        });
        this.FullName = `${this.GivenNames} ${this.Surname}`;
    }

    //save to cache
    store() {
        Object.keys(this).forEach((key) => {
            localStorage.setItem(`bettysburger-v2-member--${key}`, this[key])
        })
    }

    fill(data) {
        Object.keys(data).forEach((key) => {
            this[key] = data[key];
        });
        this.FullName = `${this.GivenNames} ${this.Surname}`;
    }

    /**
     *
     * @param loginForm
     * @param platform
     * @param uuid
     * @returns {Promise<any | never>}
     */
    static login(loginForm, platform, uuid) {
        return this.post('/api/public/v2/login', {
            email: loginForm.email,
            password: loginForm.password,
            platform: platform,
            uuid: uuid
        })
    }

    static load(email, token, device) {
        return super.get(`api/public/members/${device.uuid}`, {
            params: {
                email: email,
                token: token,
                platform: device.platform
            }
        })
    }

    static update(member, token, device) {
        member.GivenNames = member.FullName.split(' ').slice(0, -1).join(' ');
        member.Surname = member.FullName.split(' ').slice(-1).join(' ');

        return super.put(`api/public/members/${device.uuid}`, {
            member: member,
            email: member.Email,
            token: token,
            platform: device.platform,
        });
    }

    static updatePassword(member, token, device, newPassword, confirmPassword) {

        return super.put(`/api/public/members/${device.uuid}/password`, {
            newPassword: newPassword,
            confirmPassword: confirmPassword,
            email: member.Email,
            token: token,
            platform: device.platform
        });
    }

    static updateMobile(member, token, device, Mobile) {

        return super.put(`/api/public/members/${device.uuid}/mobile`, {
            Mobile: Mobile,
            email: member.Email,
            token: token,
            platform: device.platform
        });
    }

    static getDeals(MemberNo) {

        return super.get(`/api/public/members/${MemberNo}/deals`);
    }

    purge() {
        Object.keys(this).forEach((key) => {
            localStorage.removeItem(`bettysburger-v2-member--${key}`)
        })
    }
}
