<template>
    <f7-page class="RegisterPage" v-if="registerForm">
        <div class="RegisterPage__Banner">
            <div class="RegisterPage__Banner__Top">
                <f7-button back class="fb__back RegisterPage__Banner__Back">
                    <i class="bettys-burgers-web-2 bettys-burgers-web-2-Arrow-Back-Icon"></i>
                </f7-button>
                <img class="bettys-burgers-white-logo" src="static/images/logo--white.png"/>
                <f7-button back class="fb__invisible"
                           icon-material="arrow_back"/>
            </div>
            <div class="RegisterPage__Banner__Header">
                Create your Betty's Profile
            </div>
        </div>
        <div class="RegisterPage__Content">
            <f7-list no-hairlines>
                <f7-list-input type="text"
                               floating-label
                               label="Full name*"
                               placeholder="Enter your full name"
                               :value="registerForm.FullName"
                               @change="registerForm.FullName = $event.target.value"
                />
                <f7-list-input type="email"
                               floating-label
                               label="Email address*"
                               placeholder="Enter your email address"
                               :value="registerForm.Email"
                               @change="registerForm.Email = $event.target.value"
                />
                <f7-list-input type="password"
                               floating-label
                               label="Password*"
                               placeholder="Enter your password"
                               :value="registerForm.Password"
                               @change="registerForm.Password = $event.target.value"
                />
                <f7-list-input type="select"
                               label="Gender*"
                               :value="registerForm.Sex"
                               @change="registerForm.Sex = $event.target.value"
                >
                    <option value="M">Male</option>
                    <option value="F">Female</option>
                    <option value="O">Prefer not to say</option>
                </f7-list-input>
                <f7-list-input type="date"
                               label="Date of Birth"
                               :value="registerForm.DateOfBirth"
                               @change="registerForm.DateOfBirth = $event.target.value"
                />
            </f7-list>
            <f7-block class="RegisterPage__Content__TC">
                By signing in I agree to Betty’s Burgers
                <f7-link href="/terms-and-conditions/">Terms & Conditions</f7-link>
                and
                <f7-link href="/privacy-policy/">Privacy Policy</f7-link>
            </f7-block>
            <f7-block>
                <f7-button @click="register" fill large>Create Account</f7-button>
            </f7-block>
            <p class="GuestPaymentPage__DontHaveAnAccount">
                Already have an account?
            </p>
            <div>
                <f7-button large href="/login/">Login now</f7-button>
            </div>
        </div>
    </f7-page>
</template>
<script>
    import BasePage from './BasePage';
    import RegisterForm from '../models/Forms/RegisterForm';
    import AppBoy from '../classes/AppBoy';
    import Member from "../models/Member";
    import Token from "../models/Token";
    import Device from "../models/Device";
    import DataLayerHelper from "../models/DataLayerHelper";

    export default {
        extends: BasePage,
        data: function () {
            return {
                device: new Device(),
                registerForm: null,
                member: new Member(),
                token: new Token()
            };
        },
        mounted: function () {
            this.device.restore();
            this.registerForm = new RegisterForm(this.device);
        },
        methods: {
            register: function () {
                this.$f7.preloader.show();
                this.registerForm.submit().then((response) => {

                    this.$f7.preloader.hide();

                    if (response.success) {
                        this.member.fill(response.member);
                        this.token.fill(response.token);
                      DataLayerHelper.logCustomerEmailOnRegister(this.member.Email);
                        this.member.store();
                        this.token.store();
                        this.$f7.toast.show({
                            text: response.message,
                            position: "top"
                        });
                        this.$f7router.navigate('/checkout/', {
                            reloadAll: true
                        });
                    } else {
                        this.alert('Error', response.message);
                    }
                })
            }
        }
    };
</script>
