<template>
    <f7-page class="DealPage"
             :style="`background-image: url('${getImageUrl(deal.innerImage)}')`">
        <div
                class="DealPage__Banner"
        >
            <div class="display-flex align-items-center justify-content-space-between">
                <f7-link class="fb__back" back icon-material="close"/>
                <div class="text-align-center flex-grow-1">
                    <img class="bettys-burgers-white-logo" src="static/images/logo--white.png">
                </div>
                <f7-link class="fb__invisible" back icon-material="close"/>
            </div>
            <div class="DealPage__Name">
                {{deal.name}}
            </div>
            <div class="DealPage__Description">
                {{deal.description}}
            </div>
        </div>
        <div class="DealPage__Content">
            <f7-button class="DealPage__AddToOrder" @click="addToOrder">
                <i class="bettys-burgers-icon bettys-burgers-cart-icon"></i> Add to Order
            </f7-button>
            <f7-button v-if="deal.isScannableInRestaurant" class="DealPage__ScanAtRestaurant" @click="scanAtRestaurant">
                <i class="bettys-burgers-icon bettys-burgers-qrcode-icon"></i> Scan at Restaurant
            </f7-button>
            <div class="DealPage__TermsAndConditions">
                Offer can only be redeemed once. Either in-app or scanned in restaurant.
            </div>
        </div>
        <div slot="fixed" class="DealPage__QrCode" v-if="frontEnd__qrCodePopup">
            <div class="DealPage__QrCode__Content">
                <div class="text-align-right">
                    <f7-link @click="frontEnd__qrCodePopup=false" icon-material="close"></f7-link>
                </div>
                <div class="DealPage__QrCode__Content__Name">
                    {{deal.name}}
                </div>
                <div class="DealPage__QrCode__Content__Description">
                    {{deal.description}}
                </div>
                <div v-if="deal.product">
                    <qrcode class="HomePage__QrCode__Code"
                            :options="{width: 245}"
                            :value="deal.product.ProductID"/>
                </div>
                <div class="display-flex flex-direction-row align-items-center justify-content-center">
                    <div class="DealPage__QrCode__Content__Icon">
                        <i class="bettys-burgers-icon bettys-burgers-qrcode-icon"/>
                    </div>
                    <div class="DealPage__QrCode__Content__Help">
                        Scan in Restaurant
                    </div>
                </div>
            </div>
        </div>
        <div slot="fixed" class="DealPage__Expiry">
            Voucher Expires {{deal.to | countdown}}
        </div>
    </f7-page>
</template>
<script>
    import Menu from "../models/Menu";
    import Cart from "../models/Cart";
    import CartItem from "../models/CartItem";
    import Store from "../models/Store";
    import Member from "../models/Member";
    import Token from "../models/Token";
    import BasePage from "./BasePage";
    import Deal from "../models/Deal";
    import moment from "moment";
    import Device from "../models/Device";
    import VueQrcode from '@chenfengyuan/vue-qrcode';
    import Product from "../models/Product";

    export default {
        extends: BasePage,
        components: {
            'qrcode': VueQrcode
        },
        data() {
            return {
                deal: new Deal(),
                menu: new Menu(),
                cart: new Cart(),
                member: new Member(),
                token: new Token(),
                device: new Device(),
                cartItem: new CartItem(),
                frontEnd__qrCodePopup: false
            }
        },
        mounted() {
            this.member.restore();
            this.token.restore();
            this.device.restore();
            this.deal.restore();
            this.menu.restore();
            this.cart.restore();
        },
        methods: {
            addToOrder() {
                if (this.member.isValid()) {
                    this.$f7.preloader.show();
                    Deal.get(this.deal, this.member, this.token.token, this.device).then((response) => {
                        this.$f7.preloader.hide();
                        if (response.success) {
                            this.deal.fill(response.deal);
                            let product = new Product(this.deal.product);
                            product.store();
                            this.$f7router.navigate('/product/', {
                                reloadCurrent: true
                            });
                        } else {
                            this.$f7.dialog.alert(response.message);
                        }
                    })
                } else {
                    this.$f7.dialog.confirm('You must login in order to claim this deal. Login?', () => {
                        this.$f7router.navigate('/login/')
                    })
                }
            },
            scanAtRestaurant() {
                if (this.member.isValid()) {
                    this.$f7.preloader.show();
                    Deal.get(this.deal, this.member, this.token.token, this.device).then((response) => {
                        this.$f7.preloader.hide();
                        if (response.success) {
                            this.deal.fill(response.deal);
                            this.frontEnd__qrCodePopup = true;
                        } else {
                            this.$f7.dialog.alert(response.message);
                        }
                    })
                } else {
                    this.$f7.dialog.confirm('You must login in order to claim this deal. Login?', () => {
                        this.$f7router.navigate('/login/')
                    })
                }
            }
        },
        filters: {
            countdown(value) {
                return moment(value).hour(23).minute(59).fromNow();
            }
        }
    }
</script>