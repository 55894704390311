import Env from '../../Env'
import axios from "axios";
import Util from "../../Util";

export default class {
    email;
    token;
    //contain platform + uuid
    device;

    getFullUrl(url) {
        return `${Env.url}/${url}`
    }

    getImageUrl(imageUrl) {
        return this.getFullUrl(`storage/${imageUrl}`)
    }

    all(url, options) {
        url = this.getFullUrl(url);
        let client = axios.create(
            {
                timeout: 30000
            }
        );
        return client.get(url, options).then((response) => {
            return response.data
        }).catch((error) => {
            return {
                success: false,
                message: Util.getErrorMessage(error),
                header: !!options.onrejectedHeader?options.onrejectedHeader: error.header,
            }
        })
    }

    get(url, options) {
        url = this.getFullUrl(url);
        let client = axios.create(
            {
                timeout: 30000
            }
        );
        return client.get(url, options).then((response) => {
            return response.data
        }).catch((error) => {
            return {
                success: false,
                message: Util.getErrorMessage(error),
                header: !!options.onrejectedHeader?options.onrejectedHeader: error.header,
            }
        })
    }

    post(url, options) {
        url = this.getFullUrl(url);
        let client = axios.create(
            {
                timeout: 30000
            }
        );
        return client.post(url, options).then((response) => {
            return response.data
        }).catch((error) => {
            return {
                success: false,
                message: Util.getErrorMessage(error),
                header: !!options.onrejectedHeader?options.onrejectedHeader: error.header,
            }
        })
    }

    put(url, options) {
        url = this.getFullUrl(url);
        let client = axios.create(
            {
                timeout: 30000
            }
        );
        return client.put(url, options).then((response) => {
            return response.data
        }).catch((error) => {
            return {
                success: false,
                message: Util.getErrorMessage(error),
                header: !!options.onrejectedHeader?options.onrejectedHeader: error.header,
            }
        })
    }

    delete(url, options) {
        url = this.getFullUrl(url);
        let client = axios.create(
            {
                timeout: 30000
            }
        );
        return client.delete(url, options).then((response) => {
            return response.data
        }).catch((error) => {
            return {
                success: false,
                message: Util.getErrorMessage(error),
                header: !!options.onrejectedHeader?options.onrejectedHeader: error.header,
            }
        })
    }
}