<template>
  <f7-page class="MenuPage" @page:afterin="checkCartStatus" @page:afterout="beepingCart=false">
    <BettysToolbar :index="1" slot="fixed"></BettysToolbar>
    <div slot="fixed" class="MenuPage__Top">
      <f7-navbar no-shadow no-hairline class="MenuPage__NavBar">
        <f7-nav-left>
          <f7-button class="fb__30" back icon-material="arrow_backward"/>
        </f7-nav-left>
        <f7-nav-title class="width-100 text-align-center">
          <img width="100px" height="70px" margin-top="2px" margin-left="2px" src="static/images/HomePageV2/logo-orange.png"/>
        </f7-nav-title>
        <f7-nav-right>
          <f7-button :class="`fb__30 MenuPage__CartButton ${this.beepingCart?'active':null}`"
                     href="/checkout/">
            <div class="dot">&bullet;</div>
            <i class="bettys-burgers-icon bettys-burgers-cart-icon"/>
            <f7-badge class="qty" v-if="cart.getQty()>0">{{ cart.getQty() }}</f7-badge>
          </f7-button>
        </f7-nav-right>
      </f7-navbar>
      <div>
        <f7-button v-if="pickupStore.isValid()" class="MenuPage__FindStores" fill
                   @click="openStoresPage('menu')">
          {{ pickupStore.name }}
        </f7-button>
        <f7-button v-else class="MenuPage__FindStores" fill @click="openStoresPage('menu')">
          <i class="bettys-burgers-web-3 bettys-burgers-web-3-Location-Icon"/>
          &nbsp;Find your nearest restaurant&nbsp;
          <i class="bettys-burgers-web-3 bettys-burgers-web-3-Down-Icon"/>
        </f7-button>
      </div>
      <div>
        <div class="MenuPage__TabBars">
          <div v-for="(category, categoryIndex) in menu.categories"
               @click="changeCategory(categoryIndex)"
               :class="activeCategoryIndex === categoryIndex?'active':null"
          >
            {{ category.editedName }}
          </div>
        </div>
      </div>
    </div>
    <div class="MenuPage__Categories">
      <div class="MenuPage__Categories__Category"
           v-for="(category, categoryIndex) in menu.categories"
           v-if="activeCategoryIndex===categoryIndex"
      >
        <div class="MenuPage__Categories__Category__Image"
             :style="`background-image: url('${getImageUrl(category.singleViewImage)}')`">
        </div>
        <f7-list no-hairlines media-list class="MenuPage__Categories__Category__Products">
          <f7-list-item v-if="isAlcoholCategory(category)">
            <div class="CheckoutPage__Item__Ingredients" style="text-align: center; margin-bottom: 0; max-width: 100%">
              The ordering of alcohol is only available at the Register in Restaurant. Please have photographic ID ready
              for checking when purchasing.
            </div>
          </f7-list-item>

          <f7-list-item no-chevron
                        v-for="product in category.products"
                        @click="showProduct(product)"
                        :class="`MenuPage__Categories__Category__Products__Product product-${product.ProductID}`"
                        swipeout
          >
            <div class="MenuPage__Categories__Category__Products__Product__Title" slot="title">
              {{ product.editedName }}
            </div>
            <div class="MenuPage__Categories__Category__Products__Product__Description" slot="title">
              {{ product.description }}
            </div>
            <div slot="after" class="display-flex align-items-center justify-content-space-between">
              <div class="MenuPage__Categories__Category__Products__Product__Price">
                {{ menu.map[product.ProductID].price | total }}
              </div>
            </div>
            <f7-swipeout-actions right v-if="product.isSimplified && !isViewOnly(category)">
              <f7-button class="MenuPage__Categories__Category__Products__Product__Add"
                         @click="addToCart(product)">
                <i class="bettys-burgers-web-3 bettys-burgers-web-3-Quick-Add-Button">
                  <span class="path2"></span>
                </i>
              </f7-button>
            </f7-swipeout-actions>
          </f7-list-item>
        </f7-list>
      </div>
    </div>
  </f7-page>
</template>
<script>
import Menu from "../models/Menu";
import Cart from "../models/Cart";
import CartItem from "../models/CartItem";
import Store from "../models/Store";
import Member from "../models/Member";
import Token from "../models/Token";
import BasePage from "./base";
import Product from "../models/Product";
import BettysToolbar from "./BettysToolbar";
import Category from "../models/Category";
import DataLayerHelper from "../models/DataLayerHelper";

export default {
  extends: BasePage,
  components: {
    BettysToolbar
  },
  data: function () {
    return {
      frontEnd__SwipeOutElms: {},
      menu: new Menu(),
      cart: new Cart(),
      cartItem: new CartItem(),
      total: 0,
      position: null, //only lat & lng
      sliders: [],
      pickupStore: new Store(),
      category: null,
      product: new Product(),
      productViewIndex: 0,
      productViews: ['GridView', 'ListView'],
      //active tab
      tab: 0,
      member: new Member(),
      token: new Token(),
      popupOpened: false,
      activeProduct: null,
      activeCategoryIndex: 0,
      isProductView: false,
      beepingCart: false
    }
  },
  mounted: function () {
    this.menu.restore();
    this.pickupStore.restore();
    DataLayerHelper.logCategoryView(this.menu.categories[this.activeCategoryIndex].editedName)
  }, methods: {
    showProduct(product) {

      if (!product.isSimplified) {
        const category = this.menu.categories.find(category => category.id === product.category_id);

        DataLayerHelper.logProductView(product.editedName, category.editedName)
        this.product.fill(product);
        this.product.store();
        this.$f7router.navigate('/product/');
      } else {
        if (this.frontEnd__SwipeOutElms[product.ProductID]) {
          this.$f7.swipeout.close(`.product-${product.ProductID}`, () => {
            this.frontEnd__SwipeOutElms[product.ProductID] = false;
          })
        } else {
          this.$f7.swipeout.open(`.product-${product.ProductID}`, 'right', () => {
            this.frontEnd__SwipeOutElms[product.ProductID] = true;
          })
        }
      }
    },
    addToCart(product) {

      DataLayerHelper.logProductAddToCart(product.editedName)

      let cartItem = CartItem.fromProduct(product);
      this.cart.addToCart(cartItem);
      this.beepingCart = true;
      setTimeout(() => {
        this.beepingCart = false
      }, 500);
      this.cart.store();
    },
    checkCartStatus() {
      this.cart.restore();
      if (this.cart.recentlyAdded) {
        this.beepingCart = true;
        this.cart.recentlyAdded = false;
        this.cart.store();
      }
    },
    isViewOnly(category) {
      return category.editedName.toLowerCase().trim() === 'alcohol'
    },
    isAlcoholCategory(category) {
      return category.editedName.toLowerCase().trim() === 'alcohol'
    },
    changeCategory(categoryIndex) {
      this.activeCategoryIndex = categoryIndex
      DataLayerHelper.logCategoryView(this.menu.categories[categoryIndex].editedName)
    },
  },
}
</script>
