<template>
    <f7-page class="HomePage"
             @page:afterin="checkStatus"
             @page:afterout="beepingCart=false"
    >
        <div class="HomePage__Banner">
            <div v-if="member.isValid()" class="bg-color-black display-flex justify-content-flex-end padding-half">
                <f7-link color="white" fill small @click="signOut">Logout</f7-link>
            </div>
            <div class="HomePage__Banner__Top">
                <f7-button external href="https://www.bettysburgers.com.au/" class="fb__backward fb__30"
                           icon-material="arrow_back" color="white">
                </f7-button>
                <div class="text-align-center flex-grow-1">
                    <f7-link external href="https://www.bettysburgers.com.au/">
                        <img class="bettys-burgers-white-logo" src="static/images/logo--white.png"/>
                    </f7-link>
                </div>
                <f7-button :class="`fb__forward HomePage__CartButton ${this.beepingCart?'active':null}`"
                           href="/checkout/">
                    <div class="dot">&bullet;</div>
                    <i class="bettys-burgers-icon bettys-burgers-cart-icon"/>
                    <f7-badge class="qty" v-if="cart.getQty()>0">{{cart.getQty()}}</f7-badge>
                </f7-button>
            </div>
            <f7-block class="HomePage__Banner__Header">
                Order online and save time!
            </f7-block>
            <f7-block>
                <div class="HomePage__Promo">Download our App and receive exclusive benefits!</div>
            </f7-block>
            <f7-block>
                <f7-row>
                    <f7-col>
                        <f7-link @click="openAppStore">
                            <img
                                style="height: 45px" src="static/images/HomePage/AppStore.png">
                        </f7-link>
                    </f7-col>
                    <f7-col class="text-align-right">
                        <f7-link @click="openPlayStore">
                            <img style="height: 45px" src="static/images/HomePage/GooglePlay.png">
                        </f7-link>
                    </f7-col>
                </f7-row>
            </f7-block>
        </div>
        <div class="HomePage__ViewMenu" @click="$f7router.navigate('/menu/')">
            <div>
                View Menu
            </div>
            <div>
                <f7-icon class="HomePage__ViewMenu__Icon" material="chevron_right"></f7-icon>
            </div>
        </div>
        <div class="HomePage__Content">
            <div>
                <div class="HomePage__Featured">
                    <div class="HomePage__Featured__Item"
                         :style="`background-image:url('${getImageUrl(featuredItem.productImage)}')`"
                         v-for="featuredItem in menu.featuredItems"
                         @click="showProduct(featuredItem)"
                    >
                        <span class="HomePage__Featured__Item__Title">
                            {{featuredItem.editedName}}
                       </span>
                    </div>
                </div>
            </div>
        </div>
    </f7-page>
</template>
<script>
    import moment from 'moment';
    import BasePage from "./base";
    import Member from "../models/Member";
    import Token from "../models/Token";
    import Menu from "../models/Menu";
    import Product from "../models/Product";
    import Cart from "../models/Cart";
    import BettysToolbar from './BettysToolbar'
    import VueQrcode from '@chenfengyuan/vue-qrcode';
    import Feature from "../models/Feature";

    export default {
        extends: BasePage,
        components: {
            'qrcode': VueQrcode,
            BettysToolbar
        },
        data: function () {
            return {
                //frontend to beep the top-right cart button
                beepingCart: false,
                promoSection: 0,
                menu: new Menu(),
                product: new Product(),
                cart: new Cart(),
                deals: [],
                member: new Member(),
                memberDeals: [],
                token: new Token(),
                feature: new Feature()
            }
        },
        mounted: function () {

            Menu.get().then((response) => {
              console.log("HomePage:mounted",response);
                if (response.success) {
                    this.menu.fill(response.menu);
                    this.menu.store();
                }
            });

        }, methods: {
            goToByronBayPage() {
                this.$f7.sheet.close('.profile-sheet');
                setTimeout(() => {
                    this.$f7router.navigate('/byron-bay/')
                }, 500)
            },
            showProduct(product) {
                this.product.fill(product);
                this.product.store();
                this.$f7router.navigate('/product/');
            },
            showDeal(deal, memberDeals) {
                if (memberDeals[deal.id]) {
                    //do nothing
                } else {
                    deal.store();
                    this.$f7router.navigate('/deal/');
                }
            },
            checkStatus() {
                this.member.restore();
                this.token.restore();

                if (this.member.isValid()) {
                    Feature.load().then((response) => {
                        if (response.success)
                            this.feature.fill(response.feature);
                    })
                }

                this.cart.restore();
                if (this.cart.recentlyAdded) {
                    this.beepingCart = true;
                    this.cart.recentlyAdded = false;
                    this.cart.store();
                }

                if (this.$f7route.query.scanNow === 'true') {
                    this.$f7.sheet.open('.profile-sheet')
                }
            },

            signOut: function () {
                this.$f7.dialog.confirm('Are you sure?', () => {
                    this.member.purge();
                    this.token.purge();
                    window.location.reload(true)
                });
            },
            onPtrRefresh(done) {
                Menu.get().then((response) => {
                  console.log("HomePage:mounted",response);
                    if (response.success) {
                        this.menu.fill(response.menu);
                        this.menu.store();
                    }
                });

                if (this.member.isValid()) {
                    Feature.load().then((response) => {
                        if (response.success)
                            this.feature.fill(response.feature);
                    })
                }

                done();
            },
            openAppStore()
            {
                // gtag('event', 'homepage_appstore_button_click');
                window.open('https://apps.apple.com/au/app/bettys-burgers/id1499423312', '_blank')
            },
            openPlayStore()
            {
                // gtag('event', 'homepage_playstore_button_click');
                window.open('https://play.google.com/store/apps/details?id=com.retailzoo.bettysburgers', '_blank')
            }
        },
        filters: {
            countdown(value) {
                return moment(value).hour(23).minute(59).fromNow();
            }
        }
    }
</script>
