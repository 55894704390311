<template>
    <f7-page class="ByronBayPage">
        <div class="ByronBayPage__Banner">
            <f7-button back
                       class="ByronBayPage__Banner__Back"
                       icon-material="arrow_back"/>
        </div>
        <div class="ByronBayPage__Content">
            <div class="ByronBayPage__Content__Header">
                Take Your Bestie on the ultimate beach hop holiday!
            </div>
            <div class="ByronBayPage__Content__Text">
                <p> Travel to three of Australia’s best beaches, Noosa, Burleigh Heads and Byron Bay on a seven day holiday.
                </p>
                <p>
                    Enjoy the waves and sand between your toes during the day and dine on your personal selection of Betty’s Burgers in the evenings!
                </p>
                <p>
                    With flights, accommodation and car hire, what’s not to love about this perfect holiday for two?!
                </p>
            </div>
        </div>
        <img class="width-100" src="static/images/ByronBayPage/ByronBay.jpg" alt="">
        <div class="ByronBayPage__Content2">
            <div class="ByronBayPage__Content__Header2">
                Scan in restaurant for your chance to win!
            </div>
            <div class="ByronBayPage__Content__Text">
                <p>
                    Simply scan your QR code in restaurant with every purchase for your chance to win. The more entries you have, the higher your chances of winning! T&Cs below.
                </p>
            </div>
            <div class="text-align-center">
                <f7-button @click="scanNow" outline large class="ByronBayPage__Content__Button">
                    Scan Now
                </f7-button>
            </div>
        </div>
        <div class="display-flex align-items-center justify-content-space-evenly"
             style="border: solid 1px #f0f0f0"
        >
            <div>
                <f7-link class="ByronBayPage__Link" href="/byron-bay-terms-and-conditions/">
                    Terms And Conditions
                </f7-link>
            </div>
            <div>
                <f7-link class="ByronBayPage__Link" href="/privacy-policy/">
                    Privacy Policy
                </f7-link>
            </div>
        </div>
    </f7-page>
</template>
<script>
    import BasePage from "./base";
    import Member from "../models/Member";

    export default {
        extends: BasePage,
        data: function () {
            return {
                member: new Member()
            }
        },
        mounted() {
        },
        methods: {
            scanNow() {
                this.member.restore();

                if (this.member.isValid()) {
                    this.$f7router.navigate('/?scanNow=true')
                } else {
                    this.$f7.dialog.confirm('You must login in order to scan. Login?', () => {
                        this.$f7router.navigate('/login/')
                    })
                }
            }
        }
    };
</script>
