<template>
  <f7-page class="MapPage">
    <BettysToolbar :index="2" slot="fixed"></BettysToolbar>
    <div class="MapPage__MapBox">
      <div id="map"/>
    </div>
    <div class="MapPage__SearchBox">
      <div id="resize-handler" class="resize-handler">
        <div class="handler"></div>
      </div>
      <div class="MapPage__SearchBox__Top">
        <input style="border-right: 1px solid #e6e6f4" placeholder="Search Location" class="width-100"
               type="text"
               id="map-search-input"/>
        <f7-button style="text-overflow: unset" @click="useCurrentLocation" class="text-align-right"
                   icon-material="near_me"/>
      </div>
      <div class="MapPage__SearchBox__Bottom">
        <f7-list v-if="stores.length > 0" accordion-list no-chevron>
          <f7-list-item accordion-item
                        :id="`store-${store.latitude}-${store.longitude}`"
                        v-for="store in stores"
                        @click="activeStore = store"
          >
            <div slot="title" @click="activeStore = store">
              <div class="MapPage__Store__Name">
                {{ store.name }}
              </div>
              <div class="MapPage__Store__Address">
                {{ store.address1 }}
              </div>
              <div class="MapPage__Store__Status">
                <template v-if="store.IsOpen">
                  <span>Open Now:</span> {{ store.openingHours }}
                </template>
                <template v-else>
                  <span>Closed</span>
                </template>
              </div>
            </div>
            <div slot="after">
              <div class="MapPage__Store__Distance"
                   @click="openGoogleMap(store)">
                <f7-icon f7="placemark_fill"/>
                {{ store.distance }}km
              </div>
            </div>
            <f7-accordion-content>
              <f7-block class="no-margin-horizontal margin-bottom">
                <f7-button fill large @click="selectPickupStore(store)">Select Restaurant</f7-button>
              </f7-block>
            </f7-accordion-content>
          </f7-list-item>
        </f7-list>
        <f7-block v-else>
          No store found
        </f7-block>
      </div>
    </div>
  </f7-page>
</template>
<script>
import Store from "../models/Store";
import BettysToolbar from "./BettysToolbar";
import DataLayerHelper from "../models/DataLayerHelper";

export default {
  components: {
    BettysToolbar
  },
  data: () => {
    return {
      map: null,
      markers: [],
      stores: [],
      pickupStore: new Store(),
      activeStore: null,
      currentPosition: null,
      currentPositionMarker: null,
      autoCompleteService: null,
      placeService: null
    }
  },
  mounted() {

    this.$f7.autocomplete.create({
      inputEl: '#map-search-input',
      openIn: 'dropdown',
      valueProperty: 'description',
      textProperty: 'description',
      source: (query, render) => {
        if (query.length < 3)
          render([]);
        else {
          this.autoCompleteService.getPlacePredictions({
            input: query,
            componentRestrictions: {country: 'au'}
          }, (predictions, status) => {
            if (status != google.maps.places.PlacesServiceStatus.OK) {
              render([]);
            }
            render(predictions);
          });
        }
      },
      on: {
        change: (places) => {
          this.activeStore = null;
          document.querySelector('#map-search-input').value = '';
          let place = places.pop();
          this.placeService.getDetails({
            placeId: place.place_id
          }, (response) => {
            this.currentPosition = {
              lat: response.geometry.location.lat(),
              lng: response.geometry.location.lng()
            }
          });
        }
      }
    });

    this.currentPosition = {
      lat: parseFloat(this.$f7route.query.lat),
      lng: parseFloat(this.$f7route.query.lng)
    };

    this.map = new google.maps.Map(document.getElementById('map'), {
      center: {lat: this.currentPosition.lat, lng: this.currentPosition.lng},
      zoom: 10,
      disableDefaultUI: true,
      clickableIcons: false
    });

    Store.getNearestStores(this.lat, this.lng).then((response) => {
      if (response.success) {
        this.stores = response.stores;
        if (this.stores.length > 0)
          this.activeStore = this.stores[0];
      }
    });


    this.autoCompleteService = new google.maps.places.AutocompleteService();
    this.placeService = new google.maps.places.PlacesService(document.createElement('div'));

    this.pickupStore.restore();

  },
  methods: {
    useCurrentLocation() {
      this.$f7.preloader.show();
      navigator.geolocation.getCurrentPosition((position) => {

        this.currentPosition = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
        this.$f7.preloader.hide();
      }, (error) => {
        this.$f7.preloader.hide();
        this.$f7.dialog.alert('Unable to get your location. -- Please use the search box instead');
      }, {
        timeout: 5000
      });
    },
    selectPickupStore(store) {
      this.$f7.dialog.confirm(`Select store <b>${store.name}</b> as pickup store?`, 'Select Pickup store', () => {
        DataLayerHelper.logStoreChange(store.name);
        this.pickupStore.fill(store);
        this.pickupStore.store();
        this.$f7.toast.show({
          position: 'center',
          icon: '<i class="f7-icons">checkmark_alt_circle</i>',
          text: `Store ${store.name} is marked as pickup store`,
          closeTimeout: 1000
        });

        if (this.$f7router.view.name === 'right') {
          //desktop right sidebar - navigate back to checkout
          this.$f7router.navigate(`/checkout/`, {
            reloadCurrent: true
          })
        } else {
          this.$f7router.navigate(`/${this.$f7route.query.back}/`, {
            reloadCurrent: true
          })
          localStorage.setItem('storeID', store.id);

          window.location.reload();
        }
      });
    },
    openGoogleMap(store) {
      this.$f7.dialog.confirm(`Open store <b>${store.name}</b> in Google Map?`, 'Open Google Map', () => {
        window.open(`https://www.google.com/maps/search/?api=1&query=${store.latitude},${store.longitude}`, '_system')
      })
    },
  },
  watch: {
    activeStore: function () {
      this.map.setZoom(14);
      this.map.panTo({
        lat: parseFloat(this.activeStore.latitude),
        lng: parseFloat(this.activeStore.longitude)
      });
      setTimeout(() => {
        document.getElementById(`store-${this.activeStore.latitude}-${this.activeStore.longitude}`).scrollIntoView({behavior: 'smooth'});
      }, 300)
    },
    stores: function () {

      this.map.setZoom(10);

      this.stores.forEach((store, index) => {
        let marker = new google.maps.Marker({
          position:
              {
                lat: parseFloat(store.latitude),
                lng: parseFloat(store.longitude)
              },
          map: this.map
        });

        google.maps.event.addDomListener(marker, 'click', () => {
          this.activeStore = store;
        });
      });

      this.map.panTo({
        lat: this.currentPosition.lat,
        lng: this.currentPosition.lng
      });
    },
    currentPosition: {
      deep: true,
      handler: function () {
        if (this.currentPositionMarker !== null)
          this.currentPositionMarker.setMap(null);

        this.currentPositionMarker = new google.maps.Marker({
          map: this.map,
          position: {
            lat: this.currentPosition.lat,
            lng: this.currentPosition.lng,
          },
          icon: {
            path: google.maps.SymbolPath.CIRCLE,
            fillColor: '#00F',
            fillOpacity: 0.6,
            strokeColor: '#00A',
            strokeOpacity: 0.9,
            strokeWeight: 1,
            scale: 7
          }
        });
        Store.getNearestStores(this.currentPosition.lat, this.currentPosition.lng).then((response) => {
          if (response.success) {
            this.stores = response.stores;
          }
        });
      }
    }
  }
};
</script>
<style scoped>
#map {
  height: 100%;
  width: 100%;
}
</style>
