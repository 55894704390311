const env = 'dev';
// const env = 'prod';
const devBaseUrl = 'http://bettysburgers-backend.test';
const prodBaseUrl = 'https://2d07022d.ngrok.io';
const networkState = 'wifi';
// const prodBaseUrl = 'https://4dc08438.ngrok.io';

export default {

  appName: 'BettysBurgers',

  networkState: () => {
    return networkState;
  },
  isDev: () => {
    return env === 'dev';
  },
  getDeviceUuid: (device) => {
    return env === 'dev' ? 'f280c75f-d687-463a-a5de-c3bfb27741c8' : device.uuid;
  },
  getDevicePlatform: (device) => {
    return env === 'dev' ? 'ios' : device.platform.toLowerCase();
  },
  getFullUrl: (url) => {
    return env === 'dev' ? (devBaseUrl + url) : (prodBaseUrl + url);
  }
};
