import _ from 'lodash'
import DataLayerHelper from "./DataLayerHelper";

export default class View {
    views;
    currentView;
    static enums = {
        BASIC: 'basic',
        CUSTOMIZE: 'customize',
        UPSELLS: 'upsells',
        STORES: 'stores',
        PRODUCT: 'product',
        RIGHT_PANEL: 'right-panels',
        LOGIN_PANEL: 'right-login',
        FAQS: 'faqs',
        CONTACT_US: 'contact-us'
    };

    constructor(views) {
        if (_.isArray(views)) {
            this.views = views;
        }
        this.currentView = null;
    }

    changeView(view) {
        if (_.indexOf(this.views, view) !== -1)
            this.currentView = view;
    }

    isView(view) {
        return this.currentView === view;
    }

    isBasicView() {
        return this.isView(View.enums.BASIC)
    }

    isRightPanelView() {
        return this.isView(View.enums.RIGHT_PANEL)
    }

    isLoginPanelView() {
        return this.isView(View.enums.LOGIN_PANEL)
    }

    isCustomizeView() {
        return this.isView(View.enums.CUSTOMIZE)
    }

    isProductView() {
        return this.isView(View.enums.PRODUCT)
    }

    isStoresView() {
        return this.isView(View.enums.STORES)
    }

    isUpsellsView() {
        return this.isView(View.enums.UPSELLS)
    }

    isFaqsView() {
        return this.isView(View.enums.FAQS)
    }

    isContactUsView() {
        return this.isView(View.enums.CONTACT_US)
    }

    changeToBasicView() {
        return this.changeView(View.enums.BASIC)
    }

    changeToCustomizeView() {
        return this.changeView(View.enums.CUSTOMIZE)
    }

    changeToRightPanelView() {
        return this.changeView(View.enums.RIGHT_PANEL)
    }

    changeToLoginPanelView() {
        return this.changeView(View.enums.LOGIN_PANEL)
    }

    changeToFAQsView() {
        return this.changeView(View.enums.FAQS)
    }

    changeToContactUsView() {
        return this.changeView(View.enums.CONTACT_US)
    }
}
