import {Model} from "vue-mc";

export default class Category extends Model {

    defaults() {
        return {
            id: null,
            name: ''
        }
    }
    mutations() {
        return {
            id: (id) => Number(id) || null,
            name: String,
            listViewImage: String,
            singleViewImage: String,
            products: Array
        }
    }
}
