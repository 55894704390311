function getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

function setCookie(name, value, days, sameSite, partitioned) {
    let expires = "";
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
    console.log('G4 Cookie created');
}

function init(){
    // Extract UTM parameters
    const utm_params = {
        utm_source: getParameterByName('utm_source'),
        utm_medium: getParameterByName('utm_medium'),
        utm_campaign: getParameterByName('utm_campaign'),
        utm_term: getParameterByName('utm_term'),
        utm_content: getParameterByName('utm_content')
    };

    // Filter out null values and store the remaining as a JSON string in a cookie
    const filteredParams = Object.fromEntries(Object.entries(utm_params).filter(([key, value]) => value !== null));
    // save:
    const num = Object.entries(filteredParams).length;
    if(num === 0) {
        console.log('No G4 Cookie set');
        // not exits: skip overwrite
        return;
    }
    const name = 'utm_params';
    const value = JSON.stringify(filteredParams);
    const days = 7;

    //::Setting a regular cookie
    //setCookie(name, value, days, "Lax", false);

    //::Setting a cross-site cookie
    //setCookie(name, value, days, "None", false);

    //::Setting a partitioned cookie (ensure browser support)
    //setCookie(name, value, days, "None", true);

    setCookie(name, value, days, 'Lax');

}

function getCookie() {
    const data = document.cookie.replaceAll(';','').split(' ');
    let g4 = null;
    data.forEach(info=>{
        const item = info.trim().split('=');
        if(item[0] === 'utm_params' && item[1] !== '{}'){
            g4 = item[1];
        }
    });

    return g4 ? JSON.parse(g4) : null;
}

export default {
    init,
    getCookie,
}
