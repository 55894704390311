export default class Product {
    ProductID;
    editedName;
    description;
    productImage;
    ingredients;
    hasUpsells;
    isSimplified;
    isCustomisable;
    isQtyEditable;

    constructor(data) {
        this.ProductID = null;
        this.ingredients = [];

        if (data !== null && data !== undefined) {
            this.fill(data)
        }
    }

    isValid() {
        return this.ProductID !== null;
    }

    fill(data) {
        if(!data)return;
        Object.keys(this).forEach((key) => {
            if (data[key] !== undefined)
                this[key] = data[key];
        })
    }

    store() {
        window.BettysBurgersProduct = this;
    }

    restore() {
        this.fill(window.BettysBurgersProduct);
    }
}
