<template>
<!--    <div class="BettyToolbar">-->
<!--        <f7-segmented class="BettyToolbarInner">-->
<!--            <f7-button :disabled="!available" @click="handleMenuClick(0)" :active="currentMenuIndex===0">-->
<!--                <i class="color-white bettys-burgers-icon bettys-burgers-home-icon"/>-->
<!--                Home-->
<!--            </f7-button>-->
<!--            <f7-button :disabled="!available" @click="handleMenuClick(1)" :active="currentMenuIndex===1">-->
<!--                <i class="bettys-burgers-icon bettys-burgers-menu-icon"/>-->
<!--                Order-->
<!--            </f7-button>-->
<!--            <f7-button :disabled="!available" @click="handleMenuClick(2)" :active="currentMenuIndex===2">-->
<!--                <i class="bettys-burgers-icon bettys-burgers-stores-icon"/>-->
<!--                Find Us-->
<!--            </f7-button>-->
<!--            <f7-button :disabled="!available" @click="handleMenuClick(3)" :active="currentMenuIndex===3">-->
<!--                <i class="bettys-burgers-icon bettys-burgers-profile-icon"/>-->
<!--                Profile-->
<!--            </f7-button>-->
<!--        </f7-segmented>-->
<!--    </div>-->
</template>
<script>
    import Member from "../models/Member";

    export default {
        data() {
            return {
                menus: [],
                currentMenuIndex: -1,
                available: false
            }
        },
        mounted() {
            this.currentMenuIndex = this.index;
            let member = new Member();
            member.restore();
            if (member.isValid()) {
                this.menus = ['/', '/menu/', '/find-a-bettys/', '/club/'];
            } else {
                this.menus = ['/', '/menu/', '/find-a-bettys/', '/login/'];
            }

            this.available = true;
        },
        methods: {
            handleMenuClick(index) {
                this.currentMenuIndex = index;
                setTimeout(() => {
                    this.$f7router.navigate(this.menus[index], {
                        animate: false
                    });
                }, 300)
            }
        },
        props: {
            index: Number
        }
    }
</script>
<style>
    .BettyToolbar a i {
        display: none;
    }

    .BettyToolbar a.button-active i {
        display: inline;
    }
</style>