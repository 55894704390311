<template>
    <f7-page name="order-history" class="OrderHistoryPage" ptr @ptr:refresh="reloadOrderHistories">
        <f7-navbar no-shadow class="OrderHistoryPage__NavBar">
            <f7-nav-left>
                <i @click="$f7router.back()" class="OrderHistoryPage__Icon--Left icon material-icons">arrow_backward</i>
            </f7-nav-left>
            <f7-nav-title class="width-100 text-align-center">Order History</f7-nav-title>
            <f7-nav-right>
                <i class="OrderHistoryPage__Icon--Right bettys-burgers-icon bettys-burgers-cart-icon"/>
            </f7-nav-right>
        </f7-navbar>
        <div>
            <div v-for="orderHistory in orderHistories" class="OrderHistoryPage__Item">
                <div class="OrderHistoryPage__Item__Store">
                    {{orderHistory.StoreName}}
                </div>
                <div class="OrderHistoryPage__Item__PickupTime">
                    Ordered {{orderHistory.TxnTime}} {{orderHistory.TxnDate}}
                </div>
                <div class="OrderHistoryPage__Item__ID">
                    <strong>Order ID</strong> {{orderHistory.SalesID}}
                </div>
                <div class="OrderHistoryPage__Item__OrderCompleted">
                    <f7-icon f7="checkmark_alt_circle_fill"/>
                    Order completed
                </div>
                <f7-list>
                    <f7-list-item accordion-item>
                        <div slot="title" class="OrderHistoryPage__Item__ViewOrder">
                            View Order
                        </div>
                        <f7-accordion-content>
                            <f7-list class="margin-vertical-half">
                                <f7-list-item
                                        v-for="Item in orderHistory.Items"
                                        v-if="Item.Qty > 0"
                                >
                                    <div slot="title">
                                        {{Item.Qty}} x {{Item.Description}}
                                    </div>
                                    <div slot="after" v-if="orderHistory.frontEnd__showReceipt">
                                        {{Item.Amount | total}}
                                    </div>
                                </f7-list-item>
                                <f7-list-item v-if="orderHistory.frontEnd__showReceipt">
                                    <div slot="title">
                                        <strong>Total</strong>
                                    </div>
                                    <div slot="after">
                                        <strong>{{orderHistory.TotalAmount | total}}</strong>
                                    </div>
                                </f7-list-item>
                            </f7-list>
                        </f7-accordion-content>
                    </f7-list-item>
                </f7-list>
                <div class="display-flex align-items-center justify-content-space-evenly">
                    <div class="OrderHistoryPage__Item__OrderAgain">
                        <f7-button @click="orderAgain(orderHistory)">
                            Order Again
                        </f7-button>
                    </div>
                    <div class="OrderHistoryPage__Item__Receipt">
                        <f7-button @click="orderHistory.showReceipt()">
                            Receipt
                        </f7-button>
                    </div>
                </div>
            </div>
        </div>
    </f7-page>
</template>
<script>
    import BasePage from './base'
    import OrderHistory from "../models/OrderHistory";
    import Token from "../models/Token";
    import Member from "../models/Member";
    import Device from "../models/Device";
    import Cart from "../models/Cart";
    import Menu from "../models/Menu";

    export default {
        extends: BasePage,
        data() {
            return {
                orderHistories: [],
                cart: new Cart(),
                menu: new Menu()
            }
        },
        mounted() {

            this.member = new Member();
            this.token = new Token();
            this.device = new Device();
            this.member.restore();
            this.token.restore();
            this.device.restore();
            this.menu.restore();

            this.$f7.preloader.show();
            OrderHistory.all(this.device.uuid, this.member.Email, this.token.token, this.device.platform).then((response) => {
                this.$f7.preloader.hide();
                if (response.success)
                    this.orderHistories = response.orderHistories.map((orderHistory) => {
                        return new OrderHistory(orderHistory);
                    });
                else
                    this.$f7.dialog.alert(response.message, 'Error')
            })
        },
        methods: {
            orderAgain(orderHistory) {
                if (orderHistory.canBeOrderedAgain(this.menu.map)) {
                    this.cart = orderHistory.convertToCart(this.menu.map);
                    if (this.cart.isEmpty()) {
                        this.$f7.dialog.alert("Unable to order this as some items are no longer available")
                    } else {
                        this.cart.store();
                        this.$f7.dialog.alert('Items added to cart', () => {
                            this.$f7router.navigate('/checkout/')
                        })
                    }
                } else {
                    this.$f7.dialog.alert("Unable to order this as some items are no longer available")
                }
            },
            reloadOrderHistories(done) {
                OrderHistory.all(this.device.uuid, this.member.Email, this.token.token, this.device.platform).then((response) => {
                    if (response.success)
                        this.orderHistories = response.orderHistories.map((orderHistory) => {
                            return new OrderHistory(orderHistory);
                        });
                    else
                        this.$f7.dialog.alert(response.message, 'Error');

                    done();
                })
            }
        },
        filters: {
            total(value) {
                return `$${(value / 1).toFixed(2)}`
            }
        }
    }
</script>
<style scoped>
</style>
