<template>
  <f7-page :class="`ProductPage ${isCustomize?'ProductPage--IsCustomized':null}`" v-if="product.isValid()"
           @page:afterin="scrollToBottom"
  >
    <f7-sheet class="ProductPage__UpsellPopUp upsell-sheet" bottom
              :close-by-backdrop-click="false"
    >
      <f7-page-content>
        <div>
          <div class="ProductPage__UpsellPopUp__Header">
            Betty's Suggestions
          </div>
          <div class="ProductPage__Upsell">
            <div class="ProductPage__Upsell__Item"
                 :style="`background-image:url('${getImageUrl(upsell.productImage)}')`"
                 v-for="upsell in menu.upsells"
                 @click="toggleUpsells(upsell.ProductID)"
            >
              <f7-icon class="ProductPage__Upsell__Item__Marker color-primary"
                       v-if="frontEnd__Upsells.indexOf(upsell.ProductID) !== -1"
                       f7="checkmark_alt_circle_fill"></f7-icon>
              <f7-icon class="ProductPage__Upsell__Item__Marker color-white"
                       v-else
                       f7="checkmark_alt_circle"></f7-icon>
              <div class="ProductPage__Upsell__Item__Title">
                {{ upsell.editedName }}
              </div>
              <div class="ProductPage__Upsell__Item__Price">
                {{ menu.map[upsell.ProductID].price | currency }}
              </div>
            </div>
          </div>
          <div v-if="frontEnd__Upsells.length===0">
            <f7-button @click="hideUpsells" large outline class="ProductPage__UpsellPopUp__Button">
              No Thanks
            </f7-button>
          </div>
          <div class="ProductPage__SubCart" v-else>
            <div class="ProductPage__SubCart__Upsell">
              <div class="ProductPage__SubCart__Upsell__Item active"
                   v-for="upsell in menu.upsells"
                   v-if="frontEnd__Upsells.indexOf(upsell.ProductID) !== -1"
                   :style="`background-image:url('${getImageUrl(upsell.productImage)}')`"
              >
              </div>
              <div class="ProductPage__SubCart__Upsell__Item"
                   v-for="upsell in menu.upsells"
                   v-if="frontEnd__Upsells.indexOf(upsell.ProductID) === -1"
              >
              </div>
            </div>
            <div class="ProductPage__SubCart__AddToCart">
              <f7-button @click="addToOrderWithUpsells">
                <i class="bettys-burgers-web-4 bettys-burgers-web-4-cart-plus-icon"></i>
              </f7-button>
            </div>
          </div>
        </div>
      </f7-page-content>
    </f7-sheet>
    <div class="ProductPage__Image" :style="`background-image: url('${getImageUrl(product.productImage)}')`">
      <f7-link class="ProductPage__Image__Back" back icon-material="arrow_back"></f7-link>
    </div>
    <div class="ProductPage__Product">
      <div class="ProductPage__Product__Detail">
        <div class="ProductPage__Product__Detail__Name" v-html="product.editedName">
        </div>
        <div class="ProductPage__Product__Detail__Description" v-html="product.description">
        </div>
        <div class="ProductPage__Product__Detail__Customize" v-if="product.isCustomisable">
          <span @click="isCustomize = true">Customise</span>
        </div>
      </div>
      <div class="ProductPage__Product__CustomizeSection" v-if="product.isCustomisable">
        <f7-navbar no-shadow class="ProductPage__Navbar">
          <f7-nav-left>
            <f7-button class="fb__30" @click="removeCustomization">
              <i style="margin-left: 21px" class="bettys-burgers-web-2 bettys-burgers-web-2-Arrow-Back-Icon"></i>
            </f7-button>
          </f7-nav-left>
          <f7-nav-title style="width:100%" class="text-align-center">
            Customize
          </f7-nav-title>
          <f7-nav-right>
            <f7-button class="fb__30 fb__invisible" @click="removeCustomization" icon-material="arrow_back"></f7-button>
          </f7-nav-right>
        </f7-navbar>
        <f7-list no-hairlines accordion-list class="ProductPage__Product__Options">
          <f7-list-item
              accordion-item
              v-for="ingredientCategory in menu.ingredientCategories"
              v-if="ingredientCategory.items > 0"
              class="ProductPage__Product__Options__IngredientCategory"
          >
            <div slot="title">
              <div class="ProductPage__Product__Options__IngredientCategory__Name">
                {{ ingredientCategory.name }}
              </div>
              <div class="ProductPage__Product__Options__IngredientCategory__Items">
                {{ cartItem.ingredients | selected(product, ingredientCategory) }}
              </div>
            </div>
            <f7-accordion-content>
              <f7-list>
                <f7-list-item v-for="ingredient in product.ingredients"
                              v-if="ingredient.ingredient_category_id === ingredientCategory.id"
                              @click="cartItem.toggleIngredient(ingredient.IngredientID)"
                              class="ProductPage__Product__Ingredients__Ingredient"
                >
                  <div slot="title" class="ProductPage__Product__Ingredients__Ingredient__Name">
                    {{ ingredient.editedName }}
                  </div>
                  <div slot="after" class="display-flex align-items-center justify-content-space-between">
                    <div class="ProductPage__Product__Ingredients__Ingredient__Price">
                      {{ `+$${menu.map[ingredient.IngredientID].price / 100}` }}
                    </div>
                    <i v-if="cartItem.hasIngredient(ingredient.IngredientID)"
                       class="ProductPage__Product__Ingredients__Ingredient__CheckBox bettys-burgers-web bettys-burgers-web-checkbox-filled">
                      <span class="path1"></span><span class="path2"></span>
                    </i>
                    <i v-else
                       class="ProductPage__Product__Ingredients__Ingredient__CheckBox bettys-burgers-web bettys-burgers-web-checkbox"/>

                  </div>
                </f7-list-item>
              </f7-list>
            </f7-accordion-content>
          </f7-list-item>
        </f7-list>
      </div>
      <div class="ProductPage__Product__Qty">
        <f7-stepper :disabled="!product.isQtyEditable" :min="1" :value="cartItem.qty"
                    @change="cartItem.qty = parseInt($event.target.value)"
                    large/>
      </div>
    </div>
    <div slot="fixed" class="ProductPage__DockBottom">
      <div class="left">
        {{ cartItem.getTotal(menu.map) | currency }}
      </div>
      <div class="right">
        <f7-button @click="addToOrder" fill large>{{ cartText }}</f7-button>
      </div>
    </div>
  </f7-page>
</template>
<script>
import Menu from "../models/Menu";
import Cart from "../models/Cart";
import CartItem from "../models/CartItem";
import Store from "../models/Store";
import Member from "../models/Member";
import Token from "../models/Token";
import BasePage from "./BasePage";
import Product from "../models/Product";
import DataLayerHelper from "../models/DataLayerHelper";

export default {
  extends: BasePage,
  data() {
    return {
      product: new Product(),
      menu: new Menu(),
      cart: new Cart(),
      cartItem: new CartItem(),
      cartText: 'Add to Order',
      isCustomize: false,
      frontEnd__showUpsells: false,
      frontEnd__Upsells: []
    }
  },
  mounted() {
    this.product.restore();
    this.menu.restore();
    this.cart.restore();
    this.menu.ingredientCategories = this.menu.ingredientCategories.map((ingredientCategory) => {
      ingredientCategory.items = 0;
      this.product.ingredients.forEach((ingredient) => {
        if (ingredient.ingredient_category_id === ingredientCategory.id)
          ingredientCategory.items++;
      });
      return ingredientCategory;
    });
    this.cartItem = CartItem.fromProduct(this.product);
  },
  methods: {
    scrollToBottom() {
      document.querySelector('.ProductPage__Product__Qty').scrollIntoView(
          {
            behavior: 'smooth'
          }
      )
    },
    removeCustomization() {
      this.cartItem.reset();
      this.isCustomize = false;
    },
    showUpsells() {
      this.$f7.sheet.open('.upsell-sheet')
    },
    hideUpsells() {
      this.frontEnd__showUpsells = false;
      this.$f7.sheet.close('.upsell-sheet')
      // this.cart.addToCart(this.cartItem);
      // this.cart.store();
      setTimeout(() => {
        this.$f7router.back()
      }, 500)
    },
    toggleUpsells(ProductID) {
      let index = this.frontEnd__Upsells.indexOf(ProductID);
      if (index === -1) {
        this.frontEnd__Upsells.push(ProductID)
      } else {
        this.frontEnd__Upsells.splice(index, 1)
      }
    },
    addToOrder() {
      const product = this.findProduct(this.cartItem.product);

      if (product !== null) {
        DataLayerHelper.logProductAddToCart(product.editedName)
      }

      this.cart.addToCart(this.cartItem);
      this.cart.store();
      this.cartText = 'Added!';
      setTimeout(() => {
        if (this.product.hasUpsells) {
          this.showUpsells();
        } else {
          this.$f7router.back()
        }
      }, 500)
    },
    addToOrderWithUpsells() {

      this.frontEnd__Upsells.forEach((upsell) => {

        const product = this.findProduct(upsell);

        if (product !== null) {
          DataLayerHelper.logProductAddToCart(product.editedName);
        }

        let cartItem = new CartItem();
        cartItem.product = upsell;
        this.cart.addToCart(cartItem)
      });
      this.cart.store();
      this.hideUpsells();
      setTimeout(() => {
        this.$f7router.back()
      }, 500)
    },
    findProduct(productId) {

      let foundProduct = null;

      this.menu.categories.forEach((category) => {
        if (Array.isArray(category.products)) {
        const matchedProduct = category.products.find((product) => {
          return product.ProductID === productId;
        });

        if (matchedProduct !== undefined) {
          foundProduct = matchedProduct;
        }
        }
      })

      return foundProduct;
    }
  },
  filters: {
    selected: function (cartItemIngredients, product, ingredientCategory) {
      let count = 0;
      product.ingredients.forEach((ingredient) => {
        if (ingredient.ingredient_category_id === ingredientCategory.id && cartItemIngredients.indexOf(ingredient.IngredientID) !== -1)
          count++;
      });
      if (count > 0)
        return `${count} selected`;
      else
        return '';
    }
  }
}
</script>
