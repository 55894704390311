import Env from '../Env'
import axios from 'axios'
import Util from "../Util";

export default class {

    email;
    token;
    device;

    static getFullUrl(url) {
        return `${Env.url}/${url}`
    }

    static getImageUrl(imageUrl) {
        return `${Env.resourceUrl}/${imageUrl}`
    }

    static all(url, options = {}) {
        url = this.getFullUrl(url);
        let client = axios.create(
            {
                timeout: 30000
            }
        );
        return client.get(url, options).then((response) => {
            return response.data
        }).catch((error) => {
            return {
                success: false,
                message: Util.getErrorMessage(error)
            }
        })
    }

    static get(url, options = {}) {
        url = this.getFullUrl(url);
        let client = axios.create(
            {
                timeout: 30000
            }
        );
        return client.get(url, options).then((response) => {
            return response.data
        }).catch((error) => {
            return {
                success: false,
                message: Util.getErrorMessage(error)
            }
        })
    }

    static post(url, options) {
        url = this.getFullUrl(url);
        let client = axios.create(
            {
                timeout: 30000
            }
        );
        return client.post(url, options).then((response) => {
            return response.data
        }).catch((error) => {
            return {
                success: false,
                message: Util.getErrorMessage(error)
            }
        })
    }

    static put(url, options) {
        url = this.getFullUrl(url);
        let client = axios.create(
            {
                timeout: 30000
            }
        );
        return client.put(url, options).then((response) => {
            return response.data
        }).catch((error) => {
            return {
                success: false,
                message: Util.getErrorMessage(error)
            }
        })
    }

    static delete(url, options) {
        url = this.getFullUrl(url);
        let client = axios.create(
            {
                timeout: 30000
            }
        );
        return client.delete(url, options).then((response) => {
            return response.data
        }).catch((error) => {
            return {
                success: false,
                message: Util.getErrorMessage(error)
            }
        })
    }
}