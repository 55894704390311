export default class {
    counter;

    constructor() {
        this.counter = 1;
    }

    //restore from cache
    restore() {
        let counter = parseInt(localStorage.getItem('bettysburger-v2-apprate-counter'));
        this.counter = isNaN(counter)?1:counter;
    }

    //save to cache
    store() {
        localStorage.setItem('bettysburger-v2-apprate-counter', this.counter)
    }

    fill(counter) {
        this.counter = counter;
    }
}