import BaseModel from "./BaseModel";

export default class Store extends BaseModel {

    id;
    name;
    address1;
    address2;
    latitude;
    longitude;
    IsOnline;
    IsOpen;
    FirstOrderTime;
    LastOrderTime;
    phone;
    state;
    suburb;
    openingHours;
    distance;
    asapEstimatedTimeDescription;

    isValid()
    {
        return this.id !== null;
    }

    constructor(data) {
        super();
        this.id = null;
        if (data !== null && data !== undefined) {
           this.fill(data)
        }
    }

    restore() {
        Object.keys(this).forEach((key) => {
            this[key] = localStorage.getItem(`bettysburgers-v2-store--${key}`)
        })
    }

    fill(data)
    {
        if (data !== null && data !== undefined) {
            Object.keys(this).forEach((key) => {
                this[key] = data[key];
            })
        }
    }

    store() {
        Object.keys(this).forEach((key) => {
            localStorage.setItem(`bettysburgers-v2-store--${key}`, this[key])
        })
    }

    static getNearestStores(latitude, longitude) {
        return super.all('api/public/nearest-stores', {
            params: {
                latitude: latitude,
                longitude: longitude
            }
        })
    }

    static all() {
        return super.all('api/public/stores')
    }

    isValid() {
        return !!this.id;
    }
}
