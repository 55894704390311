<template>
  <f7-page class="LoginPage" v-if="loginForm">
    <div class="LoginPage__Banner">
      <div class="LoginPage__Banner__Top">
        <f7-button class="fb__back LoginPage__Banner__Back" back @click="executeClick">
          <i class="bettys-burgers-web-2 bettys-burgers-web-2-Arrow-Back-Icon"></i>
        </f7-button>
        <img src="static/images/logo--white.png" class="bettys-burgers-white-logo">
        <f7-button class="fb__invisible" back icon-material="arrow_back"/>
      </div>
      <div class="LoginPage__Banner__Header">
        Login to<br>Betty’s Burgers
      </div>
    </div>
    <div class="LoginPage__Content">
      <f7-list no-hairlines>
        <f7-list-input type="email"
                       floating-label
                       label="Email address"
                       placeholder="Enter your email address"
                       :value="loginForm.email"
                       @change="loginForm.email = $event.target.value"
        />
        <f7-list-input type="password"
                       floating-label
                       label="Password"
                       placeholder="Enter your password"
                       :value="loginForm.password"
                       @change="loginForm.password = $event.target.value"
        />
      </f7-list>
      <f7-block class="text-align-right">
        <f7-link class="LoginPage__Content__ForgotPassword" href="/forgot-password/">Forgot password</f7-link>
      </f7-block>
      <f7-block class="LoginPage__Content__TC">
        By signing in I agree to Betty’s Burgers
        <f7-link href="/terms-and-conditions/">Terms & Conditions</f7-link>
        and
        <f7-link href="/privacy-policy/">Privacy Policy</f7-link>
      </f7-block>
      <f7-block>
        <f7-button @click="login" fill large>Log in</f7-button>
      </f7-block>
      <p class="GuestPaymentPage__DontHaveAnAccount">
        Don't have an account?
      </p>
      <div>
        <f7-button large href="/register/">Sign up now</f7-button>
      </div>
    </div>
  </f7-page>
</template>
<script>
import LoginForm from '../models/forms/LoginForm';
import Member from "../models/Member";
import Device from "../models/Device";
import Token from "../models/Token";
import BasePage from "./base";
import DataLayerHelper from "../models/DataLayerHelper";

export default {
  extends: BasePage,
  data: function () {
    return {
      loginForm: null,
      device: new Device()
    }
  },
  mounted() {
    this.device.restore();
    this.loginForm = new LoginForm(this.device);
  },
  methods: {
    executeClick(){
      if(this.$f7.views.login){
        this.$f7router.emit('view:close');
      }
    },
    login() {
      this.$f7.preloader.show();
      this.loginForm.submit().then((response) => {
        this.$f7.preloader.hide();
        if (response.success) {

          let member = new Member(response.member);
          let token = new Token(response.token);

          DataLayerHelper.logCustomerEmailOnLogin(member.Email);

          member.store();
          token.store();

          this.$f7router.navigate('/checkout/', {
            reloadAll: true
          });
          window.location.reload();
        } else {
          this.$f7.dialog.alert(response.message, 'Error');
        }
      });
    }
  }
};
</script>
