<template>
    <f7-page class="ForgotPasswordPage">
        <div class="ForgotPasswordPage__Banner">
            <f7-button color="white" back
                       class="ForgotPasswordPage__Banner__Left">
                <i class="bettys-burgers-web-2 bettys-burgers-web-2-Arrow-Back-Icon"></i>
            </f7-button>
            <img class="bettys-burgers-white-logo" src="static/images/logo--white.png"/>
            <f7-button
                       class="fb__invisible"
                       icon-material="arrow_back"/>
        </div>
        <div class="ForgotPasswordPage__Content">
            <div class="ForgotPasswordPage__Content__Header">
                Forgot Password?
            </div>
            <div class="ForgotPasswordPage__Content__Description">
                Don’t worry. Enter the email you used when you joined and we’ll send you your password.
            </div>
            <f7-list no-hairlines class="no-margin-vertical">
                <f7-list-input type="email"
                               outline
                               placeholder="Email address"
                               :value="form.Username"
                               @change="form.Username = $event.target.value"
                               class="fli__br0"
                />
            </f7-list>
            <f7-block>
                <f7-button @click="sendEmail" fill large>Recover Password
                </f7-button>
            </f7-block>
        </div>
    </f7-page>
</template>
<script>
    import Member from "../models/Member";
    import Device from "../models/Device";
    import Token from "../models/Token";
    import BasePage from "./base";
    import ForgotPasswordForm from "../models/Forms/ForgotPasswordForm";

    export default {
        extends: BasePage,
        data: function () {
            return {
                form: new ForgotPasswordForm(),
                device: new Device()
            }
        },
        mounted() {
        },
        methods: {
            sendEmail() {
                this.$f7.preloader.show();

                ForgotPasswordForm.submit(this.form.Username).then((response) => {

                    this.$f7.preloader.hide();

                    this.$f7.dialog.alert(response.message, response.success ? 'Success' : 'Error');
                    if (response.success) {
                        this.$f7router.navigate('/login/');
                    }
                })
            }
        }
    };
</script>
