import BaseModel from "./BaseModel";

export default class Feature extends BaseModel {
    barcodeScanning;
    byronBay;

    constructor(data) {
        super();

        this.barcodeScanning = false;
        this.byronBay = false;

        if (data !== null && data !== undefined) {
            this.fill(data)
        }
    }

    fill(data) {
        Object.keys(data).forEach((key) => {
            this[key] = data[key];
        });
        this.FullName = `${this.GivenNames} ${this.Surname}`;
    }

    static load() {
        return this.get('/api/public/feature')
    }
}