<template>
    <f7-page class="TermsAndConditionsPage">
        <f7-navbar class="TermsAndConditionsPage__NavBar" no-shadow>
            <f7-nav-left>
                <f7-link @click="$f7router.back()">Back</f7-link>
            </f7-nav-left>
            <f7-nav-title>Terms & Conditions</f7-nav-title>
            <f7-nav-right>
                <f7-link>Back</f7-link>
            </f7-nav-right>
        </f7-navbar>
        <div class="TermsAndConditionsPage__Content">
            <h3>Encyclopedia of Burgers Disclaimer</h3>
            <p>The information on this website was gathered collaboratively and voluntarily.</p>
            <p>If you would like to make a contribution, or believe an article to be in error, you may contact one of
                our representatives at&nbsp;<a
                        href="#" @click="openEmail('contact@bettysburgers.com.au')">contact@bettysburgers.com.au</a></p>
            <p>Be advised that nothing found here has necessarily been reviewed by humans with the expertise required to
                provide you with complete, accurate or reliable information. Betty's Burgers cannot guarantee the
                validity of the information found here.</p>
            <p>Any of the trademarks, service marks, collective marks, design rights, personality rights or similar
                rights that are mentioned, used or cited in the articles of the Encyclopedia of burgers are the property
                of their respective owners.</p>
            <p>Unless otherwise stated, Betty's Burgers are neither endorsed nor affiliated with any of the holders of
                any such rights and as such Betty's cannot grant any rights to use any otherwise protected materials.
                Your use of any such or similar incorporeal property is at your own risk.</p>
            <h3>not professional advice</h3>
            <p>Our facts and claims should not be deemed as professional advice. If you seek professional advice, please
                seek the advises of a professional<br/>who is licensed or knowledgeable in the area you seek.</p>
            <h3>website disclaimer</h3>
            <p>1.The materials displayed on this website, including without limitation all editorial materials,
                information, photographs, illustrations, artwork and other graphic materials, and names, logos and trade
                marks, are unless otherwise stated, the property of Betty's Burgers and are protected by copyright,
                trade mark and other intellectual property laws. Any such content may be displayed and printed solely
                for your personal, non-commercial use within your organisation only. You agree not to reproduce,
                retransmit, distribute, disseminate, sell, publish, broadcast or circulate any such material to any
                third party without the express prior written consent of Betty's Burgers.</p>
            <p>Save for the above, Betty's Burgers does not grant any license or right in, or assign all or part of, its
                intellectual property rights in the content or applications incorporated into the website or in the user
                interface of the website.</p>
            <p>2. You acknowledge and accept that the information contained on this website (the website information)
                may include technical inaccuracies. You acknowledge and accept that the website information is subject
                to change at any time and may not necessarily be up to date or accurate at the time you view it. You
                should enquire with us directly to ensure the accuracy and currency of the material you seek to rely
                upon.</p>
            <p>3. To the extent permitted by law Betty's Burgers disclaims all warranties with regard to the information
                and applications contained on the website and your use of the website. In no event shall Betty's Burgers
                , its contractors, agents, franchisees or employees be liable for any damages whatsoever (including
                special, indirect or consequential) arising out of or in connection with the use or performance of the
                website whether in contract, at common law or in equity, or on any other basis. These Terms and
                Conditions do not exclude warranties or conditions which Betty's Burgers cannot, by law, exclude. These
                Terms and Conditions do not exclude any liability which any law requires Betty's Burgers to accept.</p>
            <p>4. If you wish to establish a&nbsp;<a href="#" @click="openLink('http://www.bettysburgers.com.au/')">link
                to this website</a>&nbsp;you must, in the
                first instance, use the Contact Us Link at the bottom of the page and provide the following information:
            </p>
            <p>(i) the URL of the website that you seek to establish a link from<br/>(ii) a brief description of your
                website<br/>(iii) the reason that you wish to establish a link.</p>
            <p>If Betty's Burgers agrees to your proposed link, you must comply with any terms and conditions imposed by
                Betty's Burgers as a condition of such agreement. If the nature and/or content of your website changes
                in any significant way, you must contact Betty's Burgers and provide a new description of your
                website.</p>
            <p>5. This website may not be accessible from time to time, including inability to obtain or shortage of
                necessary materials, equipment facilities, power or telecommunications, lack of telecommunications
                equipment or facilities and failure of information technology or telecommunications equipment or
                facilities.</p>
            <p>6. You must not attempt to change, add to, remove, deface, hack or otherwise interfere with this website
                or any material or content displayed on this website.</p>
            <p>7. These Terms and Conditions are governed by and construed in accordance with the State of Victoria,
                Australia. In the event that a dispute arises from these terms and Conditions, you agree to submit to
                the non-exclusive jurisdiction of the courts of Victoria, Australia.</p>
            <p>For more information,&nbsp;<a href="#" @click="openEmail('contact@bettysburgers.com.au')">contact us</a>.
            </p>
        </div>
    </f7-page>
</template>
<script>
    import BasePage from './BasePage';
    import Env from "../Env";

    export default {
        extends: BasePage,
        methods: {
            openEmail(email) {
                window.open(`mailto:${email}`, '_system');
            },
            openLink(link) {
                window.open(link, '_system');
            }
        }
    };
</script>
