import moment from 'moment';

export default class {

    value;
    isASAP;

    constructor(props) {
        this.isASAP = true;
        this.value = moment().add(15, 'minutes').format('HH:mm:ss');
    }

    getValue() {
        return moment(this.value, 'HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');
    }

    getHourValue() {
        return moment(this.value, 'HH:mm:ss').format('HH:mm');
    }

    //restore from cache
    restore() {
        Object.keys(this).forEach((key) => {
            this[key] = localStorage.getItem(`bettysburger-v2-pickup-time--${key}`)
        });
        this.isASAP = this.isASAP === 'true';
    }

    //save to cache
    store() {
        Object.keys(this).forEach((key) => {
            localStorage.setItem(`bettysburger-v2-pickup-time--${key}`, this[key])
        })
    }

    fill(data) {
        Object.keys(data).forEach((key) => {
            this[key] = data[key];
        })
    }

    // hour;
    // minute;
    // when;
    //
    // constructor() {
    //     this.hour = moment().add(15, 'minutes').format("h");
    //     this.minute = moment().add(15, 'minutes').format("m");
    //     this.when = moment().add(15, 'minutes').format("a");
    // }
    //
    // fromInputTime(value) {
    //     value = moment(value, 'H:m');
    //     this.hour = value.format('h');
    //     this.minute = value.format('m');
    //     this.when = value.format('a');
    // }
    //
    // toInputTime() {
    //     return moment(`${this.hour}.${this.minute}${this.when}`, 'h:ma').format('H:m');
    // }
    //
    // format(isAsap) {
    //     if (isAsap)
    //         return moment().add(7, 'minutes').format('YYYY-MM-DD HH:mm:ss');
    //     else
    //     return moment(`${this.hour}.${this.minute}${this.when}`, 'h:ma').format('YYYY-MM-DD HH:mm:ss');
    // }
}