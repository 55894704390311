import BaseModel from "./BaseModel";
import Cart from "./Cart";
import CartItem from "./CartItem";

export default class OrderHistory extends BaseModel {
    SalesID;
    StoreName;
    TxnDate;
    TxnTime;
    TxnType;
    Items;
    TotalAmount;
    frontEnd__showReceipt;

    constructor(data) {
        super();
        this.frontEnd__showReceipt = false;

        if (data !== null && data !== undefined)
            this.fill(data);
    }

    showReceipt() {
        this.frontEnd__showReceipt = true;
    }

    //this order can be ordered again
    canBeOrderedAgain(map) {

        let rs = true;

        this.Items.forEach((Item) => {
            if (typeof map[Item.PLUCode] === 'undefined')
                rs = false;
        });

        return rs;
    }

    convertToCart(map)
    {
        let cart = new Cart();
        let cartItem = new CartItem();
        this.Items.forEach((Item) => {
            if(map[Item.PLUCode].type === 'product')
            {
                cart.addToCart(cartItem);
                cartItem = new CartItem();
                cartItem.product = Item.PLUCode;
                cartItem.qty = Item.Qty;
            }
            else if (map[Item.PLUCode].type === 'ingredient')
            {
                cartItem.ingredients.push(Item.PLUCode);
            }
        });

        cart.addToCart(cartItem);

        cart.removeFromCart(0);

        return cart;
    }

    fill(data) {
        Object.keys(this).forEach((key) => {
            this[key] = data[key];
        })
    }

    static all(uuid, email, token, platform) {
        let url = `api/public/members/${uuid}/order-histories`;
        return super.all(url, {
            params: {
                email: email,
                token: token,
                platform: platform
            }
        });
    }
}