
export default class {

    PickupTime;
    OrderID;

    constructor(props) {
        this.PickupTime = null;
        this.OrderID = null;
    }

    //restore from cache
    restore() {
        Object.keys(this).forEach((key) => {
            this[key] = localStorage.getItem(`bettysburger-v2-invoice--${key}`)
        })
    }

    store() {
        Object.keys(this).forEach((key) => {
            localStorage.setItem(`bettysburger-v2-invoice--${key}`, this[key])
        })
    }

    purge() {
        Object.keys(this).forEach((key) => {
            localStorage.removeItem(`bettysburger-v2-invoice--${key}`)
        })
    }

    fill(data)
    {
        Object.keys(data).forEach((key) => {
            this[key] = data[key];
        })
    }
}