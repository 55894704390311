import HomePage from '../pages/HomePage.vue';
import MenuPage from '../pages/MenuPage.vue';
import AccountPage from '../pages/AccountPage';
import NotFoundPage from '../pages/404.vue';
import ChangePasswordPage from "../pages/ChangePasswordPage";
import ContactUsPage from "../pages/ContactUsPage";
import TermsAndConditionsPage from "../pages/TermsAndConditionsPage";
import ByronBayTermsAndConditionsPage from "../pages/ByronBayTermsAndConditionsPage";
import PrivacyPolicyPage from "../pages/PrivacyPolicyPage";
import LoginPage from "../pages/LoginPage";
import ForgotPasswordPage from "../pages/ForgotPasswordPage";
import RegisterPage from "../pages/RegisterPage";
import OrderHistoryPage from "../pages/OrderHistoryPage";
import CreditCardPage from "../pages/CreditCardPage";
import ProductPage from "../pages/ProductPage";
import FindABettysPage from "../pages/FindABettysPage";
import MapPage from "../pages/MapPage";
import ClubPage from "../pages/ClubPage";
import CheckoutPage from "../pages/CheckoutPage";
import PaymentPage from "../pages/PaymentPage";
import GuestPaymentPage from "../pages/GuestPaymentPage";
import OrderReceivedPage from "../pages/OrderReceivedPage";
import DealPage from "../pages/DealPage";
import ByronBayPage from "../pages/ByronBayPage";
import FAQsPage from "../pages/FAQsPage";
//V2
import CartPageV2 from "../pages/CartPageV2";

import HomePageV2 from "../pages/HomePageV2";

let routes = [
  {
    path: '/',
    component: HomePageV2,
  },
  {
    path: '/map/',
    component: MapPage,
  },
  {
    path: '/account/',
    component: AccountPage,
  },
  {
    path: '/order-history/',
    component: OrderHistoryPage,
  },
  {
    path: '/deal/',
    component: DealPage,
  },
  {
    path: '/checkout/',
    component: CheckoutPage
  },
  {
    path: '/order-received/',
    component: OrderReceivedPage,
  },
  {
    path: '/payment/',
    component: PaymentPage,
  },
  {
    path: '/guest-payment/',
    component: GuestPaymentPage,
  },
  {
    path: '/credit-card/',
    component: CreditCardPage,
  },
  {
    path: '/byron-bay/',
    component: ByronBayPage,
  },
  {
    path: '/change-password/',
    component: ChangePasswordPage,
  },
  {
    path: '/register/',
    component: RegisterPage
  },
  {
    path: '/find-a-bettys/',
    component: FindABettysPage
  },
  {
    path: '/club/',
    component: ClubPage
  },
  {
    path: '/contact-us/',
    component: ContactUsPage,
  },
  {
    path: '/terms-and-conditions/',
    component: TermsAndConditionsPage,
  },
  {
    path: '/faqs/',
    component: FAQsPage,
  },
  {
    path: '/byron-bay-terms-and-conditions/',
    component: ByronBayTermsAndConditionsPage,
  },
  {
    path: '/privacy-policy/',
    component: PrivacyPolicyPage,
  },
  {
    path: '/menu/',
    component: MenuPage,
  },
  {
    path: '/product/',
    component: ProductPage
  },
  {
    path: '/login/',
    component: LoginPage,
  },
  {
    path: '/forgot-password/',
    component: ForgotPasswordPage
  },
  {
    path: '/cart-v2/',
    component: CartPageV2
  },
  {
    path: '(.*)',
    component: NotFoundPage,
  }
];

export default routes;
