export default {
    login: {
        email: '',
        password: ''
    },
    register: {
        Email: '',
        GivenNames: '',
        Surname: '',
        Sex: 'M',
        FullName: '',
        Password: '',
        ConfirmPassword: '',
        DateOfBirth: null
    },
    guestCheckout: {
        email: '',
        givenNames: '',
        phone: ''
    }
}