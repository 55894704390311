<template>
    <f7-app :params="f7params">
        <f7-view url="/" main name="main"></f7-view>
    </f7-app>
</template>
<script>
    import cordovaApp from '../js/cordova-app.js';
    import routes from '../js/routes.js';
    import Member from "../models/Member";
    import Token from "../models/Token";

    export default {
        data() {
            return {
                // Framework7 Parameters
                f7params: {
                    id: 'com.retailzoo.bettysburgers', // App bundle ID
                    name: "Betty's Burgers", // App name
                    theme: 'md', // Automatic theme detection


                    // App routes
                    routes: routes,

                    // Input settings
                    input: {
                        scrollIntoViewOnFocus: this.$device.cordova && !this.$device.electron,
                        scrollIntoViewCentered: this.$device.cordova && !this.$device.electron,
                    },
                    // Cordova Statusbar settings
                    statusbar: {
                        overlay: this.$device.cordova && this.$device.ios || 'auto',
                        iosOverlaysWebView: true,
                        androidOverlaysWebView: false,
                        androidTextColor: 'white',
                    }

                    // // Input settings
                    // input: {
                    //     scrollIntoViewOnFocus: this.$device.cordova && !this.$device.electron,
                    //     scrollIntoViewCentered: this.$device.cordova && !this.$device.electron,
                    // },
                    // // Cordova Statusbar settings
                    // statusbar: {
                    //     iosOverlaysWebView: false,
                    //     androidOverlaysWebView: false,
                    //     androidBackgroundColor: '#a4dbe8',
                    //     androidTextColor: 'white',
                    // },
                },
            }
        },
        methods: {
            alertLoginData() {
                this.$f7.dialog.alert('Username: ' + this.username + '<br>Password: ' + this.password);
            },
        },
        mounted() {
            this.$f7ready((f7) => {
                // Init cordova APIs (see cordova-app.js)
                if (f7.device.cordova) {
                    cordovaApp.init(f7);
                }
            });
        }
    }
</script>