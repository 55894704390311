import BaseForm from "./BaseForm";
import FormProfile from "../../FormProfile";

export default class extends BaseForm {

    email;
    password;
    platform;
    uuid;

    constructor(device) {
        super();
        this.platform = device.platform;
        this.uuid = device.uuid;
        this.email = FormProfile.login.email;
        this.password = FormProfile.login.password;
    }

    submit() {
        return this.post(`api/login`, this);
    }
}